///////////////////////////////////
///////////////////////////////////
///////////////////////////////////
// This is basically copied from https://github.com/pmndrs/react-three-fiber/blob/master/packages/fiber/src/web/Canvas.tsx
// With the exception that we can pass in our own specific cavas size (the default auto resizes which causes issues with our image panning/zooming)
// Unfortunately at this point the default canvas provides no way to set a canvas size that is independent of transforms !?!?
// We have also removed an unused div.
///////////////////////////////////
///////////////////////////////////
///////////////////////////////////
import React, { useLayoutEffect } from 'react';
import {
  render,
  RenderProps,
  unmountComponentAtNode,
  events,
} from '@react-three/fiber';
import mergeRefs from 'react-merge-refs';
import { useProps } from '@chakra-ui/react';

class ErrorBoundary extends React.Component<
  { set: React.Dispatch<any> },
  { error: boolean }
> {
  state = { error: false };
  static getDerivedStateFromError = () => ({ error: true });
  componentDidCatch(error: any) {
    this.props.set(error);
  }
  render() {
    return this.state.error ? null : this.props.children;
  }
}

type UnblockProps = {
  set: React.Dispatch<React.SetStateAction<SetBlock>>;
  children: React.ReactNode;
};

function Block({ set }: Omit<UnblockProps, 'children'>) {
  useLayoutEffect(() => {
    set(new Promise(() => null));
    return () => set(false);
  }, []);
  return null;
}

export interface RoomviewCanvasProps
  extends Omit<RenderProps<HTMLCanvasElement>, 'events'>,
    React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
  fallback?: React.ReactNode;
  handleRightClick: React.MouseEventHandler;
}

type SetBlock = false | Promise<null> | null;

export const RoomviewCanvas = React.forwardRef<
  HTMLCanvasElement,
  RoomviewCanvasProps
>(function Canvas(
  { children, fallback, tabIndex, id, style, className, size, ...props },
  forwardedRef
) {
  const canvasRef = React.useRef<HTMLCanvasElement>(null!);
  const [block, setBlock] = React.useState<SetBlock>(false);
  const [error, setError] = React.useState<any>(false);
  // Suspend this component if block is a promise (2nd run)
  if (block) throw block;
  // Throw exception outwards if anything within canvas throws
  if (error) throw error;

  // Execute JSX in the reconciler as a layout-effect
  useLayoutEffect(() => {
    if (size && size.width > 0 && size.height > 0) {
      render(
        <ErrorBoundary set={setError}>
          <React.Suspense fallback={<Block set={setBlock} />}>
            {children}
          </React.Suspense>
        </ErrorBoundary>,
        canvasRef.current,
        { ...props, size, events }
      );
    }
  }, [size, children]);

  useLayoutEffect(() => {
    const container = canvasRef.current;
    return () => unmountComponentAtNode(container);
  }, []);

  return (
    <canvas
      id={id}
      ref={mergeRefs([canvasRef, forwardedRef])}
      style={{ ...style, display: 'block' }}
      onContextMenu={props.handleRightClick}
    >
      {fallback}
    </canvas>
  );
});
