import { Flex, Modal, useDisclosure } from '@chakra-ui/react';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useGetRugProductsQuery } from '../../redux/api';
import { productData, setRugProducts } from './ProductData/productDataSlice';
import { productVisualisation } from './productVisualisationSlice';
import { useAppSelector } from '../../redux/hooks';
import { showRugOnboarding } from '../../redux/uiSlice';
import ProductOnboarding from './ProductOnboarding';
import RugSelectedProduct from './SelectedProduct/RugSelectedProduct';
import { config } from '../../config';
import { RugFilterResponse } from 'shared/build/models/product';
import { flatMap } from 'lodash';

function RugProducts() {
  const dispatch = useDispatch();
  const hasShownRugOnboarding = useAppSelector(
    (state) => state.ui.hasShownRugOnboarding
  );
  const { isOpen, onOpen, onClose } = useDisclosure();
  useEffect(() => {
    if (!hasShownRugOnboarding) {
      onOpen();
      dispatch(showRugOnboarding());
    }
  }, [hasShownRugOnboarding]);

  const { hasNextPage, loadNextPage, allData, filteredData } = useRugData();

  return (
    <>
      <Flex
        backgroundColor="white"
        flexDir="column"
        w={{ base: '100%', sm: '300px', md: '300px', lg: '500px' }}
        p="0 10px"
        height={{ base: 'auto', lg: 'calc(100vh - 50px)' }}
      >
        <RugSelectedProduct
          hasNextPage={hasNextPage}
          loadNextPage={loadNextPage}
          allData={allData}
          filteredData={filteredData}
        />
      </Flex>
      <Modal isOpen={isOpen} onClose={onClose} size="xs">
        <ProductOnboarding
          onClose={onClose}
          heading="Use the controls to move and rotate the rug in your room"
          videoUrl="https://choices-roomview.s3.ap-southeast-2.amazonaws.com/onboarding_assets/Rugs_small.mp4"
        />
      </Modal>
    </>
  );
}

export default RugProducts;

const useRugData = () => {
  const dispatch = useDispatch();
  const [offset, setOffset] = useState(0);
  const { rugFilters } = useSelector(productData);
  const visualisations = useSelector(productVisualisation);
  const visualisationProductIds = visualisations.data.reduce(
    (acc, visualisation) => {
      if (visualisation.rug_id !== null) {
        return [...acc, visualisation.rug_id];
      }
      return acc;
    },
    [] as number[]
  );

  const [allData, setAllData] = useState<{
    [key: string]: RugFilterResponse[];
  }>({});

  const { data, error, isFetching } = useGetRugProductsQuery({
    region: config.region,
    active: true,
    offset: offset.toString(),
    ...rugFilters,
  });

  const { data: visualisationProductData } = useGetRugProductsQuery(
    {
      region: config.region,
      active: true,
      product_ids: visualisationProductIds,
    },
    { skip: visualisationProductIds.length === 0 }
  );

  useEffect(() => {
    setAllData({ [0]: data?.data || [] });
    setOffset(0);
  }, [rugFilters]);

  useEffect(() => {
    if (!data) return;
    setAllData({ ...allData, [data.meta.offset]: data.data });
  }, [data]);

  // combine product data with those needed in the visualisations
  const { filteredData, combined } = useMemo(() => {
    const data = flatMap(Object.values(allData), (rugGroup) => rugGroup);
    let extraVisualisationData: RugFilterResponse[] = [];
    if (visualisationProductData) {
      extraVisualisationData = visualisationProductData.data.filter(
        (visProduct) =>
          !data.some((productData) => productData.name === visProduct.name)
      );
    }

    return {
      filteredData: data,
      combined: [...data, ...extraVisualisationData],
    };
  }, [allData, visualisationProductData?.data]);

  useEffect(() => {
    dispatch(setRugProducts(combined));
  }, [combined]);

  function loadNextPage() {
    setOffset((offset) => offset + 25);
  }

  const hasNextPage = data?.data.length === 25;

  return { hasNextPage, loadNextPage, allData: combined, filteredData };
};
