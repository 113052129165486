import { ButtonProps } from '@chakra-ui/react';
import React from 'react';
import { RiFilterLine } from 'react-icons/ri';
import { RegButton } from '../../../UI/Buttons';

interface Props extends ButtonProps {
  handleClick: () => void;
  children: React.ReactNode;
}
const FilterButton = ({ handleClick, children, ...rest }: Props) => {
  return (
    <RegButton
      display={{ base: 'inline-flex', lg: 'none' }}
      size="sm"
      leftIcon={<RiFilterLine />}
      onClick={handleClick}
      textMt="0"
      {...rest}
    >
      {children}
    </RegButton>
  );
};

export default FilterButton;
