import {
  Box,
  Button,
  Container,
  Flex,
  Grid,
  GridItem,
  Modal,
  Spacer,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { SecondaryHeader } from '../../UI/Text';
import { Thumbnail, ThumbnailContainer } from '../Photos/Thumbnail';
import { isSelected } from '../Photos/helpers';
import { useDispatch, useSelector } from 'react-redux';
import {
  resetSelection,
  saved,
  toggleSelection,
  removeSaved,
  setSaved,
} from './savedSlice';
import { RoutePaths } from '../../App';
import axios from '../../axios';
import { SavedApi } from 'shared/build/models/saved';
import { useHistory } from 'react-router-dom';
import {
  VisualisationData,
  setVisualisations,
} from '../Products/productVisualisationSlice';
import ReturningWelcome from '../Welcome/ReturningWelcome';
import { deleteSavedApi } from '../../utils/api';
import Share from '../Share/Share';
import { ProductView } from '../Products/Products';
import { IoShareOutline } from 'react-icons/io5';
import { downloadImage } from '../Share/helpers';
import { AnimatePresence, motion } from 'framer-motion';

export type SavedUiModes = 'select' | 'edit' | 'none';
export default function Saved() {
  const { selectedIds, data } = useSelector(saved);
  const selected = selectedIds;
  const dispatch = useDispatch();
  const [mode, setMode] = useState('select' as SavedUiModes);
  useEffect(() => {
    axios.get('/api/v1/saved/list').then(
      (res) => {
        dispatch(setSaved(res.data.data as SavedApi[]));
      },
      (e) => {
        window.alert('failed to load saved data' + e.message);
      }
    );
  }, []);
  useEffect(() => {
    dispatch(resetSelection());
  }, [mode]);
  useEffect(() => {
    if (mode === 'edit' && !data.length) {
      setMode('select');
    }
  }, [mode, data.length]);
  return (
    <>
      <VStack w="400px" maxW="85%">
        <Container mt="2.5rem">
          <Box
            as="section"
            w="full"
            backgroundColor="#f1f1f1"
            rounded="2xl"
            p="4"
          >
            <Grid gridTemplateColumns={'[start] 1fr [middle] 2fr [end] 1fr'}>
              <GridItem gridColumn="middle">
                <SecondaryHeader>Saved products</SecondaryHeader>
              </GridItem>
              <GridItem gridColumn="end" textAlign="end">
                {Boolean(data.length) && (
                  <Button
                    px="0"
                    fontWeight="lighter"
                    color="blue.600"
                    backgroundColor="transparent"
                    verticalAlign="unset"
                    height="unset"
                    onClick={() =>
                      setMode(mode === 'select' ? 'edit' : 'select')
                    }
                  >
                    {mode === 'select' ? 'Edit' : 'Done'}
                  </Button>
                )}
              </GridItem>
            </Grid>
            <Spacer marginTop="4"></Spacer>
            {data.length === 0 ? (
              <Flex direction="row" justifyContent="space-around">
                <Text color="#4A5568">You have no saved images</Text>
              </Flex>
            ) : (
              <Flex direction="row" wrap={'wrap'} minHeight="120px">
                {data.map((d) => {
                  return (
                    <ThumbnailContainer>
                      <Thumbnail
                        imageUrl={d.thumbnail_url}
                        key={d.id}
                        selected={isSelected(selected, d.id)}
                        mode={mode}
                        deleteSelect={async () => {
                          await deleteSavedApi(d.id);
                          dispatch(removeSaved(d.id));
                        }}
                        toggleSelect={() => dispatch(toggleSelection(d.id))}
                      />
                      <Button
                        aria-label="Download"
                        leftIcon={<IoShareOutline />}
                        rounded="full"
                        size="xs"
                        opacity="0.8"
                        fontWeight="normal"
                        color="#4A5568"
                        left="50%"
                        transform="translateY(-160%) translateX(-50%)"
                        onClick={() => {
                          downloadImage(
                            d.watermark_url,
                            'choices_flooring_roomview.jpg'
                          );
                        }}
                        _hover={{ opacity: '1' }}
                      >
                        Download
                      </Button>
                    </ThumbnailContainer>
                  );
                })}
              </Flex>
            )}
          </Box>
        </Container>
      </VStack>
      <SaveFooter />
      <ReturningWelcome />
    </>
  );
}

function SaveFooter() {
  const { selectedIds, data } = useSelector(saved);
  const selectedSaved = data.filter((saved) => selectedIds.includes(saved.id));
  const {
    isOpen: isShareOpen,
    onClose: onShareClose,
    onOpen: onShareOpen,
  } = useDisclosure();
  const history = useHistory();
  const dispatch = useDispatch();

  function clickNextPage() {
    const selectedData: VisualisationData[] = selectedSaved.map((d) => ({
      ...d,
      id: undefined,
    }));
    dispatch(setVisualisations(selectedData));
    history.push(RoutePaths.flooring);
  }

  const hasSelected = selectedSaved.length > 0;

  return (
    <>
      <Box position="fixed" bottom={0}>
        <AnimatePresence>
          <motion.footer
            layout
            initial={{ marginBottom: '-100px' }}
            animate={{ marginBottom: '0px' }}
            exit={{ marginBottom: '-100px' }}
            transition={{ duration: 0.5 }}
          >
            <Box
              bgColor="#F1F1F1"
              px={4}
              width="100vw"
              display="flex"
              justifyContent="center"
            >
              <Flex
                h={16}
                alignItems={'center'}
                justifyContent={'space-between'}
                width="100%"
                maxWidth="400px"
              >
                <Button
                  isDisabled={!hasSelected}
                  minWidth="8rem"
                  backgroundColor={hasSelected ? '#6CB33E' : '#D0D0D0'}
                  fontWeight="normal"
                  color={hasSelected ? 'white' : '#141516'}
                  rounded="full"
                  mx={3}
                  onClick={clickNextPage}
                >
                  Compare
                </Button>
                <Button
                  isDisabled={!hasSelected}
                  minWidth="8rem"
                  backgroundColor={hasSelected ? '#6CB33E' : '#D0D0D0'}
                  fontWeight="normal"
                  color={hasSelected ? 'white' : '#141516'}
                  rounded="full"
                  mx={3}
                  onClick={onShareOpen}
                >
                  Share
                </Button>
              </Flex>
            </Box>
          </motion.footer>
        </AnimatePresence>
      </Box>
      <Modal size="xs" isOpen={isShareOpen} onClose={onShareClose}>
        <Share view={ProductView.flooring} visualisations={selectedSaved} />
      </Modal>
    </>
  );
}
