import {
  Spacer,
  CheckboxGroup,
  VStack,
  Checkbox,
  Text,
  Box,
} from '@chakra-ui/react';
// import { RiArrowLeftSLine, RiArrowRightSLine } from 'react-icons/ri';
import { RoutePaths } from '../../App';
import { config } from '../../config';
import { useGetFloorProductAttributeQuery } from '../../redux/api';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { LinkButton } from '../../UI/Buttons';
import { SecondaryHeader } from '../../UI/Text';
import { setFloorFilter } from '../Products/ProductData/productDataSlice';
// import { ProductFilters } from '../Products/ProductData/ProductFilters';
import { Steps } from './models';

const FlooringSelection = () => {
  const dispatch = useAppDispatch();
  const selectedTypes = useAppSelector(
    (state) => state.productData.floorFilters.type
  );
  const data = useGetFloorProductAttributeQuery({
    region: config.region,
    types: selectedTypes,
  });
  const types = data.data?.data.type;

  const filters = useAppSelector(
    (state) => state.productData.floorFilters.type
  );

  return (
    <Box p="60px" pt="0">
      <Spacer h={8} />
      <Text textAlign="center">
        What type of flooring are you searching for?
      </Text>
      <Spacer h={4} />
      <CheckboxGroup
        value={filters}
        onChange={(value: string[]) =>
          dispatch(setFloorFilter({ category: 'type', val: value }))
        }
      >
        <VStack spacing={1} alignItems="start">
          {types?.map((type) => (
            <Checkbox key={type} size={'md'} value={type}>
              <Text>{type}</Text>
            </Checkbox>
          ))}
        </VStack>
      </CheckboxGroup>
    </Box>
  );
};
export default FlooringSelection;
