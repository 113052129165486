import {
  HStack,
  Grid,
  Text,
  useDisclosure,
  Modal,
  Box,
  GridItem,
  Flex,
  VStack,
} from '@chakra-ui/react';
import { CgArrowsExpandLeft } from 'react-icons/cg';
import { useDispatch } from 'react-redux';
import { RugFilterResponse, RugProductDb } from 'shared/build/models/product';
import { RoomApi } from 'shared/build/models/room';
import { LinkButton, RegButton } from '../../../UI/Buttons';
import { SecondaryHeader } from '../../../UI/Text';
import { calculateRugDimensions } from '../helpers';
import {
  setShowFloorFilters,
  setShowRugFilters,
} from '../ProductData/productDataSlice';
import ChangeSize, { priceFormat } from '../ProductList/ChangeSize';
import ProductThumbnail from '../ProductList/ProductThumbnail';
import {
  setSelectedProductInfo,
  VisualisationData,
} from '../productVisualisationSlice';
import FilterButton from './FilterButton';
import ProductInfo from './ProductInfo';
import { useTrackProductView } from './helpers';
import { useAppSelector } from '../../../redux/hooks';
import { ExternalLinkIcon } from '@chakra-ui/icons';
import SaveButtonNew from '../Visualiser/SaveButtonNew';
import Share from '../../Share/Share';
import { ProductView } from '../Products';
import { ProductPrice } from './ProductPrice';

type Props = {
  rug: RugFilterResponse | undefined;
  vis: VisualisationData;
  room: RoomApi;
};
const RugSelectedHeading = ({ rug, vis, room }: Props) => {
  if (!rug) {
    return <BlankHeading />;
  }

  const product = rug.products.find((r) => r.id === vis.rug_id)!;
  return <Heading product={product} room={room} rug={rug} vis={vis} />;
};

export default RugSelectedHeading;

const BlankHeading = () => {
  const dispatch = useDispatch();
  return (
    <HStack spacing="2">
      <SecondaryHeader>Select Rug to View in Room</SecondaryHeader>
      <FilterButton handleClick={() => dispatch(setShowRugFilters(true))}>
        Filter Rugs
      </FilterButton>
    </HStack>
  );
};

const Heading = ({
  product,
  room,
  rug,
  vis,
}: {
  product: RugProductDb;
  room: RoomApi;
  rug: RugFilterResponse;
  vis: VisualisationData;
}) => {
  const dispatch = useDispatch();
  const { onOpen, onClose, isOpen } = useDisclosure();
  const zoomLevel = useAppSelector((state) => state.ui.zoomLevel);

  const visData = useAppSelector((state) => state.productVisualisation);
  const {
    isOpen: isShareOpen,
    onOpen: onShareOpen,
    onClose: onShareClose,
  } = useDisclosure();

  useTrackProductView(room, product.sku);

  const productSelected = useAppSelector(
    (state) => state.productVisualisation.selectedProduct
  );
  const toggleProductInfoFromHeader = () =>
    productSelected === null
      ? dispatch(
          setSelectedProductInfo({ product: product, brand: product.category })
        )
      : dispatch(setSelectedProductInfo(null));

  return (
    <>
      <Grid
        mt={{ base: '0.5em', lg: '0' }}
        columnGap={{ base: 'auto', lg: '0.25em' }}
        gridTemplateColumns={
          '[filter] auto [share] fit-content(2rem) [save] fit-content(2rem)'
        }
      >
        <GridItem gridColumn="filter">
          <FilterButton handleClick={() => dispatch(setShowRugFilters(true))}>
            Filter Rugs
          </FilterButton>
        </GridItem>
        <GridItem gridColumn="share">
          <Box
            rounded="full"
            display="flex"
            background="gray.200"
            paddingX={3}
            paddingY={1}
            minWidth={50}
            height={30}
            justifyContent="center"
            alignItems="center"
            onClick={onShareOpen}
          >
            <ExternalLinkIcon />
          </Box>
        </GridItem>
        <GridItem gridColumn="save">
          <Box
            rounded="full"
            display="flex"
            background="gray.200"
            paddingX={3}
            paddingY={1}
            minWidth={50}
            height={30}
            ml={{ base: '2', sm: '1' }}
            justifyContent="center"
            alignItems="center"
          >
            <SaveButtonNew />
          </Box>
        </GridItem>
      </Grid>
      {zoomLevel === 0 && (
        <HStack mt={2} mb={2} alignItems="flex-end" maxWidth="100%">
          <Box flex="0 0 auto">
            <ProductThumbnail product={product} size={50}>
              <ProductInfo
                url={product.product_url}
                product={product}
                brand={product.category}
              />
            </ProductThumbnail>
          </Box>
          <Flex w="full" flexDirection="column" minH={50}>
            <Box
              display="flex"
              onClick={toggleProductInfoFromHeader}
              cursor="pointer"
            >
              <Text align="left" fontWeight="light" fontSize="sm">
                {product.name} *
              </Text>
              <Text
                ml="1"
                fontWeight="light"
                color="gray.600"
                fontSize="sm"
                whiteSpace="nowrap"
              >
                - {calculateRugDimensions(product)}
              </Text>
            </Box>

            <Flex>
              <Box flex="1">
                <SecondaryHeader flex="1" align="left">
                  <ProductPrice product={product} />
                </SecondaryHeader>
              </Box>

              <Box
                display="flex"
                justifyContent="flex-end"
                flexDirection="column"
              >
                <RegButton
                  as={'button'}
                  secondary
                  onClick={onOpen}
                  borderColor="gray.600"
                  color="gray.600"
                  leftIcon={<CgArrowsExpandLeft />}
                  left="1"
                  fontSize={'sm'}
                  m="0"
                  px="1"
                  py="0"
                  textMt="0"
                  height="6"
                >
                  Change Size
                </RegButton>
              </Box>
            </Flex>
          </Flex>
        </HStack>
      )}
      <Modal onClose={onClose} isOpen={isOpen}>
        <ChangeSize rug={rug} vis={vis} onClose={onClose} />
      </Modal>
      <Modal size="xs" isOpen={isShareOpen} onClose={onShareClose}>
        <Share view={ProductView.rugs} visualisations={visData.data} />
      </Modal>
    </>
  );
};
