import { Region } from 'shared/build/models/product';

const urlParams = new URLSearchParams(window.location.search);
const region = (urlParams.get('region') as Region) || Region.au;
const pageUrl = (urlParams.get('page_url') as string) || window.location.origin;
const isDevelopment = window.location.origin.includes('test');
const urlBase = process.env.REACT_APP_BASE_HOST || 'http://localhost:8000';

export const config = {
  region,
  googleAnalytics: isDevelopment
    ? 'UA-63781727-1'
    : region === Region.au
    ? 'UA-10799501-5'
    : 'UA-63781727-1',
  googleTagManager: isDevelopment
    ? 'GTM-N68FRZ4' // glyn personal account
    : region === Region.au
    ? 'GTM-KMZ3XBT'
    : 'GTM-M7TDGKN',
  pageUrl,
  urlBase,
};
