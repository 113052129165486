import { Link as RouterLink } from 'react-router-dom';
import { MenuItem } from '@chakra-ui/react';
import { RoutePaths } from '../../App';
import { useAppDispatch } from '../../redux/hooks';
import { setFloorFilter } from '../Products/ProductData/productDataSlice';

const FlooringSubmenu = ({
  type,
  addFloor,
}: {
  type: string;
  addFloor: boolean;
}) => {
  const appDispatch = useAppDispatch();

  return (
    <MenuItem
      as={RouterLink}
      to={RoutePaths.flooring}
      pl={12}
      onClick={() =>
        appDispatch(
          setFloorFilter({
            category: 'type',
            val: [type],
          })
        )
      }
      fontSize={{ base: 'sm', sm: 'md' }}
    >
      {`${addFloor ? 'Add ' : ''}${type}`}
    </MenuItem>
  );
};
export default FlooringSubmenu;
