import {
  Alert,
  AlertDescription,
  AlertIcon,
  Box,
  HStack,
  VStack,
} from '@chakra-ui/react';
import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { RoutePaths } from '../App';
import { LinkButton, RegButton } from './Buttons';

export function ApiError(props: {
  error: FetchBaseQueryError | SerializedError;
}) {
  return (
    <Alert status="error" overflowWrap={'anywhere'}>
      <AlertIcon />
      There was an error processing your request:{' '}
      {(props.error as any)?.data?.message || JSON.stringify(props.error)}
    </Alert>
  );
}

export function ErrorMessage({ error }: { error: string }) {
  return (
    <Alert status="error" borderRadius="md">
      <AlertIcon />
      {error}
    </Alert>
  );
}

export const SharedErrorMessage = ({ error }: { error: string }) => {
  return (
    <Alert status="error" borderRadius="md">
      <VStack>
        <HStack>
          <AlertIcon />
          <AlertDescription>{error}</AlertDescription>
        </HStack>
        <Box>
          <RegButton onClick={() => window.location.reload()}>Reload</RegButton>
          <LinkButton to={RoutePaths.home}>Continue</LinkButton>
        </Box>
      </VStack>
    </Alert>
  );
};
