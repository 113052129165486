import {
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Text,
  Grid,
  Divider,
} from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { RugFilterResponse, RugProductDb } from 'shared/build/models/product';
import { config } from '../../../config';
import { useGetRugProductsQuery } from '../../../redux/api';
import { useAppDispatch } from '../../../redux/hooks';
import { LinkButton } from '../../../UI/Buttons';
import { ApiError } from '../../../UI/ErrorMessage';
import { Loading } from '../../../UI/Loading';
import { calculateRugDimensions } from '../helpers';
import {
  productVisualisation,
  updateIndVisualisation,
  VisualisationData,
} from '../productVisualisationSlice';
import { ProductPrice } from '../SelectedProduct/ProductPrice';

type Props = {
  onClose: () => void;
  rug: RugFilterResponse;
  vis: VisualisationData;
};

const ChangeSize = (props: Props) => {
  return (
    <>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Text fontWeight={400} fontSize="md">
            Select your size
          </Text>
        </ModalHeader>
        <ModalCloseButton onClose={props.onClose} />
        <Divider />
        <ModalBody pb={6} pt={6}>
          <Body {...props} />
        </ModalBody>
      </ModalContent>
    </>
  );
};

const Body = ({ onClose, rug, vis }: Props) => {
  const dispatch = useAppDispatch();
  const { loadedIndex } = useSelector(productVisualisation);
  const { isLoading, data, error } = useGetRugProductsQuery({
    region: config.region,
    active: true,
    name: rug.name,
  });
  const products = data?.data[0].products;
  function handleClick(rugProduct: RugProductDb) {
    if (loadedIndex === null) return;
    dispatch(
      updateIndVisualisation({
        ind: loadedIndex!,
        data: { ...vis, rug_id: rugProduct.id },
      })
    );
    onClose();
  }

  if (isLoading) {
    return <Loading />;
  }
  if (error) {
    return <ApiError error={error} />;
  }

  const sortedProducts = sortRugsBySize([...(products || [])]);
  return (
    <Grid
      templateColumns="auto 1fr"
      alignItems="center"
      columnGap={2}
      rowGap={5}
    >
      {sortedProducts.map((rugProduct) => (
        <>
          <LinkButton
            as={'button'}
            to={''} // not used because of as='button' but required as prop for LinkButton
            secondary
            color="gray.600"
            fontSize={'sm'}
            onClick={() => handleClick(rugProduct)}
          >
            {calculateRugDimensions(rugProduct)}
          </LinkButton>
          <ProductPrice product={rugProduct} />
        </>
      ))}
    </Grid>
  );
};

export function priceFormat(num: string) {
  return '$' + Number(num).toFixed(2);
}

export function sortRugsBySize(rugProducts: RugProductDb[]) {
  return [...rugProducts].sort(
    (rugA, rugB) => rugA.width * rugA.length - rugB.width * rugB.length
  );
}

export default ChangeSize;
