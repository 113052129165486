import {
  FloorFilterResponse,
  RugFilterResponse,
  RugProductDb,
} from 'shared/build/models/product';

export function calculateRugDimensions(product: RugProductDb) {
  return `${trimNumber(product.length)}x${trimNumber(product.width)}cm`;
}

// parsing to string and back to nubmer removes trailing 0's in the decimals
function trimNumber(num: number) {
  return Number(num.toString());
}

// https://cloudinary.com/cookbook/resize_an_image
// Cloudinary has auto resize logic, if the urls are migrated to another service,
// this will need to be changed.
export function setDimensions(
  url: string,
  dimensions: { w: number; h: number }
) {
  const splitter = 'image/upload/';
  if (!url.includes(splitter)) {
    return url;
  }
  let [startUrl, endUrl] = url.split(splitter);
  if (endUrl.indexOf('w_') === 0) {
    endUrl = endUrl.slice(endUrl.indexOf('/') + 1);
  }
  return `${startUrl}${splitter}w_${dimensions.w},h_${dimensions.h}/${endUrl}`;
}

export function isRug(object: any): object is RugFilterResponse {
  return 'name' in object;
}

export function isFlooring(object: any): object is FloorFilterResponse {
  return 'brand_category' in object;
}

export function checkIsBrandAndCategorySame(
  brand: string | null,
  category: string | null
) {
  const regex = /-/g;
  const cleanedBrand = brand?.replace(regex, ' ').trim() ?? '';
  const cleanedCategory = category?.replace(regex, ' ').trim() ?? '';
  return cleanedBrand === cleanedCategory;
}

export function createBrandDetails(floor: FloorFilterResponse) {
  const isBrandAndCategorySame = checkIsBrandAndCategorySame(
    floor.brand_category.brand,
    floor.brand_category.brand_category
  );
  const brandDetails = isBrandAndCategorySame
    ? floor.brand_category.brand_category
    : `${floor.brand_category.brand} - ${floor.brand_category.brand_category}`;

  return brandDetails;
}
