import { VStack, Flex, Spacer, Box, Button } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { SecondaryHeader } from '../../UI/Text';
import { Thumbnail, ThumbnailContainer } from './Thumbnail';
import { isSelected } from './helpers';
import { useDispatch, useSelector } from 'react-redux';
import {
  resetSelection,
  photos,
  toggleSelection,
  removeRoom,
  setRooms,
  addNewRoom,
} from './photosSlice';
import { deleteRoomApi } from '../../utils/api';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { AddPhotosBtnNew, UploadImage, useUpload } from './AddPhotos';
import axios from '../../axios';
import { config } from '../../config';
import { io } from 'socket.io-client';

export type PhotoUiModes = 'select' | 'edit' | 'none';

export default function PhotosNew() {
  const { selectedIds, data } = useSelector(photos);
  const saved = useAppSelector((state) => state.saved.data);
  const dispatch = useDispatch();
  const [mode, setMode] = useState('select' as PhotoUiModes);
  const userData = data.filter((d) => d.user && d.visible);
  const isUserData = Boolean(userData.length);

  const { files, setFiles, getRootProps, getInputProps, uploadImage } =
    useUpload();

  const addPhoto = async (image: string) => {
    const res = await uploadImage(image);
    dispatch(addNewRoom(res));
    setFiles(files.slice(0, -1));
  };

  useEffect(() => {
    dispatch(resetSelection());
  }, [mode]);
  useEffect(() => {
    if (mode === 'edit' && !isUserData) {
      setMode('select');
    }
  }, [mode, isUserData]);

  async function fetchRooms() {
    const roomsRes = await axios.get(
      '/api/v1/room/list?region=' + config.region
    );
    const rooms = roomsRes.data.data;
    dispatch(setRooms(rooms));
  }
  useEffect(() => {
    fetchRooms();
  }, []);

  useInspirationStationPolling(fetchRooms);

  return (
    <VStack id="photos-new" spacing="8" mt="10">
      <AddPhotosBtnNew />

      {files.length > 0 && (
        <Box as="section" w="full" p="4" rounded="2xl">
          {files.map((file, i) => (
            <UploadImage
              key={file.preview}
              preview={file.preview}
              addPhoto={addPhoto}
              upload={i === files.length - 1}
            />
          ))}
        </Box>
      )}

      {userData.length > 0 && (
        <Box
          as="section"
          w="full"
          p="4"
          rounded="2xl"
          backgroundColor="#f1f1f1"
        >
          <Box width="100%">
            {Boolean(userData.length) && (
              <Box display="flex" alignItems="center" justifyContent="center">
                <SecondaryHeader
                  flex="1"
                  ml="40px" // the width of the edit button so that the header is centered
                >
                  Your photos
                </SecondaryHeader>
                <Button
                  px="0"
                  fontWeight="lighter"
                  color="blue.600"
                  backgroundColor="transparent"
                  onClick={() => setMode(mode === 'select' ? 'edit' : 'select')}
                >
                  {mode === 'select' ? 'Edit' : 'Done'}
                </Button>
              </Box>
            )}
          </Box>
          <Spacer marginTop="4" />
          <Flex direction="row" wrap={'wrap'}>
            {userData.map((photo) => {
              const isDeleteDisabled = saved.some(
                (savedItem) => savedItem.room_public_id === photo.id
              );
              const tooltip = isDeleteDisabled
                ? 'Cannot delete when used in saved items'
                : undefined;

              return (
                <ThumbnailContainer key={photo.id}>
                  <Thumbnail
                    imageUrl={photo.thumbnail_url}
                    key={photo.id}
                    selected={isSelected(selectedIds, photo.id)}
                    mode={mode}
                    disableDelete={tooltip}
                    deleteSelect={async () => {
                      await deleteRoomApi(photo.id);
                      dispatch(removeRoom(photo.id));
                    }}
                    toggleSelect={() => dispatch(toggleSelection(photo.id))}
                  />
                </ThumbnailContainer>
              );
            })}
          </Flex>
        </Box>
      )}

      <Box
        as="section"
        w="full"
        backgroundColor="#f1f1f1"
        rounded="2xl"
        p="4"
        pointerEvents={mode === 'edit' ? 'none' : 'all'}
      >
        <SecondaryHeader>
          Don't have a photo?
          <br />
          Try our gallery photos
        </SecondaryHeader>
        <Spacer marginTop="4" />
        <Flex direction="row" wrap={'wrap'}>
          {data
            .filter((d) => !d.user && d.visible)
            .map((d) => {
              return (
                <ThumbnailContainer key={d.id}>
                  <Thumbnail
                    mode={mode === 'select' ? 'select' : 'none'}
                    imageUrl={d.thumbnail_url}
                    key={d.id}
                    selected={isSelected(selectedIds, d.id)}
                    deleteSelect={() => window.alert('invalid action')}
                    toggleSelect={() => dispatch(toggleSelection(d.id))}
                  />
                </ThumbnailContainer>
              );
            })}
        </Flex>
      </Box>
      <Spacer h={4}></Spacer>
    </VStack>
  );
}

export const emptyVisualisationData = {
  floor_id: null as number | null,
  floor_config: { orientation: 'default' as 'default' },
  rug_id: null as number | null,
  rug_config: {
    rotation: Math.PI / 2,
    position: { x: 0, z: 0 },
  },
  blind_id: null as number | null,
  blind_config: [
    {
      points: [
        { x: 10, y: 10 },
        { x: 680, y: 10 },
        { x: 680, y: 500 },
        { x: 10, y: 500 },
      ],
    },
  ],
};

// polling added as inspiration station users scan a qr code to upload from their phone
const useInspirationStationPolling = (fetchRooms: () => void) => {
  const isInspirationStation = useAppSelector(
    (state) => state.ui.isInspirationStation
  );
  const appDispatch = useAppDispatch();

  useEffect(() => {
    if (isInspirationStation) {
      const socket = io({ path: '/api/socket' });
      socket.on('photos', (session, photo) => {
        const token = localStorage.getItem('roomview_token');
        const { access_token } = JSON.parse(token || '');
        if (session !== access_token) {
          return;
        }
        appDispatch(addNewRoom(photo));
      });
    }
  }, [isInspirationStation]);
};
