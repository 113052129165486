import { useEffect, useRef } from 'react';
import { Text, Center, Box } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { FloorFilterResponse, Product } from 'shared/build/models/product';
import { productData, setShowFloorFilters } from './productDataSlice';
import { FixedSizeList as List } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';
import AutoSizer from 'react-virtualized-auto-sizer';
import RowMobile from '../ProductList/Row';

type Props = {
  updateSelectedProduct: (id: number) => void;
  hasNextPage: boolean;
  loadNextPage: () => void;
  filteredData: FloorFilterResponse[];
};
export function FloorProductListMobile({
  updateSelectedProduct,
  hasNextPage,
  loadNextPage,
  filteredData,
}: Props) {
  const dispatch = useDispatch();
  const { floorFilters } = useSelector(productData);

  const listRef = useRef<any>(null);

  useEffect(() => {
    listRef?.current?.scrollToItem(0);
  }, [floorFilters]);

  function dispatchSelectProduct(product: Product) {
    dispatch(setShowFloorFilters(false));
    updateSelectedProduct(product.id);
  }

  if (filteredData.length === 0) {
    return (
      <Center m="2">
        <Text>No products found</Text>
      </Center>
    );
  }

  const isItemLoaded = (index: number) => {
    return !hasNextPage || index < filteredData.length;
  };

  const itemCount = hasNextPage ? filteredData.length + 1 : filteredData.length;

  return (
    <Box flexGrow={1}>
      <InfiniteLoader
        isItemLoaded={isItemLoaded}
        itemCount={itemCount}
        loadMoreItems={loadNextPage}
        threshold={5}
      >
        {({ onItemsRendered, ref }) => (
          <AutoSizer>
            {({ height, width }) => (
              <List
                ref={(list) => {
                  // @ts-ignore - https://github.com/bvaughn/react-window/issues/324#issuecomment-528887341
                  ref(list);
                  listRef.current = list;
                }}
                onItemsRendered={onItemsRendered}
                itemData={{
                  elems: filteredData,
                  dispatchSelectProduct,
                  isItemLoaded,
                }}
                itemCount={itemCount}
                itemSize={106}
                height={height}
                width={width}
              >
                {RowMobile}
              </List>
            )}
          </AutoSizer>
        )}
      </InfiniteLoader>
    </Box>
  );
}
