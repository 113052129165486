import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  BlindProductDb,
  FloorProductDb,
  RugProductDb,
} from 'shared/build/models/product';
import { SavedApi } from 'shared/build/models/saved';
import { RugPosition, ImagePoint } from 'shared/build/models/visualiser';
import { RootState } from '../../redux/store';
import { emptyVisualisationData } from '../Photos/PhotosNew';

export type VisualisationData = Omit<
  SavedApi,
  'id' | 'thumbnail_url' | 'watermark_url'
>;
export interface SelectionState {
  data: VisualisationData[];
  selectedIndex: null | number;
  loadedIndex: null | number;
  isLoadingRoom: boolean;
  isLoadingProduct: boolean;
  selectedProduct: {
    product: BlindProductDb | RugProductDb | FloorProductDb;
    brand: string;
  } | null;
}

const initialState: SelectionState = {
  data: [],
  selectedIndex: null,
  loadedIndex: null,
  isLoadingRoom: false,
  isLoadingProduct: false,
  selectedProduct: null,
};

export const productVisualisationSlice = createSlice({
  name: 'productVisualisation',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setVisualisations: (state, action: PayloadAction<VisualisationData[]>) => {
      const newLength = action.payload.length;
      if (newLength !== state.data.length) {
        state.selectedIndex = newLength ? 0 : null;
        state.loadedIndex = newLength ? 0 : null;
      }
      state.data = action.payload;
    },
    updateIndVisualisation: (
      state,
      action: PayloadAction<{ ind: number; data: VisualisationData }>
    ) => {
      const ind = action.payload.ind;
      if (state.data[ind]) state.data[ind] = action.payload.data;
    },
    selectNext: (state) => {
      const selectedInd = state.selectedIndex || 0;
      state.selectedIndex =
        selectedInd >= state.data.length - 1 ? 0 : selectedInd + 1;
    },
    selectPrevious: (state) => {
      const selectedInd = state.selectedIndex || 0;
      const isAtStart = selectedInd === 0;
      const newIndex = isAtStart ? state.data.length - 1 : selectedInd - 1;
      state.selectedIndex = newIndex;
    },
    setSelectedInd: (state, action: PayloadAction<number | null>) => {
      state.selectedIndex = action.payload;
    },
    setLoadedInd: (state, action: PayloadAction<number | null>) => {
      state.loadedIndex = action.payload;
    },
    setRoomLoadingState: (state, action: PayloadAction<boolean>) => {
      state.isLoadingRoom = action.payload;
    },
    setProductLoadingState: (state, action: PayloadAction<boolean>) => {
      state.isLoadingProduct = action.payload;
    },
    updateRug: (
      state,
      action: PayloadAction<{ index: number; id: number | null }>
    ) => {
      const { index, id } = action.payload;
      state.data[index].rug_id = id;
    },
    resetRugPosition: (
      state,
      action: PayloadAction<{
        index: number;
        position?: RugPosition;
      }>
    ) => {
      const { index, position } = action.payload;
      const rugConfig = state.data[index].rug_config;
      if (!rugConfig) return;
      const newPosition = position
        ? position
        : emptyVisualisationData.rug_config.position;
      rugConfig.position = newPosition;
    },
    updateBlind: (
      state,
      action: PayloadAction<{ index: number; id: number | null }>
    ) => {
      const { index, id } = action.payload;
      state.data[index].blind_id = id;
    },
    addBlind: (state, action: PayloadAction<number>) => {
      const index = action.payload;
      const conf = state.data[index];
      if (!conf.blind_config || conf.blind_config.length === 0) {
        conf.blind_config = emptyVisualisationData.blind_config;
        return;
      }
      conf.blind_config = conf.blind_config.concat(
        emptyVisualisationData.blind_config
      );
    },
    removeBlinds: (
      state,
      action: PayloadAction<{
        index: number;
        defaultPoints?: ImagePoint[][];
      }>
    ) => {
      const { index, defaultPoints } = action.payload;
      const vis = state.data[index];
      vis.blind_id = null;
      if (defaultPoints) {
        vis.blind_config = defaultPoints.map((points) => ({ points }));
      } else {
        vis.blind_config = emptyVisualisationData.blind_config;
      }
    },
    resetBlindPosition: (
      state,
      action: PayloadAction<{ index: number; points?: ImagePoint[][] }>
    ) => {
      const { index, points } = action.payload;
      const conf = state.data[index];
      if (!conf.blind_config) return;
      const defaultPointsData = emptyVisualisationData.blind_config[0].points;
      if (points) {
        conf.blind_config = conf.blind_config.map((blind, i) => {
          if (points[i]) {
            return {
              ...blind,
              points: points[i],
            };
          } else {
            return {
              ...blind,
              points: defaultPointsData.map((points) => ({
                x: points.x + i * 50,
                y: points.y + i * 50,
              })),
            };
          }
        });
        return;
      }
      conf.blind_config = conf.blind_config.map((blind, i) => ({
        ...blind,
        points: defaultPointsData.map((points) => ({
          x: points.x + i * 50,
          y: points.y + i * 50,
        })),
      }));
    },
    setSelectedProductInfo: (
      state,
      action: PayloadAction<{
        brand: string;
        product: BlindProductDb | RugProductDb | FloorProductDb;
      } | null>
    ) => {
      state.selectedProduct = action.payload;
    },
  },
});

export const {
  setVisualisations,
  selectNext,
  selectPrevious,
  updateIndVisualisation,
  setRoomLoadingState,
  setProductLoadingState,
  setLoadedInd,
  setSelectedInd,
  updateRug,
  resetRugPosition,
  updateBlind,
  resetBlindPosition,
  addBlind,
  removeBlinds,
  setSelectedProductInfo,
} = productVisualisationSlice.actions;

export const productVisualisation = (state: RootState) =>
  state.productVisualisation;

export default productVisualisationSlice.reducer;
