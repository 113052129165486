import {
  ModalOverlay,
  ModalContent,
  ModalHeader,
  Center,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Text,
} from '@chakra-ui/react';
import { RegButton } from '../../UI/Buttons';

type Props = {
  onClose: () => void;
  videoUrl: string;
  heading: string;
};

const ProductOnboarding = ({ onClose, heading, videoUrl }: Props) => {
  return (
    <>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Center>
            <Text
              fontWeight={300}
              fontSize="sm"
              width="200px"
              textAlign="center"
            >
              {heading}
            </Text>
          </Center>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody pr="0" pl="0">
          <video height="500" width="600" src={videoUrl} controls />
        </ModalBody>
        <ModalFooter justifyContent="center">
          <RegButton primary onClick={onClose}>
            Start
          </RegButton>
        </ModalFooter>
      </ModalContent>
    </>
  );
};

export default ProductOnboarding;
