"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BlindTypes = exports.RugDesign = exports.RugCategory = exports.RugShape = exports.RugSize = exports.LifeStyle = exports.FloorStyle = exports.FlooringSubCategory = exports.ReflectionType = exports.FloorType = exports.Region = void 0;
var Region;
(function (Region) {
    Region["au"] = "au";
    Region["nz"] = "nz";
})(Region = exports.Region || (exports.Region = {}));
var FloorType;
(function (FloorType) {
    FloorType["carpet"] = "Carpet";
    FloorType["timber"] = "Timber";
    FloorType["laminate"] = "Laminate";
    FloorType["luxuryVinyl"] = "Luxury Vinyl";
    FloorType["rigidHybrid"] = "Rigid/Hybrid";
})(FloorType = exports.FloorType || (exports.FloorType = {}));
var ReflectionType;
(function (ReflectionType) {
    ReflectionType["hardFlooring"] = "Hard Flooring";
    ReflectionType["carpet"] = "Carpet";
    ReflectionType["laminate"] = "Laminate";
})(ReflectionType = exports.ReflectionType || (exports.ReflectionType = {}));
var FlooringSubCategory;
(function (FlooringSubCategory) {
    FlooringSubCategory["solutionDyedNylon"] = "Solution Dyed Nylon";
})(FlooringSubCategory = exports.FlooringSubCategory || (exports.FlooringSubCategory = {}));
var FloorStyle;
(function (FloorStyle) {
    FloorStyle["oneStrip"] = "1 Strip";
})(FloorStyle = exports.FloorStyle || (exports.FloorStyle = {}));
var LifeStyle;
(function (LifeStyle) {
    LifeStyle["familyFriendlyChoices"] = "Family Friendly Choices";
    LifeStyle["carpet"] = "Carpet";
    LifeStyle["laminate"] = "Laminate";
})(LifeStyle = exports.LifeStyle || (exports.LifeStyle = {}));
var RugSize;
(function (RugSize) {
    RugSize["sml"] = "Small";
    RugSize["md"] = "Medium";
    RugSize["lg"] = "Large";
})(RugSize = exports.RugSize || (exports.RugSize = {}));
var RugShape;
(function (RugShape) {
    RugShape["rect"] = "Rectangle";
    RugShape["circle"] = "Circle";
})(RugShape = exports.RugShape || (exports.RugShape = {}));
var RugCategory;
(function (RugCategory) {
    RugCategory["contemporary"] = "Contemporary";
    RugCategory["modern"] = "Modern";
})(RugCategory = exports.RugCategory || (exports.RugCategory = {}));
var RugDesign;
(function (RugDesign) {
    RugDesign["pattern"] = "Pattern";
})(RugDesign = exports.RugDesign || (exports.RugDesign = {}));
var BlindTypes;
(function (BlindTypes) {
    BlindTypes["Roman"] = "Roman Blinds";
    BlindTypes["Shutter"] = "Shutters";
    BlindTypes["Venetian"] = "Venetians";
    BlindTypes["Vertical"] = "Vertical Blinds";
    BlindTypes["Roller"] = "Roller Blinds";
    BlindTypes["Panel"] = "Panel Glide";
    BlindTypes["CurtainSheers"] = "Curtains & Sheers";
})(BlindTypes = exports.BlindTypes || (exports.BlindTypes = {}));
