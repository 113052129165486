import { createIcon, IconButton, Tooltip } from '@chakra-ui/react';
import { BsInfoCircle } from 'react-icons/bs';
import { useDispatch } from 'react-redux';
import {
  BlindProductDb,
  FloorProductDb,
  RugProductDb,
} from 'shared/build/models/product';
import { useAppSelector } from '../../../redux/hooks';
import { setSelectedProductInfo } from '../productVisualisationSlice';

type Props = {
  url: string;
  product: BlindProductDb | FloorProductDb | RugProductDb;
  brand: string;
};

const ChoicesInfo = createIcon({
  displayName: 'Choices Info',
  viewBox: '0 0 11.92 11.92',
  path: (
    <>
      <defs>
        <filter id="drop-shadow-1" filterUnits="userSpaceOnUse">
          <feOffset dx="0" dy="0" />
          <feGaussianBlur result="blur" stdDeviation="1.37" />
          <feFlood floodColor="#000" floodOpacity=".15" />
          <feComposite in2="blur" operator="in" />
          <feComposite in="SourceGraphic" />
        </filter>
      </defs>
      <path
        d="M11.14,5.97c0,2.86-2.33,5.18-5.18,5.17-2.86,0-5.18-2.32-5.17-5.19,0-2.86,2.33-5.18,5.18-5.17,2.86,0,5.18,2.33,5.17,5.18Z"
        fill="#fff"
        stroke="#666"
        strokeMiterlimit={10}
        strokeWidth="0.025em"
      />
      <circle cx="5.96" cy="3.46" r=".69" fill="#636464" strokeWidth="0" />
      <line
        x1="5.96"
        y1="5.38"
        x2="5.96"
        y2="8.93"
        fill="none"
        stroke="#636464"
        strokeLinecap="round"
        strokeMiterlimit={10}
        strokeWidth=".05em"
      />
    </>
  ),
});

const ProductInfo = (props: Props) => {
  const dispatch = useDispatch();
  const activeProduct = useAppSelector(
    (state) => state.productVisualisation.selectedProduct
  );
  const handleClickValue =
    activeProduct === null
      ? { product: props.product, brand: props.brand }
      : null;
  return (
    <Tooltip label="Product Info" rounded="md" hasArrow>
      <IconButton
        size="sm"
        h="fit-content"
        w="fit-content"
        minWidth="unset"
        fontSize="1.3rem"
        variant="ghost"
        _hover={{
          bg: 'gray.200',
        }}
        rounded="full"
        position="absolute"
        bottom="1"
        left="1"
        icon={<ChoicesInfo />}
        aria-label="product info"
        onClick={() => dispatch(setSelectedProductInfo(handleClickValue))}
      />
    </Tooltip>
  );
};
export default ProductInfo;
