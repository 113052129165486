import { Box, Image } from '@chakra-ui/react';
import React from 'react';
import {
  BlindProductDb,
  FloorProductDb,
  RugProductDb,
} from 'shared/build/models/product';
import { setDimensions } from '../helpers';

export enum BorderType {
  full = 'full',
  discrete = 'discrete',
  none = 'none',
}
type Props = {
  product: RugProductDb | FloorProductDb | BlindProductDb;
  onClick?: () => void;
  size: number;
  border?: BorderType;
  children?: React.ReactNode;
};

export function ProductThumbnail({
  product,
  onClick,
  size,
  border = BorderType.none,
  children,
}: Props) {
  const borderStyle = getBorderStyle(border);
  return (
    <Box
      as={!!onClick ? 'button' : 'div'}
      onClick={onClick}
      position="relative"
    >
      <Image
        draggable={false}
        onDragStart={(e) => e.preventDefault()} // draggable={false} only works on chrome && css properties simply don't work
        _hover={{ opacity: 0.85 }}
        border={borderStyle}
        rounded="lg"
        ignoreFallback
        fit="cover"
        src={setDimensions(product.swatch_file_path, {
          w: size,
          h: size,
        })}
        boxSize={size + 'px'}
      />
      {children}
    </Box>
  );
}
export default ProductThumbnail;

function getBorderStyle(type: BorderType) {
  switch (type) {
    case BorderType.full: {
      return '2px solid #444';
    }
    case BorderType.discrete: {
      return '1px solid #dcdcdc';
    }
    case BorderType.none: {
      return 'none';
    }
    default: {
      return 'none';
    }
  }
}
