import { Stage, Sprite, useTick, useApp, Graphics } from '@inlet/react-pixi';
import * as PIXI from 'pixi.js';
import React, { useRef, useState } from 'react';
import { useMemo } from 'react';
import { useContext } from 'react';
import { useEffect } from 'react';
import { Provider, useSelector } from 'react-redux';
import { lerp } from 'three/src/math/MathUtils';
import { threeCanv } from '.';
import { store } from '../../../../redux/store';
import { ProductView } from '../../Products';
import { productVisualisation } from '../../productVisualisationSlice';
import { LoadedContext } from '../Visualiser';
import { RoomLoadedImage, BlindLoadedImage } from './AssetLoader';
import { Blind } from './Blinds';

interface IProps {
  width: number;
  height: number;
  room: RoomLoadedImage;
  hasFlooring: boolean;
  floorBrightness?: number;
  blind: BlindLoadedImage | null;
  currentView: ProductView;
}

export function RoomVisualiser(props: IProps) {
  const val = useContext(LoadedContext);
  // Provider is used because like react-three-fiber, react-pixi does not have access to redux store.
  // https://github.com/pmndrs/react-three-fiber/issues/43#issuecomment-476073615
  return (
    <Stage
      id={'bufferStage'}
      renderOnComponentChange
      width={props.width}
      height={props.height}
      options={{
        backgroundAlpha: 0,
        antialias: false,
        resolution: 1,
        preserveDrawingBuffer: true, // allows us to take screen shots
      }}
    >
      <Provider store={store}>
        <LoadedContext.Provider value={val}>
          <RoomLoaderPixi {...props} />
        </LoadedContext.Provider>
      </Provider>
    </Stage>
  );
}
declare global {
  interface Window {
    roomviewPixiApp?: PIXI.Application;
  }
}
export const RoomLoaderPixi = (props: IProps) => {
  const [floorTexture, setFloorTexture] = React.useState(
    null as null | PIXI.Texture
  );
  const app = useApp();

  useEffect(() => {
    window.roomviewPixiApp = app;
    app.renderer.options.antialias = false;
    app.renderer.render(app.stage);
    app.renderer.plugins.interaction.setTargetElement(threeCanv(), 1);
    setFloorTexture(PIXI.Texture.from(threeCanv()));
  }, []);

  useTick(() => {
    // Stop updating floor texture while new room is loading/rendering
    if (floorTexture) {
      floorTexture.update();
    }
  });

  return <RoomElements {...props} floorTexture={floorTexture} />;
};

export function RoomElements(
  props: IProps & { floorTexture: null | PIXI.Texture }
) {
  const loadedInd = useContext(LoadedContext);
  const vis = useSelector(productVisualisation).data[loadedInd!];
  return (
    <>
      {props.room.mask && !props.hasFlooring && (
        <Sprite
          texture={props.room.image}
          width={props.width}
          height={props.height}
        />
      )}
      {props.floorTexture && (
        <Sprite texture={props.floorTexture} x={0} y={0} />
      )}
      {props.room.reflection && (
        <ChoicesProvidedRoom
          {...props.room}
          width={props.width}
          height={props.height}
          floorBrightness={props.floorBrightness}
        />
      )}
      {props.room.mask && (
        <UserProvidedRoom
          {...props.room}
          width={props.width}
          height={props.height}
          floorBrightness={props.floorBrightness}
        />
      )}
      {props.blind &&
        (vis.blind_config || []).map((v, ind) => {
          return <Blind assets={props.blind!} blindInd={ind} />;
        })}
    </>
  );
}

export function ChoicesProvidedRoom(
  props: RoomLoadedImage & {
    width: number;
    height: number;
    floorBrightness?: number;
  }
) {
  const alphaFilter = useMemo(() => {
    const inverseBrightness = 1 - (props.floorBrightness || 0.5);
    const shadowStrength = lerp(
      0.1,
      0.6,
      Math.min(1, Math.pow(inverseBrightness, 2) + inverseBrightness)
    );

    return new PIXI.filters.AlphaFilter(shadowStrength);
  }, [props.floorBrightness]);
  return (
    <>
      <Sprite
        texture={props.reflection}
        x={0}
        y={0}
        filters={[alphaFilter]}
        width={props.width}
        height={props.height}
      />
      <Sprite
        texture={props.image}
        x={0}
        y={0}
        width={props.width}
        height={props.height}
      />
    </>
  );
}

const blurFilter1 = new PIXI.filters.BlurFilter(25);
blurFilter1.repeatEdgePixels = false;
const contrastFilter1 = new PIXI.filters.ColorMatrixFilter();
contrastFilter1.grayscale(0.35, true);
contrastFilter1.contrast(0.75, true);
const hardShadowBlurFilter = new PIXI.filters.BlurFilter(1);
export function UserProvidedRoom(
  props: RoomLoadedImage & {
    width: number;
    height: number;
    floorBrightness?: number;
  }
) {
  const maskRef = useRef(null);
  const shadowMaskRef = useRef(null);
  const [maskLoaded, setMaskLoaded] = useState(false);
  useEffect(() => {
    // There appears to be an internal react-pixi race condition where the
    // mask being rendered at the same time as the sprite it applies to
    // causes it to not be applied
    setMaskLoaded(true);
  });
  const [softAlphaFilter, hardAlphaFilter] = useMemo(() => {
    // This determines the strength of the shadows
    // for lighter textures, we expect the shadows to also be lighter.
    // this is because light bounces off lighter materials more.
    const inverseBrightness = 1 - (props.floorBrightness || 0.5);
    const softShadow = lerp(
      0.05,
      0.4,
      Math.min(1, Math.pow(inverseBrightness, 2) + inverseBrightness)
    );
    const softAlphaFilter = new PIXI.filters.AlphaFilter(softShadow); // lerp between 0.22 and 0.82
    softAlphaFilter.blendMode = PIXI.BLEND_MODES.MULTIPLY;
    const hardShadow = lerp(
      0.1,
      1,
      Math.min(1, Math.pow(inverseBrightness, 2) + inverseBrightness)
    );
    const hardAlphaFilter = new PIXI.filters.AlphaFilter(hardShadow); // lerp between 0.22 and 0.82
    hardAlphaFilter.blendMode = PIXI.BLEND_MODES.MULTIPLY;
    return [softAlphaFilter, hardAlphaFilter];
  }, [props.floorBrightness]);

  return (
    <>
      <Sprite
        texture={props.image}
        x={0}
        y={0}
        width={props.width}
        height={props.height}
        filters={[blurFilter1, contrastFilter1, softAlphaFilter]}
      />
      <Sprite
        texture={props.mask}
        x={0}
        y={0}
        ref={maskRef}
        width={props.width}
        height={props.height}
      />
      <Sprite
        texture={props.image}
        width={props.width}
        height={props.height}
        x={0}
        y={0}
        mask={maskRef.current}
      />
      <Sprite
        texture={props.shadow_mask}
        x={0}
        y={-1}
        ref={shadowMaskRef}
        width={props.width}
        height={props.height}
      />
      <Sprite
        texture={props.image}
        x={0}
        y={0}
        width={props.width}
        height={props.height}
        mask={shadowMaskRef.current}
        filters={[contrastFilter1, hardShadowBlurFilter, hardAlphaFilter]}
      />
    </>
  );
}
