import { Redirect, useLocation, useParams } from 'react-router-dom';
import { RoutePaths } from '../../App';
import { useAppSelector } from '../../redux/hooks';
import { useQuery } from '../../utils/hooks';
import { userPhotos } from '../Photos/photosSlice';

export default function Home() {
  const photos = useAppSelector(userPhotos);
  const saved = useAppSelector((state) => state.saved.data);
  const isNewUser = saved.length === 0 && photos.length === 0;
  const sharedId = useQuery('shared_id');
  const modal = useQuery('modal');
  if (sharedId) {
    const queryParams = modal
      ? `?shared_id=${sharedId}&modal=true`
      : `?shared_id=${sharedId}`;

    return <Redirect to={`${RoutePaths.flooring}${queryParams}`} />;
  }
  if (isNewUser) {
    return <Redirect to={RoutePaths.onboarding} />;
  }
  return <Redirect to={`${RoutePaths.saved}?welcome=true`} />;
}
