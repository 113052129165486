import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../redux/store';

export interface SelectionState {
  pannable: boolean;
  loadingAssets: boolean;
  showPannableInstructions: boolean;
  showRugControls: boolean;
  showBlindControls: boolean;
}

const initialState: SelectionState = {
  pannable: true,
  loadingAssets: false,
  showPannableInstructions: true,
  showRugControls: false,
  showBlindControls: false,
};

export const visualiserSlice = createSlice({
  name: 'visualiser',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setPannable: (state, action: PayloadAction<boolean>) => {
      state.pannable = action.payload;
    },
    setLoadingAssets: (state, action: PayloadAction<boolean>) => {
      state.loadingAssets = action.payload;
    },
    setShowPannableInstructions: (state, action: PayloadAction<boolean>) => {
      state.showPannableInstructions = action.payload;
    },
    setShowRugControls: (state, action: PayloadAction<boolean>) => {
      state.showRugControls = action.payload;
    },
    setShowBlindControls: (state, action: PayloadAction<boolean>) => {
      state.showBlindControls = action.payload;
    },
  },
});

export const {
  setPannable,
  setLoadingAssets,
  setShowPannableInstructions,
  setShowRugControls,
  setShowBlindControls,
} = visualiserSlice.actions;

export const visualiser = (state: RootState) => state.visualiser;

export default visualiserSlice.reducer;
