import { Flex } from '@chakra-ui/react';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useGetFloorProductsQuery } from '../../redux/api';
import { productData, setFloorProducts } from './ProductData/productDataSlice';
import { productVisualisation } from './productVisualisationSlice';
import { FloorSelectedProduct } from './SelectedProduct/FloorSelectedProduct';
import { config } from '../../config';
import { FloorFilterResponse } from 'shared/build/models/product';
import { flatMap } from 'lodash';

function FlooringProducts() {
  const { hasNextPage, loadNextPage, filteredData, allData } = useFloorData();

  return (
    <Flex
      backgroundColor="white"
      flexDir="column"
      w={{ base: '100%', sm: '300px', md: '300px', lg: '500px' }}
      p="0 10px"
      height={{ base: 'auto', lg: 'calc(100vh - 50px)' }}
    >
      <FloorSelectedProduct
        hasNextPage={hasNextPage}
        loadNextPage={loadNextPage}
        allData={allData}
        filteredData={filteredData}
      />
    </Flex>
  );
}
export default FlooringProducts;

const useFloorData = () => {
  const dispatch = useDispatch();
  const [offset, setOffset] = useState(0);

  const { floorFilters } = useSelector(productData);
  const visualisations = useSelector(productVisualisation);
  const visualisationProductIds = visualisations.data.reduce(
    (acc, visualisation) => {
      if (visualisation.floor_id !== null) {
        return [...acc, visualisation.floor_id];
      }
      return acc;
    },
    [] as number[]
  );
  const [allData, setAllData] = useState<{
    [key: string]: FloorFilterResponse[];
  }>({});

  const { data, error, isFetching } = useGetFloorProductsQuery({
    region: config.region,
    offset: offset.toString(),
    active: true,
    ...floorFilters,
  });

  const { data: visualisationProductData } = useGetFloorProductsQuery(
    {
      region: config.region,
      active: true,
      product_ids: visualisationProductIds,
    },
    {
      skip: visualisationProductIds.length === 0,
    }
  );

  useEffect(() => {
    setAllData({ [0]: data?.data || [] });
    setOffset(0);
  }, [floorFilters]);

  useEffect(() => {
    if (!data) return;
    setAllData({ ...allData, [data.meta.offset]: data.data });
  }, [data]);

  // combine product data with those needed in the visualisations
  const { filteredData, combined } = useMemo(() => {
    const data = flatMap(Object.values(allData), (floorGroup) => floorGroup);
    if (!visualisationProductData) {
      return { filteredData: data, combined: data };
    }

    const dataWithMissingFloors = addMissingFloors(
      data,
      visualisationProductData.data
    );
    const extraVisualisationProducts = visualisationProductData.data.filter(
      (visProductData) =>
        !data.some(
          (product) =>
            product.brand_category.id === visProductData.brand_category.id
        )
    );
    return {
      filteredData: data,
      combined: [...dataWithMissingFloors, ...extraVisualisationProducts],
    };
  }, [allData, visualisationProductData?.data]);

  useEffect(() => {
    dispatch(setFloorProducts(combined));
  }, [combined]);

  function loadNextPage() {
    setOffset((offset) => offset + 25);
  }

  const hasNextPage = data?.data.length === 25;

  return { hasNextPage, loadNextPage, allData: combined, filteredData };
};

function addMissingFloors(
  data: FloorFilterResponse[],
  visualisationProductData: FloorFilterResponse[]
) {
  const dataWithMissingFloors = data.map((product) => {
    const matchingVisProduct = visualisationProductData.find(
      (visProduct) => visProduct.brand_category.id === product.brand_category.id
    );
    if (!matchingVisProduct) {
      return product;
    }
    const missingFloors = matchingVisProduct.products.filter((visFloor) => {
      const isNotMissing = product.products.some(
        (floor) => floor.id === visFloor.id
      );
      return !isNotMissing;
    });

    return {
      ...product,
      products: [...product.products, ...missingFloors],
    };
  });
  return dataWithMissingFloors;
}
