import {
  Accordion,
  AccordionItem,
  AccordionButton,
  Box,
  AccordionIcon,
  AccordionPanel,
  Text,
  HStack,
  Tag,
  InputGroup,
  InputLeftElement,
  Input,
  InputRightElement,
  Icon,
  ExpandedIndex,
  VStack,
} from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  BlindFilterAttributesResponse,
} from 'shared/ts/models/product';
import {
  useGetBlindProductAttributesQuery,
} from '../../../redux/api';
import { RegButton } from '../../../UI/Buttons';
import { ApiError } from '../../../UI/ErrorMessage';
import { Loading } from '../../../UI/Loading';
import {
  changeBlindSearchTerm,
  clearBlindFilter,
  productData,
  toggleBlindFilter,
} from './productDataSlice';
import { MdSearch, MdBackspace } from 'react-icons/md';
import { useDebounce } from '../../../utils/hooks';
import { config } from '../../../config';

const filterElems: {
  name: string;
  filterKey: keyof BlindFilterAttributesResponse;
}[] = [
    {
      name: 'Name',
      filterKey: 'name',
    },
    {
      name: 'Width',
      filterKey: 'width',
    },
    {
      name: 'Length',
      filterKey: 'length',
    },
    {
      name: 'Colour Tone',
      filterKey: 'color_tone',
    },
    // {
    //   name: 'Price',
    //   filterKey: 'price_range',
    // },
  ];

export function BlindProductFiltersMobile() {
  const [openInd, setOpenInd] = useState<number[]>([]);
  const { data, error, isLoading } = useGetBlindProductAttributesQuery(
    config.region
  );
  if (isLoading) {
    return <Loading />;
  } else if (error) {
    return <ApiError error={error} />;
  }
  return (
    <Box>
      <Accordion
        mt={2}
        index={openInd as ExpandedIndex}
        onChange={(ind) => setOpenInd(Array.isArray(ind) ? ind : [ind])}
        allowToggle
        backgroundColor="transparent"
      >
        {filterElems.map((elem, i) => (
          <IndividualFilter
            isOpen={openInd.includes(i)}
            key={elem.name}
            name={elem.name}
            filterKey={elem.filterKey}
            options={data?.data[elem.filterKey] || []}
          />
        ))}
      </Accordion>
    </Box>
  );
}

export function BlindSearch() {
  const dispatch = useDispatch();
  const searchRef = useRef(null as null | HTMLInputElement);
  const search = useSelector(productData).blindFilters.search_term;
  const [searchInput, setSearchInput] = useState(search);
  const debouncedSearch = useDebounce<string>(searchInput, 300);
  useEffect(() => {
    dispatch(changeBlindSearchTerm(debouncedSearch));
  }, [debouncedSearch]);
  const showFilters = useSelector(productData).showBlindFilters;
  useEffect(() => {
    if (showFilters && searchRef.current) {
      searchRef.current!.focus();
    }
  }, [showFilters]);

  return (
    <Search
      ref={searchRef}
      value={searchInput}
      onChange={e => setSearchInput(e.target.value)}
      onClear={() => dispatch(changeBlindSearchTerm(''))}
    />
  );
}

export const Search = React.forwardRef(
  (
    props: {
      value: string;
      onClear: () => void;
      onChange: (e: any) => void;
      onFocus?: () => void;
    },
    ref
  ) => {
    return (
      <InputGroup>
        <InputLeftElement
          height={8}
          pointerEvents="none"
          children={<Icon as={MdSearch} color="gray.700" />}
        />
        <Input
          height={8}
          type="text"
          ref={ref as any}
          placeholder="Search..."
          value={props.value}
          onChange={props.onChange as any}
          onFocus={props.onFocus as any}
        />
        {props.onClear && (
          <InputRightElement
            height={8}
            children={
              <Icon
                cursor="pointer"
                onClick={props.onClear}
                as={MdBackspace}
                color={props.value ? 'gray.700' : 'gray.300'}
              />
            }
          />
        )}
      </InputGroup>
    );
  }
);

function IndividualFilter(props: {
  isOpen: boolean;
  name: string;
  filterKey: keyof BlindFilterAttributesResponse;
  options: string[];
}) {
  const dispatch = useDispatch();
  const blindFilters = useSelector(productData).blindFilters;
  const currentSelections = blindFilters[props.filterKey];

  return (
    <AccordionItem borderColor="white">
      <AccordionButton
        _focus={{ boxShadow: 'none' }}
        backgroundColor={'gray.100'}
        marginY={1}
        rounded="full"
        borderWidth={0}
      >
        <AccordionIcon />
        <Box flex="1" textAlign="left">
          <Text
            color={'brand.blue'}
            marginLeft={2}
            fontWeight={'normal'}
            fontSize="sm"
          >
            {props.name}
          </Text>
        </Box>
        {Boolean(currentSelections.length) && (
          <HStack>
            <Tag
              borderRadius="full"
              size="md"
              variant="solid"
              pl="9px"
              pb="2px"
              colorScheme="blue"
            >
              {currentSelections.length}
            </Tag>
            <Text
              ml={'4px'}
              fontWeight={'bold'}
              onClick={(e) => {
                e.stopPropagation();
                dispatch(clearBlindFilter(props.filterKey));
              }}
            >
              Clear
            </Text>
          </HStack>
        )}
      </AccordionButton>
      <AccordionPanel>
        <VStack
          spacing={2}
          maxHeight="300px"
          overflowY="auto"
          alignItems="flex-start"
        >
          {props.options.map((option) => {
            const isSelected = currentSelections.includes(option);
            const options = isSelected ? {} : { bg: 'white' };
            return (
              <RegButton
                onClick={() => {
                  dispatch(
                    toggleBlindFilter({
                      category: props.filterKey,
                      val: option,
                    })
                  );
                }}
                fontSize={'sm'}
                rounded={'full'}
                primary={isSelected}
                key={option}
                {...options}
              >
                {option}
              </RegButton>
            );
          })}
        </VStack>
      </AccordionPanel>
    </AccordionItem>
  );
}
