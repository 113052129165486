import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../redux/store';

export type WorkflowState = {
  pageData: [number, 1 | -1];
};

const initialState: WorkflowState = {
  pageData: [1, 1],
};

export const workflowSlice = createSlice({
  name: 'workflow',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    paginate: (state, action: PayloadAction<1 | -1>) => {
      state.pageData = [state.pageData[0] + action.payload, action.payload];
    },
  },
});

export const { paginate } = workflowSlice.actions;

export const workflow = (state: RootState) => state.productVisualisation;

export default workflowSlice.reducer;
