import {
  Accordion,
  AccordionItem,
  AccordionButton,
  Box,
  AccordionIcon,
  AccordionPanel,
  Text,
  HStack,
  Tag,
  InputGroup,
  InputLeftElement,
  Input,
  InputRightElement,
  Icon,
  ExpandedIndex,
  VStack,
} from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RugFilterAttributesResponse } from 'shared/build/models/product';
import { useGetRugProductAttributeQuery } from '../../../redux/api';
import { RegButton } from '../../../UI/Buttons';
import { ApiError } from '../../../UI/ErrorMessage';
import { Loading } from '../../../UI/Loading';
import {
  changeRugSearchTerm,
  clearRugFilter,
  productData,
  toggleRugFilter,
} from './productDataSlice';
import { MdSearch, MdBackspace } from 'react-icons/md';
import { useDebounce } from '../../../utils/hooks';
import { config } from '../../../config';

const filterElems: {
  name: string;
  filterKey: keyof RugFilterAttributesResponse;
}[] = [
  {
    name: 'Category',
    filterKey: 'category',
  },
  {
    name: 'Size',
    filterKey: 'size',
  },
  {
    name: 'Shape',
    filterKey: 'shape',
  },
  {
    name: 'Design',
    filterKey: 'design',
  },
  // {
  //   name: 'Price',
  //   filterKey: 'price_range',
  // },
];

export function RugProductFiltersMobile() {
  const [openInd, setOpenInd] = useState<number[]>([]);
  const { data, error, isLoading } = useGetRugProductAttributeQuery(
    config.region
  );
  if (isLoading) {
    return <Loading />;
  } else if (error) {
    return <ApiError error={error} />;
  }
  return (
    <Box>
      <Accordion
        mt={2}
        index={openInd as ExpandedIndex}
        onChange={(ind) => setOpenInd(Array.isArray(ind) ? ind : [ind])}
        allowToggle
        backgroundColor="transparent"
      >
        {filterElems.map((elem, i) => (
          <IndividualFilter
            isOpen={openInd.includes(i)}
            key={elem.name}
            name={elem.name}
            filterKey={elem.filterKey}
            options={data?.data[elem.filterKey]!.filter((val) => val)!}
          />
        ))}
      </Accordion>
    </Box>
  );
}

export function RugSearch() {
  const dispatch = useDispatch();
  const searchRef = useRef(null as null | HTMLInputElement);
  const search = useSelector(productData).floorFilters.search_term;
  const [searchInput, setSearchInput] = useState(search);
  const debouncedSearch = useDebounce<string>(searchInput, 300);
  useEffect(() => {
    dispatch(changeRugSearchTerm(debouncedSearch));
  }, [debouncedSearch]);
  const showFilters = useSelector(productData).showRugFilters;
  useEffect(() => {
    if (showFilters && searchRef.current) {
      searchRef.current!.focus();
    }
  }, [showFilters]);

  return (
    <Search
      ref={searchRef}
      value={searchInput}
      onChange={(e: any) => setSearchInput(e.target.value)}
      onClear={() => dispatch(changeRugSearchTerm(''))}
    />
  );
}

export const Search = React.forwardRef(
  (
    props: {
      value: string;
      onClear: () => void;
      onChange: (e: any) => void;
      onFocus?: () => void;
    },
    ref
  ) => {
    return (
      <InputGroup>
        <InputLeftElement
          height={8}
          pointerEvents="none"
          children={<Icon as={MdSearch} color="gray.700" />}
        />
        <Input
          height={8}
          type="text"
          ref={ref as any}
          placeholder="Search..."
          value={props.value}
          onChange={props.onChange as any}
          onFocus={props.onFocus as any}
        />
        {props.onClear && (
          <InputRightElement
            height={8}
            children={
              <Icon
                cursor="pointer"
                onClick={props.onClear}
                as={MdBackspace}
                color={props.value ? 'gray.700' : 'gray.300'}
              />
            }
          />
        )}
      </InputGroup>
    );
  }
);

function IndividualFilter(props: {
  isOpen: boolean;
  name: string;
  filterKey: keyof RugFilterAttributesResponse;
  options: string[];
}) {
  const dispatch = useDispatch();
  const rugFilters = useSelector(productData).rugFilters;
  const currentSelections = rugFilters[props.filterKey];

  return (
    <AccordionItem borderColor={'white'}>
      <AccordionButton
        _focus={{ boxShadow: 'none' }}
        backgroundColor={'gray.100'}
        marginY={1}
        rounded="full"
        borderWidth={0}
      >
        <AccordionIcon />
        <Box flex="1" textAlign="left">
          <Text color={'brand.blue'} marginLeft={2} fontWeight={'bold'}>
            {props.name}
          </Text>
        </Box>
        {Boolean(currentSelections.length) && (
          <HStack>
            <Tag
              borderRadius="full"
              size="md"
              variant="solid"
              pl="9px"
              pb="2px"
              colorScheme="blue"
            >
              {currentSelections.length}
            </Tag>
            <Text
              ml={'4px'}
              fontWeight={'bold'}
              onClick={(e) => {
                e.stopPropagation();
                dispatch(clearRugFilter(props.filterKey));
              }}
            >
              Clear
            </Text>
          </HStack>
        )}
      </AccordionButton>
      <AccordionPanel>
        <VStack
          spacing={2}
          maxHeight="300px"
          overflowY="auto"
          alignItems="flex-start"
        >
          {props.options.map((option) => {
            const isSelected = currentSelections.includes(option);
            const options = isSelected ? {} : { bg: 'white' };
            return (
              <RegButton
                onClick={() => {
                  dispatch(
                    toggleRugFilter({
                      category: props.filterKey,
                      val: option,
                    })
                  );
                }}
                fontSize={'sm'}
                rounded={'full'}
                primary={isSelected}
                key={option}
                {...options}
              >
                {option}
              </RegButton>
            );
          })}
        </VStack>
      </AccordionPanel>
    </AccordionItem>
  );
}
