import React from 'react';
import { RoomVisualiser } from './ThreeScene/RoomOverlayPixi';
import { ThreeDScene } from './ThreeScene';
import { Box, Flex } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { LoadedAssets, useRoomviewAssetLoader } from './ThreeScene/AssetLoader';
import { Loading } from '../../../UI/Loading';
import { RoomApi } from 'shared/build/models/room';
import { ProductView } from '../Products';
import {
  productVisualisation,
  VisualisationData,
  setLoadedInd,
} from '../productVisualisationSlice';
import { PannableContainer } from '../../../UI/PannableContainer';
import { SelectedData, useGenerateDefaultVisData } from './helper';
import { useEffect } from 'react';
import {
  FloorType,
  RugFilterResponse,
  RugProductDb,
} from 'shared/build/models/product';
import { RightClickHandler } from './ThreeScene/RightClick';

export const LoadedContext = React.createContext(null as null | number);

export function Visualiser(props: { view: ProductView }) {
  const dispatch = useDispatch();
  const vis = useSelector(productVisualisation);
  const visData = vis.data;
  const selectInd = vis.selectedIndex!;

  useGenerateDefaultVisData(selectInd, visData, props.view); // provides default data to current selected vis

  const { assets: loadedAssets, data: loadedData } = useRoomviewAssetLoader(
    visData[selectInd],
    selectInd
  );
  const loadedInd = loadedAssets.visualiserIndex;
  useEffect(() => {
    dispatch(setLoadedInd(loadedInd));
  }, [loadedInd]);
  if (loadedInd === null) {
    return (
      <Flex
        flexDir="row"
        p="5"
        width={{ base: '100%', sm: '50%' }}
        justifyContent="center"
      >
        <Loading />
      </Flex>
    );
  }
  const loadedRugProduct = getLoadedRug(
    loadedData.rugData ? [loadedData.rugData] : ([] as RugFilterResponse[]),
    loadedAssets.rug ? loadedAssets.rug.id : null
  );
  return (
    <Box
      w={{ base: '100%', sm: 'auto' }}
      h={{ base: '100%', sm: 'auto' }}
      flex={1}
      display="flex"
      flexDir="column"
    >
      <Box display="flex" w={'100%'} flex={1}>
        <LoadedContext.Provider value={loadedInd}>
          <VisualiseLoaded
            view={props.view}
            loadedVisualisation={visData[loadedInd]}
            loadedRoomData={loadedData.roomData!}
            loadedRugData={loadedRugProduct}
            loadedAssets={loadedAssets}
            loadedData={loadedData}
          />
        </LoadedContext.Provider>
      </Box>
    </Box>
  );
}

export function VisualiseLoaded(props: {
  loadedVisualisation: VisualisationData;
  loadedRoomData: RoomApi;
  loadedRugData: RugProductDb | null;
  loadedAssets: LoadedAssets;
  view: ProductView;
  loadedData: SelectedData;
}) {
  const { loadedVisualisation, loadedRoomData, loadedAssets, loadedRugData } =
    props;

  const orientation =
    loadedVisualisation.floor_config?.orientation ?? 'default';
  const floorInf = loadedRoomData.configuration.floor_inference;
  const rotation =
    orientation === 'default'
      ? floorInf.rotation
      : floorInf.rotation_alternative;
  if (!loadedRoomData || !loadedAssets.room) {
    return <Loading />;
  }
  // We use different rendering filter for 'Textured' flooring/rugs to reduce visual artifacts.
  // At the moment this is hardcoded to floors with brand category style of 'Texture' or
  // the Allure range for rugs.
  const floorIsTextured = props.loadedData.floorData
    ? props.loadedData.floorData.brand_category.style
        .toLowerCase()
        .includes('texture')
    : false;

  const rugIsTextured = loadedRugData
    ? loadedRugData.name.toLowerCase().includes('allure')
    : false;
  const [w, h] = [1280, 1280 * (loadedRoomData.height / loadedRoomData.width)];

  return (
    <>
      <Box pos="relative" flex="1">
        <RightClickHandler
          loadedVisualisation={loadedVisualisation}
          loadedData={props.loadedData}
          view={props.view}
        />
        <PannableContainer
          roomId={loadedAssets.room.id}
          w={w}
          h={h}
          currentView={props.view}
          orientation={orientation}
        >
          <RoomVisualiser
            width={w}
            height={h}
            floorBrightness={loadedAssets.floor?.brightness}
            hasFlooring={Boolean(loadedAssets.floor)}
            room={loadedAssets.room}
            blind={loadedAssets.blind}
            currentView={props.view}
          />
          <ThreeDScene
            floor={loadedAssets.floor}
            floorIsTextured={floorIsTextured}
            rug={loadedAssets.rug}
            rugData={loadedRugData}
            rugIsTextured={rugIsTextured}
            rotation={rotation}
            camera={{
              pitch: floorInf.pitch,
              roll: floorInf.roll,
              fovy: floorInf.fovy,
            }}
            width={w}
            height={h}
          />
        </PannableContainer>
      </Box>
    </>
  );
}

function getLoadedRug(allRugs: RugFilterResponse[], id: number | null) {
  if (!id) {
    return null;
  }
  const rug = allRugs.find((d) => d.products.some((p) => p.id === id));
  if (!rug) return null;
  return rug.products.find((p) => p.id === id)!;
}
