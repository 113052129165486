import { Box, Flex, Button } from '@chakra-ui/react';
import { AnimatePresence, motion } from 'framer-motion';
import { useLocation } from 'react-router-dom';
import { RoutePaths } from '../App';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { paginate } from './workflowSlice';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { useQuery } from '../utils/hooks';
import { photos } from './Photos/photosSlice';
import { useSku } from './Photos/helpers';
import { emptyVisualisationData } from './Photos/PhotosNew';
import { setFloorFilter } from './Products/ProductData/productDataSlice';
import {
  setVisualisations,
  VisualisationData,
} from './Products/productVisualisationSlice';

export function PhotosNextPage() {
  const { selectedIds, data } = useSelector(photos);
  const product = useQuery('product');
  const history = useHistory();
  const dispatch = useDispatch();

  const { rugData, floorData, blindsData, isLoading } = useSku();

  const productData = { ...emptyVisualisationData };
  if (rugData) {
    productData.rug_id = rugData.data[0]?.products[0].id ?? null;
  }

  if (blindsData) {
    productData.blind_id = blindsData.data[0]?.products[0].id ?? null;
  }

  if (floorData) {
    productData.floor_id = floorData.data[0]?.products[0].id ?? null;
    if (productData.floor_id) {
      dispatch(
        setFloorFilter({
          category: 'type',
          val: [floorData.data[0].brand_category.type],
        })
      );
    }
  }

  function clickNextPage() {
    if (isLoading) {
      return;
    }
    const selectedData: VisualisationData[] = data
      .filter((d) => selectedIds.includes(d.id))
      .map((d) => ({
        ...productData,
        blind_config:
          d.configuration.default_blind || emptyVisualisationData.blind_config,
        rug_config: {
          ...emptyVisualisationData.rug_config,
          ...d.configuration.default_rug,
        },
        floor_config: {
          ...emptyVisualisationData.floor_config,
          ...d.configuration.default_floor,
        },
        room_public_id: d.id,
      }));
    dispatch(setVisualisations(selectedData));
    let route = RoutePaths.flooring;
    if (product === 'rugs') {
      route = RoutePaths.rugs;
    }
    if (product === 'blinds') {
      route = RoutePaths.blinds;
    }
    history.push(route);
    dispatch(paginate(1));
  }

  return (
    <Button
      rounded="full"
      backgroundColor="#6CB33E"
      _disabled={{
        color: '#141516',
        backgroundColor: '#D0D0D0',
        cursor: 'not-allowed',
        _hover: {
          color: '#141516',
          backgroundColor: '#D0D0D0',
          cursor: 'not-allowed',
        },
      }}
      fontWeight="normal"
      color="white"
      mx={3}
      onClick={clickNextPage}
      disabled={!selectedIds.length}
    >
      Proceed
    </Button>
  );
}

export function showFooter(pageCurrentIndex: number, pathname: string) {
  return (
    (pageCurrentIndex > 1 && pathname.includes(RoutePaths.onboarding)) ||
    pathname.includes(RoutePaths.photos)
  );
}

export default function Footer() {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const location = useLocation();
  const [currentIndex, direction] = useAppSelector(
    (state) => state.workflow.pageData
  );

  const ProceedButton = () => (
    <Button
      _disabled={{
        color: '#141516',
        backgroundColor: '#D0D0D0',
        cursor: 'not-allowed',
        _hover: {
          color: '#141516',
          backgroundColor: '#D0D0D0',
          cursor: 'not-allowed',
        },
      }}
      rounded="full"
      backgroundColor="#6CB33E"
      fontWeight="normal"
      color="white"
      mx={3}
      onClick={() => dispatch(paginate(1))}
      disabled={currentIndex === 5}
    >
      Proceed
    </Button>
  );

  return (
    <AnimatePresence>
      {showFooter(currentIndex, location.pathname) && (
        <motion.footer
          layout
          initial={{ marginBottom: '-100px' }}
          animate={{ marginBottom: '0px' }}
          exit={{ marginBottom: '-100px' }}
          transition={{ duration: 0.5 }}
          style={{
            position: 'absolute',
            bottom: 0,
          }}
        >
          <Box
            bgColor="#F1F1F1"
            px={4}
            width="100vw"
            display="flex"
            justifyContent="center"
          >
            <Flex
              h={16}
              alignItems={'center'}
              justifyContent={'space-between'}
              width="100%"
              maxWidth="400px"
            >
              <Button
                backgroundColor="white"
                fontWeight="normal"
                color="black"
                rounded="full"
                mx={3}
                onClick={() => {
                  if (location.pathname.includes(RoutePaths.photos)) {
                    history.push(RoutePaths.onboarding);
                  } else {
                    dispatch(paginate(-1));
                  }
                }}
              >
                Previous
              </Button>
              {currentIndex === 3 ||
              location.pathname.includes(RoutePaths.photos) ? (
                <PhotosNextPage />
              ) : (
                <ProceedButton />
              )}
            </Flex>
          </Box>
        </motion.footer>
      )}
    </AnimatePresence>
  );
}
