import {
  Box,
  Button,
  Divider,
  HStack,
  Icon,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  SkeletonCircle,
  SkeletonText,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';
import { IoShareOutline } from 'react-icons/io5';
import { MdMessage } from 'react-icons/md';
import { HiOutlineMail } from 'react-icons/hi';
import { FiLink } from 'react-icons/fi';
import { shareVisualisations } from '../../utils/api';
import CopyLink from './CopyLink';
import {
  createShareUrl,
  handleEmail,
  handleMessage,
  handleSaveImage,
} from './helpers';
import { ErrorMessage } from '../../UI/ErrorMessage';
import { VisualisationData } from '../Products/productVisualisationSlice';
import { useSelector } from 'react-redux';
import { ProductView } from '../Products/Products';
import { productData } from '../Products/ProductData/productDataSlice';
import { SavedApi } from 'shared/build/models/saved';
import { trackEvent } from '../../utils/tracking';
import { getIndData } from '../Products/Visualiser/helper';
import { useAppSelector } from '../../redux/hooks';
import ShareInspirationStation from '../InspirationStation/QRShare';
import { saveWatermarkImage } from '../Products/SelectedProduct/helpers';
import {
  BlindFilterResponse,
  FloorFilterResponse,
  RugFilterResponse,
} from 'shared/build/models/product';
import { SecondaryHeader } from '../../UI/Text';

type Props = {
  visualisations: (VisualisationData | SavedApi)[];
  view?: ProductView;
};

enum Status {
  ready = 'ready',
  loading = 'loading',
  error = 'error',
}

async function getPreviewImage(
  visualisations: (VisualisationData | SavedApi)[],
  floorFilter: FloorFilterResponse | undefined,
  rugFilter: RugFilterResponse | undefined,
  blindFilter: BlindFilterResponse | undefined,
  view: ProductView
): Promise<string | null> {
  if (visualisations.length !== 1) return null;
  const visualised = visualisations[0];

  if ('watermark_url' in visualised) return visualised.watermark_url;

  const imageResult = await saveWatermarkImage(
    visualised,
    { floorData: floorFilter, rugData: rugFilter, blindData: blindFilter },
    view
  );
  if (imageResult.image_url) return imageResult.image_url;

  return null;
}

const Share = ({ view = ProductView.flooring, visualisations }: Props) => {
  const [status, setStatus] = useState<Status>(Status.ready);
  const shareUrl = useRef('');
  const { floorData, rugData, blindData } = useSelector(productData);
  const isInspirationStation = useAppSelector(
    (state) => state.ui.isInspirationStation
  );
  const visData = useAppSelector((state) => state.productVisualisation);
  const [previewImage, setPreviewImage] = useState<string | null>(null);
  const { floor, rug, blind } = getIndData(visData.loadedIndex ?? 0, {
    visData: visData.data,
    floorData,
    rugData,
    blindData,
  });

  const {
    isOpen: isCopyOpen,
    onOpen: onCopyOpen,
    onClose: onCopyClose,
  } = useDisclosure();

  const isSingleVisualisation = visualisations.length === 1;

  useEffect(() => {
    getPreviewImage(visualisations, floor, rug, blind, view).then((result) => {
      setPreviewImage(result);
    });
  }, [visualisations]);

  async function generateShareUrl() {
    if (shareUrl.current) {
      // use same url if sharing multiple times.
      return shareUrl.current;
    }
    try {
      const shareId = await shareVisualisations(visualisations);
      shareUrl.current = createShareUrl(shareId);
      return shareUrl.current;
    } catch (e) {
      console.error(e);
      setStatus(Status.error);
    }
  }

  if (isInspirationStation) {
    return (
      <ShareInspirationStation view={view} visualisations={visualisations} />
    );
  }

  return (
    <>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {isSingleVisualisation && previewImage && (
            <Image width="100%" src={previewImage} />
          )}
          {isSingleVisualisation && !previewImage && (
            <Box padding="6" bg="white" width="100%">
              <SkeletonCircle size="10" />
              <SkeletonText mt="4" noOfLines={4} spacing="4" />
            </Box>
          )}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={0}>
          <SecondaryHeader
            color="#0069A8"
            pl="1rem"
            mb="1rem"
            textAlign="unset"
          >
            Select a sharing option
          </SecondaryHeader>
          {status === Status.error && (
            <Box mb="2rem">
              <ErrorMessage error="There was an error sharing your rooms" />
            </Box>
          )}
          {status === Status.ready && (
            <VStack mb="2rem">
              {isSingleVisualisation && (
                <Button
                  leftIcon={<IoShareOutline />}
                  bg="transparent"
                  isFullWidth
                  justifyContent="flex-start"
                  onClick={() =>
                    handleSaveImage(
                      visualisations[0],
                      { floorData: floor, rugData: rug, blindData: blind },
                      view
                    )
                  }
                >
                  <Text fontWeight={400}>Download Image</Text>
                </Button>
              )}
              <Button
                leftIcon={<MdMessage />}
                bg="transparent"
                isFullWidth
                justifyContent="flex-start"
                onClick={async () => {
                  trackEvent({ category: 'Share', action: 'Message' });
                  const shareUrl = await generateShareUrl();
                  if (shareUrl) {
                    handleMessage(shareUrl);
                  }
                }}
                display={{ base: 'inline-flex', xl: 'none' }}
              >
                <Text fontWeight={400}>Message</Text>
              </Button>

              <Button
                leftIcon={<HiOutlineMail />}
                bg="transparent"
                isFullWidth
                justifyContent="flex-start"
                onClick={async () => {
                  trackEvent({ category: 'Share', action: 'Email' });
                  const shareUrl = await generateShareUrl();
                  if (shareUrl) {
                    handleEmail(shareUrl);
                  }
                }}
              >
                <Text fontWeight={400}>Email</Text>
              </Button>
              <Button
                leftIcon={<FiLink />}
                bg="transparent"
                isFullWidth
                justifyContent="flex-start"
                onClick={async () => {
                  trackEvent({ category: 'Share', action: 'Copy Link' });
                  const shareUrl = await generateShareUrl();
                  if (shareUrl) {
                    onCopyOpen();
                  }
                }}
              >
                <Text fontWeight={400}>Copy Link</Text>
              </Button>
            </VStack>
          )}
        </ModalBody>
      </ModalContent>
      <Modal isOpen={isCopyOpen} onClose={onCopyClose}>
        {/** Kinda hacky, this depends on a state change (onCopyOpen) to update the render for the ref */}
        <CopyLink shareUrl={shareUrl.current} />
      </Modal>
    </>
  );
};

export default Share;
