import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RoomApi } from 'shared/build/models/room';
import { RootState } from '../../redux/store';
import { isSelected } from './helpers';

export interface SelectionState {
  selectedIds: string[];
  data: RoomApi[];
}

const initialState: SelectionState = {
  selectedIds: [],
  data: [],
};

export const photosSlice = createSlice({
  name: 'photos',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    toggleSelection: (state, action: PayloadAction<string>) => {
      const ids = state.selectedIds;
      state.selectedIds = isSelected(ids, action.payload)
        ? ids.filter((i) => i !== action.payload)
        : [...ids, action.payload];
    },
    resetSelection: (state) => {
      state.selectedIds = [];
    },
    selectAllUploaded: (state) => {
      const allUploadedIds = state.data
        .filter((item) => item.user)
        .map((item) => item.id);
      state.selectedIds = allUploadedIds;
    },
    setRooms: (state, action: PayloadAction<RoomApi[]>) => {
      state.data = action.payload;
    },
    removeRoom: (state, action: PayloadAction<string>) => {
      state.data = state.data.filter((room) => room.id !== action.payload);
    },
    clearRooms: (state) => {
      state.data = state.data.filter((photo) => !photo.user);
      state.selectedIds = [];
    },
    addNewRoom: (state, action: PayloadAction<RoomApi>) => {
      state.data = [action.payload, ...state.data];
      state.selectedIds = [action.payload.id, ...state.selectedIds];
    },
  },
});

export const {
  addNewRoom,
  toggleSelection,
  resetSelection,
  selectAllUploaded,
  setRooms,
  removeRoom,
  clearRooms,
} = photosSlice.actions;

export const photos = (state: RootState) => state.photos;

export const userPhotos = (state: RootState) =>
  state.photos.data.filter((photo) => photo.user);
export default photosSlice.reducer;
