import {
  Box,
  Image,
  AspectRatio,
  BoxProps,
  Icon,
  Center,
  AlertDialog,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogOverlay,
  AlertDialogContent,
  Text,
} from '@chakra-ui/react';
import { responsiveObj, SquareImageButton } from '../../UI/Buttons';
import { PhotoUiModes } from './PhotosNew';
import { BsTrash } from 'react-icons/bs';
import React, { useEffect, useRef, useState } from 'react';
import { RegButton } from '../../UI/Buttons';
import { ErrorMessage } from '../../UI/ErrorMessage';

export function Thumbnail(props: {
  imageUrl: string;
  mode: PhotoUiModes;
  selected: boolean;
  toggleSelect: () => void;
  deleteSelect: () => void;
  disableDelete?: string;
}) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isUnableToDeleteModalOpen, setIsUnableToDeleteModalOpen] =
    useState(false);
  useEffect(() => {
    setIsModalOpen(false);
  }, [props.mode]);
  return (
    <SquareImageButton
      position="relative"
      onClick={() => {
        if (props.mode === 'select') {
          props.toggleSelect();
        } else if (props.mode === 'edit' && !!props.disableDelete) {
          setIsUnableToDeleteModalOpen(true);
        } else if (props.mode === 'edit') {
          setIsModalOpen(true);
        }
      }}
    >
      <AspectRatio
        borderRadius="lg"
        height="100%"
        width="100%"
        ratio={1}
        _hover={{ opacity: props.selected ? 1 : 0.9 }}
        _active={{ opacity: 1 }}
      >
        <Image ignoreFallback src={props.imageUrl} alt={'Image'} />
      </AspectRatio>
      {props.mode === 'select' && (
        <SelectThumbnail
          position="absolute"
          top="2"
          left="2"
          selected={props.selected}
        ></SelectThumbnail>
      )}
      {props.mode === 'edit' && (
        <DeleteThumbnail
          position="absolute"
          top="2"
          left="2"
          selected={props.selected}
        ></DeleteThumbnail>
      )}
      {props.mode === 'edit' && (
        <UnableToDeleteModal
          isOpen={isUnableToDeleteModalOpen}
          message={props.disableDelete || ''}
          onClose={() => {
            setIsUnableToDeleteModalOpen(false);
          }}
        />
      )}
      {props.mode === 'edit' && (
        <DeleteModal
          isOpen={isModalOpen}
          onDelete={props.deleteSelect}
          onClose={() => {
            setIsModalOpen(false);
          }}
        />
      )}
    </SquareImageButton>
  );
}

export function UnableToDeleteModal(props: {
  isOpen: boolean;
  message: string;
  onClose: () => void;
}) {
  const cancelRef = useRef(null);
  return (
    <AlertDialog
      isOpen={props.isOpen}
      leastDestructiveRef={cancelRef}
      onClose={props.onClose}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            Unable to Delete Image
          </AlertDialogHeader>

          <AlertDialogBody>
            <Text>{props.message}</Text>
          </AlertDialogBody>

          <AlertDialogFooter>
            <RegButton ref={cancelRef} onClick={props.onClose}>
              Close
            </RegButton>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
}

export function DeleteModal(props: {
  isOpen: boolean;
  onClose: () => void;
  onDelete: () => void;
}) {
  const [error, setError] = useState('');
  async function handleDelete() {
    try {
      await props.onDelete();
    } catch (err) {
      setError(err.message || 'Error deleting');
    }
  }
  const cancelRef = useRef(null);
  return (
    <AlertDialog
      isOpen={props.isOpen}
      leastDestructiveRef={cancelRef}
      onClose={props.onClose}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            Delete Image
          </AlertDialogHeader>

          <AlertDialogBody>
            <Text>Are you sure? You can't undo this action afterwards.</Text>
            {error && <ErrorMessage error={error} />}
          </AlertDialogBody>

          <AlertDialogFooter>
            <RegButton ref={cancelRef} onClick={props.onClose}>
              Cancel
            </RegButton>
            <RegButton
              primary
              bg="red.600"
              _hover={{ bg: 'red.700' }}
              onClick={handleDelete}
              ml={3}
            >
              Delete
            </RegButton>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
}

export function DeleteThumbnail(props: BoxProps & { selected: boolean }) {
  return (
    <Box
      borderRadius="full"
      bg="white"
      padding="1"
      width="35px"
      height="35px"
      boxShadow="dark-lg"
      {...props}
    >
      <Center width="100%" height="100%">
        <Icon
          color="gray.600"
          _hover={{ color: 'brand.blue' }}
          as={BsTrash}
          w={5}
          h={5}
        />
      </Center>
    </Box>
  );
}

export function SelectThumbnail(props: BoxProps & { selected: boolean }) {
  return (
    <Box
      borderRadius="full"
      bg="white"
      padding="1"
      width="25px"
      height="25px"
      boxShadow="dark-lg"
      {...props}
    >
      <Box
        borderRadius="full"
        bg="brand.blue"
        width="100%"
        height="100%"
        transition="opacity 0.2s"
        opacity={props.selected ? 1 : 0}
      ></Box>
    </Box>
  );
}

export const ThumbnailContainer = ({
  children,
}: {
  children: React.ReactNode;
}) => (
  <Box
    position="relative"
    width={responsiveObj.photo2}
    height="100%"
    margin="6px"
  >
    {children}
  </Box>
);
