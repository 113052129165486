import {
  Popover,
  PopoverTrigger,
  Button,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverHeader,
  PopoverBody,
} from '@chakra-ui/react';
import { ProductView } from '../Products';

type Props = {
  view: ProductView;
};

const Disclaimer = ({ view }: Props) => {
  const bodyText = getText(view);

  return (
    <Popover>
      <PopoverTrigger>
        <Button
          justifySelf="start"
          size="sm"
          p="0"
          color="brand.blue"
          textDecoration="underline"
          variant="ghost"
        >
          Disclaimer
        </Button>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverHeader>Disclaimer</PopoverHeader>
        <PopoverBody fontWeight="300" fontSize="sm">
          {bodyText}
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

export default Disclaimer;

export function getText(view: ProductView) {
  if (view === ProductView.rugs) {
    return `**Note: Due to variances among monitors, the rug colours and ratio on your device may appear slightly different than the actual product. Important tip. We recommend that an anti-slip pad such as Total Grip is used underneath rugs to prevent slippage between rug and surface placed on.`;
  }
  if (view === ProductView.blinds) {
    return `**Note: Due to variances among monitors, the window furnishing colours and ratio on your device may appear slightly different than the actual product.`;
  }
  return `**Note: Due to variances among monitors, the flooring colours and ratio on your device may appear slightly different than the actual product.`;
}
