import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SavedApi } from 'shared/build/models/saved';
import { RootState } from '../../redux/store';
import { isSelected } from '../Photos/helpers';

export interface SelectionState {
  selectedIds: string[];
  data: SavedApi[];
}

const initialState: SelectionState = {
  selectedIds: [],
  data: [],
};

export const savedSlice = createSlice({
  name: 'saved',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    toggleSelection: (state, action: PayloadAction<string>) => {
      const ids = state.selectedIds;
      state.selectedIds = isSelected(ids, action.payload)
        ? ids.filter((i) => i !== action.payload)
        : [...ids, action.payload];
    },
    resetSelection: (state) => {
      state.selectedIds = [];
    },
    setSaved: (state, action: PayloadAction<SavedApi[]>) => {
      state.data = action.payload;
    },
    removeSaved: (state, action: PayloadAction<string>) => {
      state.data = state.data.filter((saved) => saved.id !== action.payload);
    },
    clearSaved: (state) => {
      state.data = [];
      state.selectedIds = [];
    },
    addNewSaved: (state, action: PayloadAction<SavedApi>) => {
      state.data = [action.payload, ...state.data];
    },
  },
});

export const {
  addNewSaved,
  toggleSelection,
  resetSelection,
  setSaved,
  removeSaved,
  clearSaved,
} = savedSlice.actions;

export const saved = (state: RootState) => state.saved;

export default savedSlice.reducer;
