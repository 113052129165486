import {
  Flex,
  Box,
  Heading,
  Text,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  Center,
  Divider,
  ModalBody,
  ModalCloseButton,
  ModalHeader,
} from '@chakra-ui/react';
import { useEffect } from 'react';
import { MdNavigateNext } from 'react-icons/md';
import QRCode from 'react-qr-code';
import { RoutePaths } from '../../App';
import { useAppDispatch } from '../../redux/hooks';
import { setIsInspirationStation } from '../../redux/uiSlice';
import { LinkButton, RegButton } from '../../UI/Buttons';
import BackgroundImage from './assets/is_qr-bg.jpg';
import { createUploadLink } from './helpers';

const QRLandingPage = () => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const appDispatch = useAppDispatch();
  useEffect(() => {
    appDispatch(setIsInspirationStation());
  }, []);
  const link = createUploadLink();

  return (
    <>
      <Flex
        height="100%"
        pos="relative"
        width="100%"
        backgroundImage={`url("${BackgroundImage}")`}
        backgroundSize="cover"
        backgroundPosition="bottom"
      >
        <Flex w="50%" alignItems={'center'} justifyContent="center">
          <Flex
            maxW="475px"
            spacing="2"
            justifyContent={'center'}
            alignItems="center"
            flexDirection="column"
          >
            <Box mb="12">
              {/* @ts-ignore */}
              <QRCode value={link} />
            </Box>
            <Heading as="h2" size="lg" color="brand.blue" mb="2">
              Would you like to use a photo of your room from your phone?
            </Heading>
            <Text color="brand.blue">
              Simply scan the QR code above using the camera on your phone and
              follow the simple instructions to get started.
            </Text>
            <Flex flexDir={'column'}>
              <LinkButton
                to={RoutePaths.onboarding}
                rightIcon={<MdNavigateNext size="1.5em" />}
                primary
                mt="16"
              >
                Continue to RoomView
              </LinkButton>
              <RegButton
                secondary
                mt="5"
                rightIcon={<MdNavigateNext size="1.5em" />}
                onClick={onOpen}
              >
                How to upload your photo
              </RegButton>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
      <Modal isOpen={isOpen} onClose={onClose} size="2xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>How to upload your photo</ModalHeader>
          <ModalCloseButton />
          <Divider />
          <ModalBody>
            <video
              src="https://choices-roomview.s3.ap-southeast-2.amazonaws.com/assets/roomview-instructional.mp4"
              controls
              autoPlay
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default QRLandingPage;
