import * as PIXI from 'pixi.js';
import {
  BlindFilterResponse,
  FloorFilterResponse,
  RugFilterResponse,
} from 'shared/build/models/product';
import { createBrandDetails, setDimensions } from '../helpers';
import { FilterableBlindTypes } from '../ProductData/productDataSlice';
import { ProductView } from '../Products';
import { VisualisationData } from '../productVisualisationSlice';
import {
  getCanvUrl,
  getSpecificBlindProduct,
  getSpecificFloorProduct,
  getSpecificRugProduct,
} from './helper';

const textStyle = new PIXI.TextStyle({
  fontFamily: 'Sofia,sans-serif',
  fontSize: 26,
  fontWeight: 'bold',
  fill: '#4a5568',
});

const text2Style = new PIXI.TextStyle({
  fontFamily: 'Sofia,sans-serif',
  fontSize: 20,
  fill: '#6a7588',
});

const logoUrl = require('./textures/logo.png').default;
export function generateWatermarkImage(
  data: { swatch: string; line1: string; line2: string } | null
): Promise<Blob> {
  const loader = PIXI.Loader.shared;
  loader.reset();

  const canvUrl = getCanvUrl();
  loader.add('visualiser', canvUrl);
  loader.add('logo', logoUrl);
  if (data?.swatch) {
    loader.add('swatch', setDimensions(data.swatch, { w: 100, h: 100 }));
  }
  return new Promise((res, rej) => {
    loader.load((loader, resources) => {
      const visTexture = resources.visualiser.texture;
      const logoTexture = resources.logo.texture;
      const swatchTexture = resources.swatch?.texture;

      const header = 120;
      let { width, height } = resources.visualiser.data;
      width = width * 0.8;
      height = height * 0.8;
      const app = new PIXI.Application({
        width,
        height: height + header,
        backgroundColor: 0xffffff,
        resolution: 1,
        preserveDrawingBuffer: true, // allows us to take screen shots
      });

      // visualiser
      const visualiserSprite = new PIXI.Sprite(visTexture);
      visualiserSprite.x = 0;
      visualiserSprite.y = header;
      visualiserSprite.width = width;
      visualiserSprite.height = height;

      const logoSprite = new PIXI.Sprite(logoTexture);
      logoSprite.x = 45;
      logoSprite.y = 18;
      logoSprite.width = 85;
      logoSprite.height = 85;

      const productSprite = new PIXI.Sprite(swatchTexture);
      productSprite.x = 180;
      productSprite.y = 15;
      productSprite.width = 90;
      productSprite.height = 90;

      const productText = new PIXI.Text(data?.line1 || '', textStyle);
      productText.x = 300;
      productText.y = 30;

      const product2Text = new PIXI.Text(data?.line2 || '', text2Style);
      product2Text.x = 300;
      product2Text.y = 65;

      app.stage.addChild(visualiserSprite);
      app.stage.addChild(logoSprite);
      app.stage.addChild(productSprite);
      app.stage.addChild(productText);
      app.stage.addChild(product2Text);

      app.render();
      app.view.toBlob(async (b) => {
        app.destroy(true, { children: true, texture: true, baseTexture: true });
        if (!b) {
          rej('Failed to save watermark image');
          return;
        }
        res(b);
      }, 'image/jpeg');
    });
  });
}

export type ProductData = {
  floorData?: FloorFilterResponse;
  rugData?: RugFilterResponse;
  blindData?: BlindFilterResponse;
};

export function getWatermarkData(
  currentView: ProductView,
  loadedVisualisation: VisualisationData,
  productData: ProductData
): { swatch: string; line1: string; line2: string } | null {
  if (currentView === ProductView.flooring) {
    const product = getSpecificFloorProduct(
      loadedVisualisation.floor_id,
      productData.floorData
    );
    if (!product) return null;

    const brandDetails = productData.floorData
      ? createBrandDetails(productData.floorData)
      : '';
    return {
      swatch: product.swatch_file_path!,
      line1: product.name,
      line2: brandDetails || '',
    };
  } else if (currentView === ProductView.rugs) {
    const product = getSpecificRugProduct(
      loadedVisualisation.rug_id,
      productData.rugData
    );
    if (!product) return null;
    return {
      swatch: product.swatch_file_path,
      line1: product.name,
      line2: `${product.width}cm x ${product.length}cm`,
    };
  } else if (currentView === ProductView.blinds) {
    const product = getSpecificBlindProduct(
      loadedVisualisation.blind_id,
      productData.blindData
    );
    if (!product) return null;
    return {
      swatch: product.swatch_file_path,
      line1: product.name,
      line2: productData.blindData?.brand_category.brand || '',
    };
  }
  return null;
}
