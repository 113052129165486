import {
  Box,
  Center,
  VStack,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  Image,
  Progress,
  Heading,
  Alert,
  AlertIcon,
  AlertDescription,
  Button,
} from '@chakra-ui/react';
import { noop } from 'lodash';
import { useEffect, useState } from 'react';
import { useQuery } from '../../utils/hooks';
import RoomviewLogo from '../Onboarding/roomview-logo.svg';
import InspirationStationScreenshot from './assets/is_screenshot.png';
import InspirationStationScreenshot2 from './assets/is_screenshot-2.jpg';
import ImageBackground from './assets/is_upload.jpg';
import { RegButton } from '../../UI/Buttons';
import { UploadFile, useUpload } from '../Photos/AddPhotos';
import { IoAdd } from 'react-icons/io5';
import { MdNavigateNext } from 'react-icons/md';

enum States {
  pending = 'pending',
  uploading = 'uploading',
  uploaded = 'uploaded',
}

const UploadLandingPage = () => {
  const source = useQuery('source');
  const isPhotosPage = useQuery('isPhotosPage');
  const { files, setFiles, getRootProps, getInputProps, uploadImage } =
    useUpload(source || '');

  const [state, setState] = useState<States>(States.pending);
  const [failedUploads, setFailedUploads] = useState<UploadFile[]>([]);

  useEffect(() => {
    if (files.length) {
      (async function () {
        setState(States.uploading);
        for (let file of files) {
          try {
            await uploadImage(file.preview);
          } catch (err) {
            console.error(err);
            setFailedUploads((failedUploads) => [...failedUploads, file]);
          }
        }
        setFiles([]);
        setState(States.uploaded);
      })();
    }
  }, [JSON.stringify(files)]);

  return (
    <Box
      height="100vh"
      pos="relative"
      background={`url('${ImageBackground}')`}
      backgroundSize="cover"
      backgroundPosition={'center'}
    >
      <Modal isOpen onClose={noop}>
        <ModalOverlay />
        <ModalContent maxW="335px">
          <ModalHeader>
            <Center>
              <Image src={RoomviewLogo} alt="roomview-logo" width="100px" />
            </Center>
          </ModalHeader>
          <ModalBody p="25px">
            <VStack alignItems="center" spacing="4">
              {(state === States.pending || state === States.uploading) && (
                <>
                  <Text color="brand.blue">
                    Select which photos you would like to view on the
                    Inspiration Station.
                  </Text>
                  <RegButton
                    primary
                    disabled={!!files.length}
                    {...getRootProps({ className: 'dropzone' })}
                    rightIcon={<MdNavigateNext size="1.5em" />}
                  >
                    <input {...getInputProps()} />
                    Go to photo library
                  </RegButton>
                  {state === States.uploading && (
                    <Progress w="100%" isIndeterminate />
                  )}
                </>
              )}
              {state === States.uploaded && (
                <>
                  <Heading as="h2" size="sm" color="brand.blue">
                    Photos uploaded!
                  </Heading>
                  {!!failedUploads.length && (
                    <Alert status="error" borderRadius="8">
                      <AlertIcon />
                      <AlertDescription
                        fontSize="sm"
                        display="flex"
                        alignItems="center"
                      >
                        <Box>Some images failed to upload</Box>
                        <Button
                          size="xs"
                          colorScheme="red"
                          onClick={() => {
                            setFiles(failedUploads);
                            setFailedUploads([]);
                          }}
                        >
                          Retry
                        </Button>
                      </AlertDescription>
                    </Alert>
                  )}
                  <Text color="brand.blue">
                    On the Inspiration Station, tap the 'Continue to RoomView'
                    button.
                  </Text>
                  <Image
                    src={
                      isPhotosPage
                        ? InspirationStationScreenshot2
                        : InspirationStationScreenshot
                    }
                    alt="Inspiration Station Continue"
                    width="100%"
                  />
                  <RegButton
                    primary
                    leftIcon={<IoAdd size="1.5em" />}
                    onClick={() => setState(States.pending)}
                  >
                    Add more photos
                  </RegButton>
                </>
              )}
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default UploadLandingPage;
