import {
  Box,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { RegButton } from '../../../UI/Buttons';
import { SecondaryHeader } from '../../../UI/Text';
import {
  productData,
  setShowFloorFilters,
} from '../ProductData/productDataSlice';
import {
  productVisualisation,
  updateIndVisualisation,
} from '../productVisualisationSlice';
import { getIndData } from '../Visualiser/helper';
import ProductScroll from '../ProductList/ProductScroll';
import { ProductFilters } from '../ProductData/ProductFilters';
import { ProductList } from '../ProductData/FloorProductList';
import { FloorFilterResponse } from 'shared/build/models/product';
import { ProductView } from '../Products';
import { flatMap } from 'lodash';
import { sendFreeMeasureAndQuote } from './helpers';
import FloorSelectedProductHeading from './FloorSelectedProductHeading';
import { useAppSelector } from '../../../redux/hooks';
import { useEffect } from 'react';
import { FloorProductListMobile } from '../ProductData/FloorProductListMobile';
import {
  FloorSearch,
  ProductFiltersMobile,
} from '../ProductData/ProductFiltersMobile';
import { ArrowBackIcon } from '@chakra-ui/icons';

type Props = {
  hasNextPage: boolean;
  loadNextPage: () => void;
  filteredData: FloorFilterResponse[];
  allData: FloorFilterResponse[];
};
export function FloorSelectedProduct({
  allData,
  filteredData,
  ...rest
}: Props) {
  const dispatch = useDispatch();
  const showFilters = useSelector(productData).showFloorFilters;
  const visData = useSelector(productVisualisation);
  const rooms = useAppSelector((state) => state.photos.data);
  const zoomLevel = useAppSelector((state) => state.ui.zoomLevel);

  if (visData.loadedIndex === null) return null;
  const { vis, floor, room } = getIndData(visData.loadedIndex, {
    visData: visData.data,
    roomData: rooms,
    floorData: allData,
  });

  if (!floor) return null;
  const product = floor.products.find((fp) => fp.id === vis.floor_id);
  if (!product) return null;

  const allProducts = flatMap(filteredData, (fd) => fd.products);

  function updateSelectedProduct(productId: number) {
    if (visData.loadedIndex === null) {
      return; // check to keep typescript happy - we already check above and prevent render if null
    }
    dispatch(
      updateIndVisualisation({
        ind: visData.loadedIndex,
        data: { ...vis, floor_id: productId },
      })
    );
  }

  return (
    <>
      {zoomLevel <= 1 && (
        <>
          <FloorSelectedProductHeading
            floor={floor}
            product={product}
            room={room}
            allProducts={allProducts}
            updateSelectedProduct={updateSelectedProduct}
          />

          <ProductScroll
            selectedProduct={product}
            products={allProducts}
            handleClick={updateSelectedProduct}
            {...rest}
          />
        </>
      )}

      <Box bg="white" width="100%" display="flex" justifyContent="space-around">
        <Button
          rounded="full"
          fontWeight="normal"
          height={8}
          color="white"
          mt="2"
          mb="2"
          width="100%"
          backgroundColor="#6CB33E"
          onClick={() => {
            sendFreeMeasureAndQuote(
              product,
              visData.data,
              visData.data[visData.loadedIndex!],
              { floorData: floor },
              ProductView.flooring
            );
          }}
        >
          Request a Free Measure and Quote
        </Button>
      </Box>
      <Flex flexDir="column" flex={1} display={{ base: 'none', lg: 'flex' }}>
        <SecondaryHeader align="start" mt="2" mb="2">
          Product Filters
        </SecondaryHeader>
        <ProductFilters />
        <FloorProductListMobile
          filteredData={filteredData}
          updateSelectedProduct={updateSelectedProduct}
          {...rest}
        />
      </Flex>
      <Modal
        returnFocusOnClose={false}
        isOpen={showFilters}
        onClose={() => {
          dispatch(setShowFloorFilters(false));
          if (document.activeElement instanceof HTMLElement) {
            document.activeElement.blur();
          }
        }}
      >
        <ModalOverlay />
        <ModalContent height={'95vh'} overflowY="scroll" marginY="1rem">
          <ModalHeader
            display="flex"
            justifyContent="space-between"
            paddingBottom={0}
          >
            <Box display="flex">
              <Button
                fontWeight="normal"
                leftIcon={<ArrowBackIcon />}
                backgroundColor="blue.500"
                color="white"
                rounded="full"
                height={8}
                onClick={() => dispatch(setShowFloorFilters(false))}
              >
                Back
              </Button>
            </Box>
            <Box width="70%">
              <FloorSearch />
            </Box>
          </ModalHeader>
          <ModalBody display="flex" flexDirection="column">
            <ProductFiltersMobile />
            <ProductList
              filteredData={filteredData}
              updateSelectedProduct={updateSelectedProduct}
              {...rest}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
