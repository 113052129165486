import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { config } from '../config';
import ReactGA from 'react-ga';
import TagManager from 'react-gtm-module';

const tagManagerArgs = {
  gtmId: config.googleTagManager,
};

export const useTracking = () => {
  const location = useLocation();
  const [isInitialised, setIsInitialised] = useState(false);
  useEffect(() => {
    TagManager.initialize(tagManagerArgs);
    ReactGA.initialize(config.googleAnalytics);
    setIsInitialised(true);
  }, []);
  useEffect(() => {
    if (isInitialised) {
      const page = location.pathname + location.search;
      ReactGA.pageview(page);
    }
  }, [isInitialised, location]);
};

export const trackEvent = (event: {
  category: string;
  action: string;
  label?: string;
}) => {
  ReactGA.event(event);
};
