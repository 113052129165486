import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  BlindFilterResponse,
  FloorFilterResponse,
  RugFilterResponse,
  RugFilterResponseApi,
} from 'shared/build/models/product';
import { RoomApi } from 'shared/ts/models/room';
import { config } from '../../../config';
import {
  useGetBlindProductsQuery,
  useGetFloorProductsQuery,
  useGetRugProductsQuery,
} from '../../../redux/api';
import { sortRugsBySize } from '../ProductList/ChangeSize';
import { photos } from '../../Photos/photosSlice';
import {
  FilterableBlindTypes,
  productData,
} from '../ProductData/productDataSlice';
import { ProductView } from '../Products';
import {
  updateIndVisualisation,
  VisualisationData,
} from '../productVisualisationSlice';
import { useAppSelector } from '../../../redux/hooks';
import { BlindTypes } from 'shared/build/models/product';

// Roller
const rollerBlindMask = require('./textures/roller_blind/mask.png').default;
const rollerBlindOverlay =
  require('./textures/roller_blind/overlay.png').default;
const rollerBlindTrim = require('./textures/roller_blind/trim.png').default;

// Shutters
const shuttersMask = require('./textures/shutters/mask.png').default;
const shuttersOverlay = require('./textures/shutters/overlay.png').default;
const shuttersTrim = require('./textures/shutters/trim.png').default;

// Roman
const romanBlindsMask = require('./textures/roman_blind/mask.png').default;
const romanBlindsOverlay =
  require('./textures/roman_blind/overlay.png').default;
const romanBlindsTrim = require('./textures/roman_blind/trim.png').default;

// Curtains and Sheers
const curtainsAndSheersMask =
  require('./textures/curtains_sheers/mask.png').default;
const curtainsAndSheersOverlay =
  require('./textures/curtains_sheers/overlay.png').default;
const curtainsAndSheersTrim =
  require('./textures/curtains_sheers/trim.png').default;

// Panel Glides
const panelGlidesMask = require('./textures/panel_glides/mask.png').default;
const panelGlidesOverlay =
  require('./textures/panel_glides/overlay.png').default;
const panelGlidesTrim = require('./textures/panel_glides/trim.png').default;

// Vertical
const venetianMask = require('./textures/venetians/mask.png').default;
const venetianlOverlay = require('./textures/venetians/overlay.png').default;
const venetianTrim = require('./textures/venetians/trim.png').default;

function getBlindTypeAssets(blindType: FilterableBlindTypes) {
  switch (blindType) {
    case BlindTypes.Roller:
      return {
        mask: rollerBlindMask,
        overlay: rollerBlindOverlay,
        trim: rollerBlindTrim,
      };

    case BlindTypes.Shutter:
      return {
        mask: shuttersMask,
        overlay: shuttersOverlay,
        trim: shuttersTrim,
      };

    case BlindTypes.CurtainSheers:
      return {
        mask: curtainsAndSheersMask,
        overlay: curtainsAndSheersOverlay,
        trim: curtainsAndSheersTrim,
      };

    case BlindTypes.Panel:
      return {
        mask: panelGlidesMask,
        overlay: panelGlidesOverlay,
        trim: panelGlidesTrim,
      };

    case BlindTypes.Venetian:
      return {
        mask: venetianMask,
        overlay: venetianlOverlay,
        trim: venetianTrim,
      };

    case BlindTypes.Roman:
      return {
        mask: romanBlindsMask,
        overlay: romanBlindsOverlay,
        trim: romanBlindsTrim,
      };

    default:
      return {
        mask: rollerBlindMask,
        overlay: rollerBlindOverlay,
        trim: rollerBlindTrim,
      };
  }

  //   case 'Roman Blinds':
  //     return {
  //       mask: romanBlindsMask,
  //       overlay: romanBlindsOverlay,
  //       trim: romanBlindsTrim,
  //     };
  //
  //   case 'Venetian':
  //     return {
  //       mask: venetianMask,
  //       overlay: venetianlOverlay,
  //       trim: venetianTrim,
  //     };
  //
  //   case 'Panel Glide':
  //     return {
  //       mask: panelGlidesMask,
  //       overlay: panelGlidesOverlay,
  //       trim: panelGlidesTrim,
  //     };
  // }
}

export type SelectedData = {
  visData: VisualisationData;
  roomData?: RoomApi;
  floorData?: FloorFilterResponse;
  rugData?: RugFilterResponse;
  blindData?: BlindFilterResponse;
};

export function generateLoadRequestData(
  index: number,
  data: SelectedData,
  blindType: FilterableBlindTypes
) {
  return {
    visualiserIndex: index,
    room: data.roomData
      ? {
          id: data.roomData.id,
          image: data.roomData.file_url,
          mask: data.roomData.mask_url, // if user image
          reflection: data.roomData.reflection_url, // if choices image
          shadow_mask:
            data.roomData.configuration.floor_inference.shadow_mask_url, // if choices image
        }
      : null,
    blind:
      data.blindData && data.visData.blind_id
        ? {
            id: data.visData.blind_id!,
            image: data.blindData.products.find(
              (p) => p.id === data.visData.blind_id
            )!.texture_file_path,
            ...getBlindTypeAssets(blindType),
          }
        : null,
    rug:
      data.rugData && data.visData.rug_id
        ? {
            id: data.visData.rug_id!,
            image: data.rugData.products.find(
              (p) => p.id === data.visData.rug_id
            )!.texture_file_path,
          }
        : null,
    floor:
      data.floorData && data.visData.floor_id
        ? {
            id: data.visData.floor_id!,
            image: data.floorData.products.find(
              (p) => p.id === data.visData.floor_id
            )!.texture_file_path,
          }
        : null,
  };
}

export type AllData = {
  visData: VisualisationData[];
  roomData?: RoomApi[];
  floorData?: FloorFilterResponse[];
  rugData?: RugFilterResponse[];
  blindData?: BlindFilterResponse[];
};

export function getIndData(ind: number, data: AllData) {
  const vis = data.visData[ind];
  const room = (data.roomData || []).find((d) => d.id === vis.room_public_id);
  const floor = getFloorProducts(vis.floor_id, data.floorData);
  const rug = getRugProducts(vis.rug_id, data.rugData);
  const blind = getBlindProducts(vis.blind_id, data.blindData);
  return {
    vis,
    room: room!,
    floor,
    rug,
    blind,
  };
}

export function getBlindProducts(
  blindId: number | null,
  blindData?: BlindFilterResponse[]
) {
  return (blindData || []).find((d) =>
    d.products.some((p) => p.id === blindId)
  );
}

export function getSpecificBlindProduct(
  blindId: number | null,
  blindData?: BlindFilterResponse
) {
  return (blindData?.products || []).find((r) => r.id === blindId);
}

export function getFloorProducts(
  floorId: number | null,
  floorData?: FloorFilterResponse[]
) {
  return (floorData || []).find((d) =>
    d.products.some((p) => p.id === floorId)
  );
}

export function getSpecificFloorProduct(
  floorId: number | null,
  floorData?: FloorFilterResponse
) {
  return (floorData?.products || []).find((f) => f.id === floorId);
}

export function getRugProducts(
  rugId: number | null,
  rugData?: RugFilterResponse[]
) {
  return (rugData || []).find((d) => d.products.some((p) => p.id === rugId));
}

export function getSpecificRugProduct(
  rugId: number | null,
  rugData?: RugFilterResponse
) {
  return (rugData?.products || []).find((r) => r.id === rugId);
}

type InitialisationDict = {
  rugs: {
    [ind: number]: true;
  };
  blinds: {
    [ind: number]: true;
  };
  flooring: {
    [ind: number]: true;
  };
};
function useDefaultProductId(view: ProductView) {
  const floorFilters = useSelector(productData).floorFilters;
  const { data: floorProductsRes } = useGetFloorProductsQuery({
    region: config.region,
    type: floorFilters.type,
    active: true,
  });
  const { data: rugProductsRes } = useGetRugProductsQuery(
    {
      region: config.region,
      active: true,
    },
    { skip: view !== ProductView.rugs }
  );
  const { data: blindProductsRes } = useGetBlindProductsQuery(
    {
      region: config.region,
      active: true,
    },
    { skip: view !== ProductView.blinds }
  );
  const defaultRugId = getDefaultRug(rugProductsRes);
  const defaultFloorId = floorProductsRes?.data[0]?.products[0]?.id;
  const defaultBlindId = blindProductsRes?.data[0]?.products[0]?.id;

  return { defaultRugId, defaultFloorId, defaultBlindId };
}

export function useGenerateDefaultVisData(
  ind: number,
  visData: VisualisationData[],
  view: ProductView
) {
  const rooms = useSelector(photos);
  const dispatch = useDispatch();
  const vis = visData[ind];
  const previousVis = visData[ind - 1];
  const isIninitialisedRef = useRef<InitialisationDict>({
    rugs: {},
    blinds: {},
    flooring: {},
  });
  const { defaultBlindId, defaultFloorId, defaultRugId } =
    useDefaultProductId(view);
  function getFloorIdToUse() {
    if (vis.floor_id) {
      return vis.floor_id;
    }
    if (isIninitialisedRef.current.flooring[ind]) {
      return vis.floor_id;
    }
    if (view === ProductView.blinds || view === ProductView.rugs) {
      const room = rooms.data.find((r) => r.id === vis.room_public_id);
      if (!room || room.user) {
        // If this is on the rugs/blind page and it is a user uploaded room,
        // don't replace any selection. On Choices images we want this replaced
        // so that there isn't a white background.
        return vis.floor_id;
      }
    }
    isIninitialisedRef.current.flooring[ind] = true;
    return previousVis && previousVis.floor_id
      ? previousVis.floor_id
      : defaultFloorId!;
  }
  useEffect(() => {
    if (view === ProductView.flooring && !vis.floor_id && defaultFloorId) {
      if (isIninitialisedRef.current.flooring[ind]) {
        return;
      }
      dispatch(
        updateIndVisualisation({
          ind,
          data: {
            ...vis,
            floor_id: getFloorIdToUse(),
          },
        })
      );
    } else if (
      view === ProductView.blinds &&
      defaultFloorId &&
      defaultBlindId
    ) {
      if (isIninitialisedRef.current.blinds[ind]) {
        return;
      }
      dispatch(
        updateIndVisualisation({
          ind,
          data: {
            ...vis,
            floor_id: getFloorIdToUse(),
            blind_id: vis.blind_id
              ? vis.blind_id
              : previousVis && previousVis.blind_id
              ? previousVis.blind_id
              : defaultBlindId,
          },
        })
      );
      isIninitialisedRef.current.blinds[ind] = true;
    } else if (view === ProductView.rugs && defaultFloorId && defaultRugId) {
      if (isIninitialisedRef.current.rugs[ind]) {
        return;
      }
      dispatch(
        updateIndVisualisation({
          ind,
          data: {
            ...vis,
            floor_id: getFloorIdToUse(),
            rug_id: vis.rug_id
              ? vis.rug_id
              : previousVis && previousVis.rug_id
              ? previousVis.rug_id
              : defaultRugId,
          },
        })
      );
      isIninitialisedRef.current.rugs[ind] = true;
    }
  }, [
    view,
    ind,
    defaultBlindId,
    defaultRugId,
    defaultFloorId,
    JSON.stringify(vis),
  ]);
}

export function getCanvUrl(): string {
  const canv = window.roomviewPixiApp!.renderer.view;
  return canv.toDataURL();
}

export function getCanvImage(): Promise<Blob> {
  const canv = window.roomviewPixiApp!.renderer.view;
  return new Promise((res, rej) => {
    canv.toBlob(async (b) => {
      if (!b) {
        rej('Failed to save image');
        return;
      }
      res(b);
    }, 'image/jpeg');
  });
}

export function checkVisualiserBtn(e: PointerEvent) {
  const visualiserBtns = document.querySelectorAll('.visualiser-btn');
  if (
    Array.from(visualiserBtns).some((btn) => btn.contains(e.target as Node))
  ) {
    return true;
  }
  return false;
}

function getDefaultRug(rugsRes: RugFilterResponseApi | undefined) {
  if (!rugsRes) return undefined;
  const rugs = rugsRes?.data[0].products;
  if (!rugs) return undefined;
  const sortedRugs = sortRugsBySize(rugs);
  return sortedRugs[0].id;
}

export const useDefaultRugPosition = () => {
  const selectedVisIndex = useAppSelector(
    (state) => state.productVisualisation.selectedIndex
  );
  const selectedVis = useAppSelector(
    (state) => state.productVisualisation.data
  )[selectedVisIndex ?? 0];
  const rooms = useAppSelector((state) => state.photos.data);
  const selectedRoom = rooms.find(
    (room) => room.id === selectedVis.room_public_id
  );
  const defaultPosition = selectedRoom?.configuration.default_rug?.position;
  return defaultPosition;
};

export const useDefaultBlindPoints = () => {
  const selectedVisIndex = useAppSelector(
    (state) => state.productVisualisation.selectedIndex
  );
  const selectedVis = useAppSelector(
    (state) => state.productVisualisation.data
  )[selectedVisIndex ?? 0];
  const rooms = useAppSelector((state) => state.photos.data);
  const selectedRoom = rooms.find(
    (room) => room.id === selectedVis.room_public_id
  );

  const defaultBlindPoints = selectedRoom?.configuration.default_blind?.map(
    (blind) => blind.points
  );
  return defaultBlindPoints;
};
