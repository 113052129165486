import axios from '../../../axios';
import { useEffect } from 'react';
import { RoomApi } from 'shared/build/models/room';
import { shareVisualisations } from '../../../utils/api';
import {
  sendIframeMessage,
  IframeSendTypes,
} from '../../../utils/iframe-communication';
import { trackEvent } from '../../../utils/tracking';
import { createShareUrl } from '../../Share/helpers';
import { ProductView } from '../Products';
import { VisualisationData } from '../productVisualisationSlice';
import {
  getWatermarkData,
  generateWatermarkImage,
  ProductData,
} from '../Visualiser/generateWatermark';
import { Product } from 'shared/build/models/product';

export async function sendFreeMeasureAndQuote(
  product: Product,
  visualisations: VisualisationData[],
  loadedVisualisation: VisualisationData,
  products: ProductData,
  view: ProductView.flooring | ProductView.blinds
) {
  trackEvent({
    category: 'Free Measure & Quote',
    action: 'Clicked',
    label: `${view} - ${product.sku}`,
  });
  return freeMeasureAndQuote(
    visualisations,
    loadedVisualisation,
    products,
    view
  ).then((res: { image_url: string; share_url: string }) => {
    sendIframeMessage(IframeSendTypes.freeMeasureAndQuote, {
      variantId: product.shopify_id,
      productId: product.shopify_product_id,
      visible_sku: product.sku,
      all_skus: [product.sku],
      user_product_urls: [res.share_url],
      preview_urls: [res.image_url],
    });
  });
}

async function freeMeasureAndQuote(
  visualisations: VisualisationData[],
  loadedVisualisation: VisualisationData,
  products: ProductData,
  view: ProductView.flooring | ProductView.blinds
) {
  try {
    const data = await saveWatermarkImage(loadedVisualisation, products, view);
    const shareId = await shareVisualisations(visualisations);
    const createdShareUrl = createShareUrl(shareId);
    return { image_url: data.image_url, share_url: createdShareUrl };
  } catch (e) {
    console.error(e);
    throw e;
  }
}

export async function saveWatermarkImage(
  loadedVisualisation: VisualisationData,
  productData: ProductData,
  view: ProductView
) {
  const wD = getWatermarkData(view, loadedVisualisation, productData);
  const blob = await generateWatermarkImage(wD);
  const data = await savePreview(blob);
  return data;
}

export async function savePreview(file: Blob) {
  const data = new FormData();
  data.append('image', file!);
  const uploadedData = (
    await axios.post('/api/v1/shared/preview_image', data, {})
  ).data.data;
  return uploadedData;
}

export const useTrackProductView = (room: RoomApi, sku: string) => {
  useEffect(() => {
    const isOwnRoom = room.user;
    trackEvent({
      category: 'Product',
      action: `View Product - ${isOwnRoom ? 'Own Room' : 'Provided Room'}`,
      label: `Rug - ${sku}`,
    });
  }, [sku, room.id]);
};
