import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Product } from 'shared/build/models/product';
import { productData, setShowRugFilters } from './productDataSlice';
import { FixedSizeList as List } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';
import AutoSizer from 'react-virtualized-auto-sizer';
import { Box, Center, Text, Flex, Tooltip } from '@chakra-ui/react';
import { BlindFilterResponse } from 'shared/ts/models/product';
import { HorizontalScroll } from '../../../UI/HorizontalScroll';
import { Loading } from '../../../UI/Loading';
import Description from '../ProductList/ProductDescriptions';
import ProductThumbnail from '../ProductList/ProductThumbnail';
import { AdditionalCount } from '../ProductList/Row';

type RowProps = {
  data: {
    elems: BlindFilterResponse[],
    dispatchSelectProduct: (product: Product) => void;
    isItemLoaded?: (index: number) => boolean;
  };
  index: number;
  style: {};
};
type Props = {
  hasNextPage: boolean;
  loadNextPage: () => void;
  handleProductClick: (id: number) => void;
  filteredData: BlindFilterResponse[];
};


const productThumbnailSize = 35;

const Row = ({ data, index, style }: RowProps) => {
  const [visibleCount, setVisibleCount] = useState(0);
  const [offset, setOffset] = useState(0);
  const productListRef = useRef(null as null | HTMLDivElement);
  const { elems, dispatchSelectProduct, isItemLoaded } = data;
  const itemLoaded = isItemLoaded ? isItemLoaded(index) : false;
  useEffect(() => {
    if (itemLoaded && productListRef.current) {
      const visCount = Math.floor(
        productListRef.current.clientWidth / (productThumbnailSize + 5)
      );
      setVisibleCount(visCount);
    }
  }, [itemLoaded]);
  if (!itemLoaded) {
    return <Loading />;
  }
  const elem = elems[index];
  const [firstProduct, ...remainProducts] = elem.products;

  const showNextPage = offset + visibleCount < remainProducts.length;
  const showPreviousPage = offset > 0;
  let productCount = visibleCount;
  if (showNextPage || showPreviousPage) productCount = visibleCount - 1; // One space allocated for next page btn
  if (showNextPage && showPreviousPage) productCount = visibleCount - 2; // One space allocated for next page btn and one for previous btn
  return (
    <Flex key={index} width="100%" style={style} pb={'3px'} pt="3px">
      <Tooltip label={firstProduct.name} placement="auto">
        <ProductThumbnail
          product={firstProduct}
          size={75}
          onClick={() => {
            dispatchSelectProduct(firstProduct);
          }}
        />
      </Tooltip>
      <Flex direction="column" flex="1" ml="3">
        <Description elem={elem} />
        <Flex pr="10px" mt="1" flexWrap="wrap" ref={productListRef}>
          {showPreviousPage && (
            <AdditionalCount
              key={'prevbtn'}
              count={null}
              onClick={() => setOffset(Math.max(0, offset - productCount))}
            />
          )}
          {remainProducts
            .slice(offset, offset + productCount)
            .map((prod: any) => {
              return (
                <Box mr="1" key={prod.id}>
                  <ProductThumbnail
                    product={prod}
                    size={productThumbnailSize}
                    onClick={() => {
                      dispatchSelectProduct(prod);
                    }}
                  />
                </Box>
              );
            })}
          {showNextPage && (
            <AdditionalCount
              key={'nextbtn'}
              count={remainProducts.length - (offset + productCount)}
              onClick={() => setOffset(offset + productCount)}
            />
          )}
        </Flex>
      </Flex>
    </Flex>
  );
};

export function BlindProductListMobile({
  hasNextPage,
  loadNextPage,
  handleProductClick,
  filteredData,
}: Props) {
  const dispatch = useDispatch();
  const { rugFilters } = useSelector(productData);
  const listRef = useRef<any>(null);

  useEffect(() => {
    listRef?.current?.scrollToItem(0);
  }, [rugFilters]);

  function dispatchSelectProduct(product: Product) {
    dispatch(setShowRugFilters(false));
    handleProductClick(product.id);
  }

  if (filteredData.length === 0) {
    return (
      <Center m="2">
        <Text>No products found</Text>
      </Center>
    );
  }

  const isItemLoaded = (index: number) => {
    return !hasNextPage || index < filteredData.length;
  };

  const itemCount = hasNextPage ? filteredData.length + 1 : filteredData.length;

  // const rugDataOneSize = filteredData.map((rug) => {
  //   const sortedRugs = sortRugsBySize([...rug.products]);
  //   const smallestRug = sortedRugs.slice(0, 1);
  //   return {
  //     ...rug,
  //     products: smallestRug,
  //   };
  // });

  return (
    <Box flexGrow={1}>
      <InfiniteLoader
        isItemLoaded={isItemLoaded}
        itemCount={itemCount}
        loadMoreItems={loadNextPage}
        threshold={5}
      >
        {({ onItemsRendered, ref }) => (
          <AutoSizer>
            {({ height, width }) => (
              <List
                ref={(list) => {
                  // @ts-ignore - https://github.com/bvaughn/react-window/issues/324#issuecomment-528887341
                  ref(list);
                  listRef.current = list;
                }}
                onItemsRendered={onItemsRendered}
                itemData={{
                  elems: filteredData,
                  dispatchSelectProduct,
                  isItemLoaded,
                }}
                itemCount={itemCount}
                itemSize={106}
                height={height}
                width={width}
              >
                {Row}
              </List>
            )}
          </AutoSizer>
        )}
      </InfiniteLoader>
    </Box>
  );
}
