import {
  HStack,
  Box,
  Flex,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Modal,
  Text,
  Button,
} from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { SecondaryHeader } from '../../../UI/Text';
import {
  productVisualisation,
  updateBlind,
  updateIndVisualisation,
} from '../productVisualisationSlice';
import { getIndData } from '../Visualiser/helper';
import ProductScroll from '../ProductList/ProductScroll';
import { BlindFilterResponse } from 'shared/build/models/product';
import { flatMap } from 'lodash';
import { RegButton } from '../../../UI/Buttons';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { ProductView } from '../Products';
import { sendFreeMeasureAndQuote } from './helpers';
import { useEffect } from 'react';
import { useRef } from 'react';
import {
  FilterableBlindTypes,
  setShowBlindFilters,
} from '../ProductData/productDataSlice';
import BlindTypeButtons from './BlindTypeButtons';
import BlindSelectedProductHeading from './BlindSelectedProductHeading';
import { BlindProductFilters } from '../ProductData/BlindFilters';
import { BlindProductList } from '../ProductData/BlindProductList';
import {
  BlindProductFiltersMobile,
  BlindSearch,
} from '../ProductData/BlindFiltersMobile';
import { BlindProductListMobile } from '../ProductData/BlindProductListMobile';
import { ArrowBackIcon } from '@chakra-ui/icons';

type Props = {
  hasNextPage: boolean;
  loadNextPage: () => void;
  filteredData: BlindFilterResponse[];
  allData: BlindFilterResponse[];
};

export function BlindSelectedProduct({
  allData,
  filteredData,
  ...rest
}: Props) {
  const dispatch = useDispatch();
  const visData = useSelector(productVisualisation);
  const blindType = useAppSelector(
    (state) => state.productData.blindFilters.type
  )[0];
  const rooms = useAppSelector((state) => state.photos.data);
  const zoomLevel = useAppSelector((state) => state.ui.zoomLevel);
  const showBlindFilters = useAppSelector(
    (state) => state.productData.showBlindFilters
  );

  useBlindTypeUpdate(blindType, filteredData, visData.loadedIndex);

  if (visData.loadedIndex === null) return null;
  const { vis, blind, room } = getIndData(visData.loadedIndex, {
    visData: visData.data,
    roomData: rooms,
    blindData: allData,
  });

  const product = blind?.products.find((fp) => fp.id === vis.blind_id);

  const allProducts = flatMap(filteredData, (fd) => fd.products);

  function updateSelectedProduct(productId: number) {
    if (visData.loadedIndex === null) {
      return; // check to keep typescript happy - we already check above and prevent render if null
    }
    dispatch(
      updateIndVisualisation({
        ind: visData.loadedIndex,
        data: { ...vis, blind_id: productId },
      })
    );
  }

  return (
    <>
      {!blind || !product ? (
        <HStack spacing="2">
          <SecondaryHeader>Select Blind to View in Room</SecondaryHeader>
        </HStack>
      ) : (
        zoomLevel <= 1 && (
          <BlindSelectedProductHeading
            product={product}
            blind={blind}
            visIndex={visData.loadedIndex}
            room={room}
          />
        )
      )}

      {zoomLevel === 0 && (
        <>
          <Box display={{ base: 'none', sm: 'block', lg: 'none' }}>
            <BlindTypeButtons />
          </Box>
        </>
      )}

      {zoomLevel <= 1 && (
        <ProductScroll
          selectedProduct={product}
          products={allProducts}
          handleClick={updateSelectedProduct}
          showBorder
          {...rest}
        />
      )}

      {(zoomLevel === 0 || zoomLevel === 1) && (
        <RegButton
          primary
          width={'100%'}
          height={8}
          rounded="full"
          mt="2"
          mb="2"
          flex="0 0 auto"
          backgroundColor="#6CB33E"
          disabled={!product}
          _disabled={{
            color: '#141516',
            backgroundColor: '#D0D0D0',
            cursor: 'not-allowed',
            _hover: {
              color: '#141516',
              backgroundColor: '#D0D0D0',
              cursor: 'not-allowed',
            },
          }}
          _hover={{
            backgroundColor: '#6CB33E',
          }}
          onClick={() => {
            if (product) {
              sendFreeMeasureAndQuote(
                product,
                visData.data,
                visData.data[visData.loadedIndex!],
                { blindData: blind },
                ProductView.blinds
              );
            }
          }}
        >
          Request a Free Measure and Quote
        </RegButton>
      )}

      <Flex flexDir="column" flex={1} display={{ base: 'none', lg: 'flex' }}>
        <SecondaryHeader align="start" mt="2" mb="2">
          Product Filters
        </SecondaryHeader>
        <BlindProductFilters />
        <BlindProductList
          {...rest}
          filteredData={filteredData}
          handleProductClick={updateSelectedProduct}
        />
      </Flex>
      <Modal
        returnFocusOnClose={false}
        isOpen={showBlindFilters}
        onClose={() => dispatch(setShowBlindFilters(false))}
      >
        <ModalOverlay />
        <ModalContent height={'95vh'} overflowY="scroll" marginY="1rem">
          <ModalHeader
            display="flex"
            justifyContent="space-between"
            paddingBottom={0}
          >
            <Box display="flex" alignItems="center">
              <Button
                fontWeight="normal"
                backgroundColor="blue.500"
                color="white"
                rounded="full"
                leftIcon={<ArrowBackIcon />}
                height={8}
                onClick={() => dispatch(setShowBlindFilters(false))}
              >
                Back
              </Button>
            </Box>
            <Box width="70%">
              <BlindSearch />
            </Box>
          </ModalHeader>
          <ModalBody display="flex" flexDirection="column">
            <BlindProductFiltersMobile />
            <BlindProductListMobile
              {...rest}
              filteredData={filteredData}
              handleProductClick={updateSelectedProduct}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

const useBlindTypeUpdate = (
  blindType: FilterableBlindTypes,
  filteredData: BlindFilterResponse[],
  loadedIndex: number | null
) => {
  const dispatch = useAppDispatch();

  // ref's are used as we only want to update the selected product when the type has changed and the corresponding data has changed
  const typeRef = useRef(blindType);
  const dataRef = useRef(filteredData);

  useEffect(() => {
    if (dataRef.current.length === 0 && filteredData.length > 0) {
      dataRef.current = filteredData; // intially  there is no data so we just want to update with the 'first' data
      return;
    }
    if (typeRef.current === blindType) return;
    if (JSON.stringify(dataRef.current) === JSON.stringify(filteredData))
      return;
    if (loadedIndex === null) return;
    if (!filteredData.length) return;
    if (!filteredData?.[0].products[0]) return;

    dispatch(
      updateBlind({
        index: loadedIndex,
        id: filteredData[0].products[0].id,
      })
    );
    typeRef.current = blindType;
    dataRef.current = filteredData;
  }, [JSON.stringify(filteredData), blindType]);
};
