import { Box } from '@chakra-ui/react';
import InfiniteLoader from 'react-window-infinite-loader';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList as List } from 'react-window';
import {
  BlindProductDb,
  FloorProductDb,
  RugProductDb,
} from 'shared/build/models/product';
import ProductThumbnail, { BorderType } from './ProductThumbnail';
import { useEffect, useRef } from 'react';
import { Loading } from '../../../UI/Loading';

type Props = {
  selectedProduct: RugProductDb | FloorProductDb | BlindProductDb | undefined;
  products: RugProductDb[] | FloorProductDb[] | BlindProductDb[];
  handleClick: (productId: number) => void;
  hasNextPage: boolean;
  loadNextPage: () => void;
  showBorder?: boolean;
};

const ProductScroll = ({
  selectedProduct,
  products,
  handleClick,
  hasNextPage,
  loadNextPage,
  showBorder,
}: Props) => {
  const clickedRef = useRef(false);
  const listRef = useRef<any>(null);
  const listRefElem = useRef<any>(null);

  useEffect(() => {
    if (!selectedProduct) return;
    // If the selected product was caused by this component, don't update scroll pos
    if (clickedRef.current) {
      clickedRef.current = false;
      return;
    }
    const selectedProductIndex = products.findIndex(
      (product) => product.id === selectedProduct.id
    );
    listRef?.current?.scrollToItem(selectedProductIndex, 'start');
  }, [selectedProduct?.id]);

  const isItemLoaded = (index: number) => {
    return !hasNextPage || index < products.length;
  };

  const itemCount = hasNextPage ? products.length + 1 : products.length;

  return (
    <Box
      height="50px"
      flexShrink={0}
      onWheel={(e: any) => {
        if (listRef.current && listRefElem.current) {
          const newPosition = listRefElem.current.scrollLeft + e.deltaY;
          listRef?.current.scrollTo(newPosition);
        }
      }}
    >
      <InfiniteLoader
        isItemLoaded={isItemLoaded}
        itemCount={itemCount}
        loadMoreItems={loadNextPage}
        threshold={5}
      >
        {({ onItemsRendered, ref }) => (
          <AutoSizer>
            {({ height, width }) => (
              <List
                className="scroll-elem"
                layout="horizontal"
                outerRef={listRefElem}
                ref={(list) => {
                  // @ts-ignore - https://github.com/bvaughn/react-window/issues/324#issuecomment-528887341
                  ref(list);
                  listRef.current = list;
                }}
                onItemsRendered={onItemsRendered}
                itemData={{
                  elems: products,
                  selectedId: selectedProduct?.id,
                  handleClick: (productId) => {
                    if (selectedProduct?.id !== productId) {
                      clickedRef.current = true;
                      handleClick(productId);
                    }
                  },
                  isItemLoaded,
                  showBorder,
                }}
                itemCount={itemCount}
                itemSize={60}
                height={height}
                width={width}
              >
                {Column}
              </List>
            )}
          </AutoSizer>
        )}
      </InfiniteLoader>
    </Box>
  );
};

export default ProductScroll;

type ColumnProps = {
  data: {
    selectedId?: number;
    elems: RugProductDb[] | FloorProductDb[] | BlindProductDb[];
    handleClick: (productId: number) => void;
    isItemLoaded?: (index: number) => boolean;
    showBorder?: boolean;
  };
  index: number;
  style: {};
};
const Column = ({ data, index, style }: ColumnProps) => {
  const { elems, handleClick, isItemLoaded } = data;
  const product = elems[index];
  if (isItemLoaded && !isItemLoaded(index)) {
    return <Loading />;
  }
  return (
    <Box key={product.id} style={style}>
      <ProductThumbnail
        border={
          product.id === data.selectedId
            ? BorderType.full
            : data.showBorder
            ? BorderType.discrete
            : BorderType.none
        }
        key={product.id}
        product={product}
        size={50}
        onClick={() => handleClick(product.id)}
      />
    </Box>
  );
};
