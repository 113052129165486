import React from 'react';
import {
  AspectRatio,
  AspectRatioProps,
  Box,
  Button,
  ButtonProps,
  Text,
} from '@chakra-ui/react';
import { Link as RouterLink, LinkProps } from 'react-router-dom';
import { HTMLMotionProps, motion } from 'framer-motion';

type RoomviewButtonProps = ButtonProps & {
  primary?: boolean;
  ref?: any;
  textMt?: string;
  secondary?: boolean;
};

const navMarg = '4px';
export const StyledMenuButton = React.forwardRef(
  (props: RoomviewButtonProps, ref) => {
    return (
      <RegButton
        ml={navMarg}
        mr={navMarg}
        mt="4px"
        rounded={'full'}
        paddingLeft={4}
        paddingRight={4}
        {...props}
      >
        {props.children}
      </RegButton>
    );
  }
);

export const LinkButton = React.forwardRef(
  (props: LinkProps & RoomviewButtonProps, ref) => {
    return (
      <RegButton
        rounded={'full'}
        paddingLeft={4}
        ml={navMarg}
        mr={navMarg}
        mt="4px"
        paddingRight={4}
        as={RouterLink}
        {...props}
      >
        {props.children}
      </RegButton>
    );
  }
);

export const VisualiserButton = React.forwardRef(
  (props: RoomviewButtonProps, ref) => {
    return (
      <RegButton
        rounded="full"
        ref={ref}
        size="lg"
        bg="rgba(226, 232, 240, 0.7)"
        textMt="0px"
        _hover={{
          background: 'rgba(226, 232, 240, 1)',
        }}
        {...props}
      >
        {props.children}
      </RegButton>
    );
  }
);

export const RegButton = React.forwardRef((props: RoomviewButtonProps, ref) => {
  const { _hover, _focus, _active, primary, textMt, ...rest } = props as any;
  return (
    <Button
      ref={ref}
      rounded="full"
      fontWeight={400}
      height={8}
      bg={props.primary ? 'brand.blue' : props.secondary ? 'white' : 'gray.200'}
      border={props.secondary ? '1px solid' : 'none'}
      borderColor={'brand.blue'}
      color={
        props.primary ? 'white' : props.secondary ? 'brand.blue' : 'gray.700'
      }
      _hover={{
        bg: props.primary
          ? 'blue.700'
          : props.secondary
          ? 'brand.blue'
          : 'gray.300',
        color: props.primary ? 'white' : props.secondary ? 'white' : 'gray.700',
        ...(_hover || {}),
      }}
      _focus={{
        boxShadow: 'none',
        ...(_focus || {}),
      }}
      _active={{
        bg: props.primary
          ? 'brand.blue'
          : props.secondary
          ? 'brand.blue'
          : 'gray.200',
        ...(_active || {}),
      }}
      {...rest}
    >
      <Text mt={props.textMt ? props.textMt : '-4px'}>{props.children}</Text>
    </Button>
  );
});

const squareWidth = '180px';
export const MotionButton = motion(Button);
export const MotionSquare = motion(AspectRatio);
export const responsiveObj = {
  photo1: 'calc(100% - 12px)',
  photo2: 'calc(50% - 12px)',
  photo3: 'calc(33% - 12px)',
  photo4: 'calc(25% - 12px)',
  photo5: 'calc(20% - 12px)',
};

export function SquareButton(props: HTMLMotionProps<'div'> & AspectRatioProps) {
  return (
    <MotionSquare
      whileHover={{ scale: 1.02 }}
      whileTap={{ scale: 1 }}
      maxWidth={squareWidth}
      maxHeight={squareWidth}
      width={responsiveObj}
      height="100%"
      margin="6px"
      _focus={{
        boxShadow: 'none',
      }}
      fontWeight="bold"
      cursor="pointer"
      bg="gray.100"
      ratio={1}
      borderRadius="xl"
      {...props}
    >
      <Box w="100%" h="100%">
        {props.children}
      </Box>
    </MotionSquare>
  );
}

export function SquareImageButton(
  props: HTMLMotionProps<'button'> & ButtonProps
) {
  return (
    <MotionButton
      whileHover={{ scale: 1.02 }}
      whileTap={{ scale: 1 }}
      borderRadius="xl"
      overflow="hidden"
      maxWidth={squareWidth}
      maxHeight={squareWidth}
      width={'100%'}
      height={'100%'}
      padding="0"
      bg="gray.100"
      _focus={{
        boxShadow: 'none',
      }}
      {...props}
    >
      {props.children}
    </MotionButton>
  );
}
