import { useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from '@emotion/styled';
import { useOutsideClick } from '@chakra-ui/react';
import { setRightclick, ui } from '../../../../redux/uiSlice';
import { handleSaveImage } from '../../../Share/helpers';
import { VisualisationData } from '../../productVisualisationSlice';
import { ProductView } from '../../Products';
import { SelectedData } from '../helper';

type Props = {
  loadedVisualisation: VisualisationData;
  loadedData: SelectedData;
  view: ProductView;
};

export function RightClickHandler({
  loadedVisualisation,
  loadedData,
  view,
}: Props) {
  let ref = useRef(null);
  let dispatch = useDispatch();
  function close() {
    dispatch(setRightclick({ visible: false }));
  }
  useOutsideClick({
    ref: ref,
    handler: () => {
      close();
    },
  });
  let { rightClick } = useSelector(ui);
  if (!rightClick.visible) {
    return null;
  }

  const { visData: _, roomData: __, ...productData } = loadedData;
  return (
    <RightClickMenu
      ref={ref}
      style={{
        top: (rightClick.y || 0) + 'px',
        left: (rightClick.x || 0) + 'px',
      }}
      onContextMenu={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <DropdownChoice
        onClick={async () => {
          await handleSaveImage(loadedVisualisation, productData, view);
          close();
        }}
      >
        Download Image
      </DropdownChoice>
    </RightClickMenu>
  );
}

export const DropdownChoice = styled.div`
  padding: 10px;
  cursor: pointer;
  :hover {
    background: #eee;
  }
  :active {
    background: #efefef;
  }
`;
const RightClickMenu = styled.div`
  cursor: pointer;
  position: fixed;
  z-index: 999999;
  background-color: white;
  border-radius: 3px;
  font-size: 12px;
  padding: 5px;
  cursor: default;
  line-height: 1.2;
  box-shadow: 0 0 5px 0 #ccc;
`;
