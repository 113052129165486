import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { store } from './redux/store';
import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';
import axios from './axios';
import get from 'lodash/get';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { CaptureConsole } from '@sentry/integrations';
import { BrowserRouter } from 'react-router-dom';

// // If page is opened through create react app server automatically, redirect to docker-compose hosted app
// if (window.location.host.startsWith('localhost')) {
//   window.location.href = 'https://testroomviewapp.prendi.com.au';
// }

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  function (error) {
    return Promise.reject({
      response: error.response,
      message:
        get(error, 'response.data.name', '') +
        ' ' +
        get(error, 'response.data.message', error.message || '') +
        ' ' +
        get(error, 'response.data.details', ''),
    });
  }
);

Sentry.init({
  dsn: 'https://8bb239831e404f9fad052e0a55321403@o1146025.ingest.sentry.io/6214319',
  integrations: [
    new BrowserTracing(),
    new CaptureConsole({
      levels: ['error'],
    }),
  ],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 0.1,
});

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
