import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './store';

type RightClickPayload = {
  visible: boolean;
  x?: number;
  y?: number;
};

type UIState = {
  hasShownRugOnboarding: boolean;
  hasShownBlindOnboarding: boolean;
  rightClick: RightClickPayload;
  isInspirationStation: boolean;
  zoomLevel: 0 | 1 | 2;
};

const initialState: UIState = {
  hasShownRugOnboarding: false,
  hasShownBlindOnboarding: false,
  rightClick: {
    visible: false,
    x: 0,
    y: 0,
  },
  isInspirationStation: false,
  zoomLevel: 0,
};

export const uiSlice = createSlice({
  name: 'ui',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    showRugOnboarding(state) {
      state.hasShownRugOnboarding = true;
    },
    setRightclick(state, action: PayloadAction<RightClickPayload>) {
      state.rightClick = action.payload;
    },
    showBlindOnboarding(state) {
      state.hasShownBlindOnboarding = true;
    },
    setIsInspirationStation(state) {
      state.isInspirationStation = true;
    },
    setZoomLevel(state, action: PayloadAction<0 | 1 | 2>) {
      // console.log({ action });
      state.zoomLevel = action.payload;
    },
  },
});

export const {
  showRugOnboarding,
  showBlindOnboarding,
  setRightclick,
  setIsInspirationStation,
  setZoomLevel,
} = uiSlice.actions;

export const ui = (state: RootState) => state.ui;

export default uiSlice.reducer;
