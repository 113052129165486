import { Center } from '@chakra-ui/react';
import axios from '../../axios';
import { useState, useEffect } from 'react';
import { RoomApi } from 'shared/build/models/room';
import { SavedApi } from 'shared/build/models/saved';
import { config } from '../../config';
import { useAppDispatch } from '../../redux/hooks';
import { Loading } from '../../UI/Loading';
import { hasLocalStorageAccess } from '../../utils/localStorage';
import { setRooms } from '../Photos/photosSlice';
import { setSaved } from '../Saved/savedSlice';

const DataLoader = ({ children }: { children: React.ReactNode }) => {
  const isLoading = useInitialData();

  if (isLoading) {
    return (
      <Center h="100%" w="100%">
        <Loading />
      </Center>
    );
  }
  return <>{children}</>;
};

export default DataLoader;

const useInitialData = () => {
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    (async () => {
      try {
        await auth();

        const promises = [
          axios.get('/api/v1/room/list?region=' + config.region),
          axios.get<{ data: SavedApi[] }>('/api/v1/saved/list'),
        ];
        const [roomsRes, savedRes] = await Promise.all(promises);
        const rooms: RoomApi[] = roomsRes.data.data;
        const saved: SavedApi[] = savedRes.data.data;
        dispatch(setRooms(rooms));
        dispatch(setSaved(saved));
        setIsLoading(false);
      } catch (err) {
        window.alert('failed to load data' + err.message);
        // do we want to build a ui here - maybe modal with the message and a refresh button?
        setIsLoading(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return isLoading;
};

async function auth() {
  try {
    let accessToken = '';
    if (hasLocalStorageAccess()) {
      // standard flow
      let token = localStorage.getItem('roomview_token');
      if (!token) {
        const res = await axios.get('/api/v1/session/new');
        const newToken = JSON.stringify(res.data.data);
        localStorage.setItem('roomview_token', newToken);
        token = newToken;
      }
      if (!token) throw new Error('Error retrieving/generating user session');
      const { access_token } = JSON.parse(token);
      accessToken = access_token;
    } else {
      // handles incognito chrome - no access to localStorage from iframe
      const res = await axios.get('/api/v1/session/new');
      const { access_token } = res.data.data;
      accessToken = access_token;
    }
    axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
  } catch (err) {
    console.error(err);
  }
}
