import { Button, IconButton, Tooltip, useToast } from '@chakra-ui/react';
import axios from '../../../axios';
import { omit, isEqual } from 'lodash';
import { useRef, useState } from 'react';
import { AiFillHeart, AiOutlineHeart } from 'react-icons/ai';
import { MdClose } from 'react-icons/md';
import { useDispatch, useSelector, batch } from 'react-redux';
import { RoomApi } from 'shared/build/models/room';
import { CreateSavedApiReq, SavedApi } from 'shared/build/models/saved';
import { useAppSelector } from '../../../redux/hooks';
import { deleteSavedApi } from '../../../utils/api';
import { trackEvent } from '../../../utils/tracking';
import { addNewRoom } from '../../Photos/photosSlice';
import { saved, removeSaved, addNewSaved } from '../../Saved/savedSlice';
import { productData } from '../ProductData/productDataSlice';
import { productView, ProductView } from '../Products';
import { productVisualisation } from '../productVisualisationSlice';
import { generateWatermarkImage, getWatermarkData } from './generateWatermark';
import { getCanvImage, getIndData } from './helper';

const SaveIconNew = ({ altColor }: { altColor?: string }) => {
  const dispatch = useDispatch();
  const savedData = useSelector(saved).data;
  const { floorData, rugData, blindData } = useSelector(productData);
  const visData = useAppSelector((state) => state.productVisualisation);
  const photos = useAppSelector((state) => state.photos.data);
  const { floor, rug, blind } = getIndData(visData.loadedIndex ?? 0, {
    visData: visData.data,
    floorData,
    rugData,
    blindData,
  });

  const view = productView(window.location.pathname);

  const { data } = useSelector(productVisualisation);
  const loadedVisualisation = data[visData.loadedIndex ?? 0];

  const [loading, setLoading] = useState(false);
  const toast = useToast();
  const toastIdRef = useRef<number | string>();

  const closeToast = () =>
    toastIdRef.current && toast.close(toastIdRef.current);
  const fireToast = (text: string) => {
    if (toastIdRef.current) {
      closeToast();
    }
    toastIdRef.current = toast({
      status: 'success',
      isClosable: true,
      position: 'top-left',
      render: () => (
        <Button
          leftIcon={<AiFillHeart />}
          rightIcon={<MdClose />}
          bg="green.500"
          rounded="full"
          color="white"
          onClick={closeToast}
        >
          {text}
        </Button>
      ),
    });
  };

  const omitFields: (keyof SavedApi)[] = [
    'id',
    'thumbnail_url',
    'watermark_url',
  ];
  const currentVis = omit(loadedVisualisation, omitFields);
  const matchingSavedItem = savedData.find((savedItem) => {
    const savedVisualisation = omit(savedItem, omitFields);
    return isEqual(currentVis, savedVisualisation);
  });

  async function handleClick() {
    if (loading) return;
    setLoading(true);
    if (matchingSavedItem) {
      try {
        await deleteSavedApi(matchingSavedItem.id);
        dispatch(removeSaved(matchingSavedItem.id));
        trackEvent({ category: 'Save', action: 'Room Unsaved' });
        fireToast('Removed from Saved');
      } catch (e: any) {
        window.alert('Failed to remove favourite' + e.message);
      } finally {
        setLoading(false);
      }
    } else {
      try {
        const wD = getWatermarkData(view as ProductView, loadedVisualisation, {
          floorData: floor,
          rugData: rug,
          blindData: blind,
        });

        const blob = await getCanvImage();
        const blob2 = await generateWatermarkImage(wD);
        const d = {
          ...loadedVisualisation,
        };
        const savedRoomData = await saveRoom(blob, blob2, d);
        batch(() => {
          if (savedRoomData.room) {
            const { room } = savedRoomData;
            const isAlreadyAdded = photos.some((photo) => photo.id === room.id);
            if (!isAlreadyAdded) {
              dispatch(addNewRoom(savedRoomData.room));
            }
          }
          dispatch(addNewSaved(savedRoomData.saved));
        });
        trackEvent({ category: 'Save', action: 'Room Saved' });
        setLoading(false);
        fireToast('Added to Saved');
      } catch (e: any) {
        window.alert('Failed to add favourite' + e.message);
        setLoading(false);
      }
    }
  }

  return (
    <Tooltip
      rounded="md"
      hasArrow
      label={matchingSavedItem ? 'Remove from Saved' : 'Save'}
    >
      <IconButton
        _hover={{ backgroundColor: 'white' }}
        className="visualiser-btn" // id added for a document.querySelector inside visualiser
        backgroundColor="inherit"
        height="unset"
        width="unset"
        minWidth="0"
        size="sm"
        color={matchingSavedItem ? 'red' : 'grey:700'}
        aria-label="Save Favourite"
        isLoading={loading}
        isDisabled={loading}
        icon={
          matchingSavedItem ? (
            <AiFillHeart width="14px" height="14px" color={altColor} />
          ) : (
            <AiOutlineHeart width="14px" height="14px" color={altColor} />
          )
        }
        borderColor={altColor}
        onClick={handleClick}
      />
    </Tooltip>
  );
};

export default SaveIconNew;

async function saveRoom(file: Blob, file2: Blob, config: CreateSavedApiReq) {
  const data = new FormData();
  data.append('images', file!);
  data.append('images', file2!);
  data.append('body', JSON.stringify(config));
  const uploadedData: { saved: SavedApi; room?: RoomApi } = (
    await axios.post('/api/v1/saved/new', data, {})
  ).data.data;
  return uploadedData;
}
