import { Region } from 'shared/build/models/product';
import { config } from '../../config';
import {
  useGetRugProductsQuery,
  useGetFloorProductsQuery,
  useGetBlindProductsQuery,
} from '../../redux/api';
import { useQuery } from '../../utils/hooks';

export function isSelected(ids: string[], id: string) {
  return ids.includes(id);
}

export const useSku = () => {
  const product = useQuery('product');
  const sku = useQuery('sku');
  const filters = sku ? { skus: [sku] } : {};

  const { data: rugData, isLoading: isRugLoading } = useGetRugProductsQuery(
    {
      region: config.region,
      active: true,
      ...filters,
    },
    { skip: product !== 'rugs' || !sku }
  );

  const { data: floorData, isLoading: isFloorLoading } =
    useGetFloorProductsQuery(
      {
        active: true,
        region: config.region,
        ...filters,
      },
      { skip: product === 'rugs' || product === 'blinds' || !sku }
    );

  const { data: blindsData, isLoading: isBlindsLoading } =
    useGetBlindProductsQuery(
      {
        region: config.region,
        active: true,
        ...filters,
      },
      { skip: product !== 'blinds' || !sku }
    );

  const isLoading = sku && (isRugLoading || isFloorLoading || isBlindsLoading);
  return {
    rugData,
    floorData,
    blindsData,
    isLoading,
  };
};
