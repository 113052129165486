import { Flex, Modal, useDisclosure } from '@chakra-ui/react';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useGetBlindProductsQuery } from '../../redux/api';
import { productData, setBlindProducts } from './ProductData/productDataSlice';
import { productVisualisation } from './productVisualisationSlice';
import { BlindSelectedProduct } from './SelectedProduct/BlindSelectedProduct';
import { config } from '../../config';
import { BlindFilterResponse } from 'shared/build/models/product';
import { flatMap } from 'lodash';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { showBlindOnboarding } from '../../redux/uiSlice';
import ProductOnboarding from './ProductOnboarding';

function BlindProducts() {
  const dispatch = useAppDispatch();
  const hasShownBlindOnboarding = useAppSelector(
    (state) => state.ui.hasShownBlindOnboarding
  );
  const { isOpen, onOpen, onClose } = useDisclosure();
  useEffect(() => {
    if (!hasShownBlindOnboarding) {
      onOpen();
      dispatch(showBlindOnboarding());
    }
  }, [hasShownBlindOnboarding]);
  const { hasNextPage, loadNextPage, filteredData, allData } = useBlindData();

  return (
    <Flex
      backgroundColor="white"
      flexDir="column"
      w={{ base: '100%', sm: '300px', md: '300px', lg: '500px' }}
      p="0 10px"
      height={{ base: 'auto', lg: 'calc(100vh - 50px)' }}
    >
      <BlindSelectedProduct
        hasNextPage={hasNextPage}
        loadNextPage={loadNextPage}
        allData={allData}
        filteredData={filteredData}
      />
      <Modal isOpen={isOpen} onClose={onClose} size="xs">
        <ProductOnboarding
          onClose={onClose}
          videoUrl="https://choices-roomview.s3.ap-southeast-2.amazonaws.com/onboarding_assets/Blinds_small.mp4"
          heading="Use the controls to move and position the blinds in your room"
        />
      </Modal>
    </Flex>
  );
}
export default BlindProducts;

const useBlindData = () => {
  const dispatch = useDispatch();

  const { blindFilters } = useSelector(productData);
  const visualisations = useSelector(productVisualisation);
  const visualisationProductIds = visualisations.data.reduce(
    (acc, visualisation) => {
      if (visualisation.blind_id !== null) {
        return [...acc, visualisation.blind_id];
      }
      return acc;
    },
    [] as number[]
  );
  const [allData, setAllData] = useState<{
    [key: string]: BlindFilterResponse[];
  }>({});

  const { data, error, isFetching } = useGetBlindProductsQuery({
    region: config.region,
    active: true,
    ...blindFilters,
  });

  const { data: visualisationProductData } = useGetBlindProductsQuery(
    {
      region: config.region,
      active: true,
      product_ids: visualisationProductIds,
    },
    {
      skip: visualisationProductIds.length === 0,
    }
  );

  useEffect(() => {
    setAllData({ [0]: data?.data || [] });
  }, [blindFilters]);

  useEffect(() => {
    if (!data) return;
    setAllData({ ...allData, [data.meta.offset]: data.data });
  }, [data]);

  // combine product data with those needed in the visualisations
  const { filteredData, combined } = useMemo(() => {
    const data = flatMap(Object.values(allData), (blindGroup) => blindGroup);
    if (!visualisationProductData) {
      return { filteredData: data, combined: data };
    }

    const dataWithMissingBlinds = addMissingBlinds(
      data,
      visualisationProductData.data
    );
    const extraVisualisationProducts = visualisationProductData.data.filter(
      (visProductData) =>
        !data.some(
          (product) =>
            product.brand_category.id === visProductData.brand_category.id
        )
    );
    return {
      filteredData: data,
      combined: [...dataWithMissingBlinds, ...extraVisualisationProducts],
    };
  }, [allData, visualisationProductData?.data]);

  useEffect(() => {
    dispatch(setBlindProducts(combined));
  }, [combined]);

  function loadNextPage() {
    console.error(
      'Load next page logic not implemented for window furnishings'
    );
  }

  const hasNextPage = data?.data.length === 25;

  return { hasNextPage, loadNextPage, allData: combined, filteredData };
};

function addMissingBlinds(
  data: BlindFilterResponse[],
  visualisationProductData: BlindFilterResponse[]
) {
  const dataWithMissingBlinds = data.map((product) => {
    const matchingVisProduct = visualisationProductData.find(
      (visProduct) => visProduct.brand_category.id === product.brand_category.id
    );
    if (!matchingVisProduct) {
      return product;
    }
    const missingBlinds = matchingVisProduct.products.filter((visBlind) => {
      const isNotMissing = product.products.some(
        (blind) => blind.id === visBlind.id
      );
      return !isNotMissing;
    });

    return {
      ...product,
      products: [...product.products, ...missingBlinds],
    };
  });
  return dataWithMissingBlinds;
}
