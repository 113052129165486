// Communication module to allow third party website to communicate with the application for showing modals etc
const DEBUG = false;

export enum IframeSendTypes {
  productInfo = 'product-info',
  freeMeasureAndQuote = 'free-measure-and-quote',
  addToCart = 'add-to-cart',
}

export function sendIframeMessage(type: IframeSendTypes, payload: {}) {
  if (DEBUG) {
    console.log('Sending message from iframe to player', type);
  }
  if (!window.parent || window.parent === window) {
    console.warn('Payload %O not emitted to parent frame', payload);
    window.alert(
      `${type} requires the app to be embedded inside an iframe to work`
    );
    return null;
  }
  const requestId = String(Math.random());
  window.parent.postMessage(
    {
      target: 'visualiser-app-embed',
      message: type,
      data: payload,
      request_id: requestId,
    },
    '*'
  );
  return requestId;
}

export enum IframeReceiveTypes {}

/* Not currently used as messages are currently one way */
export function receiveIframeMessages(
  guard: Function,
  callback: (payload: any) => any
) {
  let listener: any | null = (event: any) => {
    if (event.source === window.parent && event.data && guard(event.data)) {
      if (DEBUG) {
        console.log('Recieved message from player in iframe', event);
      }
      callback(event.data);
    }
  };
  function closeConnection() {
    if (listener) {
      window.removeEventListener('message', listener);
      listener = null;
    }
  }

  window.addEventListener('message', listener, false);
  return closeConnection;
}

/* Not currently used as messages are currently one way */
export function sendReceiveIframeMessage(
  type: IframeSendTypes,
  payload: {},
  callback: (payload: any) => any
) {
  let requestId: string | null = null;
  const closeConnection = receiveIframeMessages(
    (msg: any) => msg.request_id === requestId,
    callback
  ); // set up listener first
  requestId = sendIframeMessage(type, payload); // send message once listener is set up
  return closeConnection;
}
