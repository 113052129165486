import {
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { RoutePaths } from '../../App';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { LinkButton } from '../../UI/Buttons';
import { useQuery } from '../../utils/hooks';
import { clearSaved, saved } from '../Saved/savedSlice';
import * as api from '../../utils/api';
import { clearRooms } from '../Photos/photosSlice';
import { batch } from 'react-redux';

const ReturningWelcome = () => {
  const welcome = useQuery('welcome');
  const [isOpen, setIsOpen] = useState(!!welcome);
  const savedDesigns = useAppSelector(saved);
  const history = useHistory();
  const appDispatch = useAppDispatch();

  return (
    <Modal isOpen={isOpen} onClose={() => setIsOpen(false)}>
      <ModalOverlay />
      <ModalContent maxW="320px" p="25px">
        <ModalCloseButton />
        <ModalBody p="0">
          <Flex flexDirection="column" gridGap="20px">
            <Text alignSelf="center">Welcome back to RoomView!</Text>
            <LinkButton
              to={RoutePaths.saved}
              primary
              onClick={() => {
                setIsOpen(false);
                if (!savedDesigns) {
                  history.push(RoutePaths.photos);
                }
              }}
            >
              Continue with previous project
            </LinkButton>

            <LinkButton
              to={RoutePaths.onboarding}
              primary
              onClick={async () => {
                try {
                  await api.deleteSavedItems();
                  await api.deleteRooms();
                  batch(() => {
                    appDispatch(clearSaved());
                    appDispatch(clearRooms());
                  });
                  setIsOpen(false);
                } catch (err) {
                  alert('error clearing saved data');
                }
              }}
            >
              Start a new project
            </LinkButton>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ReturningWelcome;
