import { Plane } from '@react-three/drei';
import { ThreeEvent, useThree } from '@react-three/fiber';
import { useMemo, useRef } from 'react';
import { useEffect } from 'react';
import { Suspense } from 'react';
import { RugProductDb } from 'shared/build/models/product';
import {
  Vector2,
  RepeatWrapping,
  Texture,
  LinearFilter,
  Raycaster,
  Object3D,
  Camera,
  LinearMipMapLinearFilter,
} from 'three';
import { threeCanv } from '.';
import { checkVisualiserBtn } from '../helper';
import { setPannable, setShowRugControls } from '../visualiserSlice';
import { xRotation } from './Flooring';

// To understand reasoning behind props dispatch passing:
// https://github.com/reduxjs/react-redux/issues/355#issuecomment-896504179
const [rugHeight, shadowHeight, rotateHeight] = [0.05, 0.039, 0.04];

function Rug(props: {
  rug: RugProductDb;
  onCompleted: Function;
  texture: Texture;
  rotateTexture: Texture;
  dispatch: Function;
  position: { x: number; z: number };
  rotation: number;
  showControls: boolean;
  rugIsTextured: boolean;
}) {
  // define the texture attributes
  const three = useThree();
  const anisotropy = useMemo(() => {
    return three.gl.capabilities.getMaxAnisotropy();
  }, []);
  const { dispatch, showControls } = props;
  const setShowControls = (show: boolean) => dispatch(setShowRugControls(show));

  const rugRef = useRef(null as null | THREE.Object3D);
  const rugShadowRef = useRef(null as null | THREE.Object3D);
  const rugRotationRef = useRef(null as null | THREE.Object3D);
  const rugPointerDown = useRef(false);
  const delta = useRef({ x: 0, z: 0 }); // difference between center of rug and where it is clicked
  const infinitePlane = useRef(null as null | THREE.Object3D); // This is the plane that the rug will be allowed to move on, it corresponds to the floor
  const { camera } = useThree();
  const threeObjs = useMemo(() => ({ raycaster: new Raycaster() }), []);
  const texture = props.texture;
  texture.repeat = new Vector2(1, 1);
  texture.wrapS = RepeatWrapping;
  texture.wrapT = RepeatWrapping;
  // If rug style is textured, we use LinearMipMapLinearFilter
  // To reduce strange noise that can occur from this kind of image
  // otherwise we use a LinearFilter as it is crisp and retains sharp details
  texture.minFilter = props.rugIsTextured
    ? LinearMipMapLinearFilter
    : LinearFilter;
  texture.anisotropy = anisotropy;
  texture.center = new Vector2(0.5, 0.5);

  const rotateTexture = props.rotateTexture;
  rotateTexture.repeat = new Vector2(1, 1);
  rotateTexture.wrapS = RepeatWrapping;
  rotateTexture.wrapT = RepeatWrapping;
  rotateTexture.minFilter = LinearFilter;
  rotateTexture.center = new Vector2(0.5, 0.5);

  useEffect(() => {
    return onClickOutside(showControls, setShowControls, threeObjs, camera, {
      rugRotationRef,
      rugRef,
    });
  }, [showControls]);

  const sizeX = props.rug.width / 100; // cm to m
  const sizeY = props.rug.length / 100; // cm to m

  // const rotateSize = size * (Math.PI / 2 + 0.1); // 0.1 is buffer as circle.png isn't to the very edge
  const rotateSize = Math.max(sizeX, sizeY) * (Math.PI / 2.2); // 0.1 is buffer as circle.png isn't to the very edge
  const allRefs = {
    rugPointerDown,
    delta,
    rugRef,
    rugShadowRef,
    rugRotationRef,
    infinitePlane,
  };

  return (
    <Suspense fallback={null}>
      <mesh
        ref={rugRef}
        rotation={[xRotation, 0, props.rotation]}
        position={[props.position.x, rugHeight, props.position.z]}
        onPointerDown={(e) => {
          rugPan(
            e,
            dispatch,
            threeObjs,
            camera,
            props.onCompleted,
            props.rotation,
            allRefs
          );
        }}
      >
        <planeBufferGeometry attach="geometry" args={[sizeX, sizeY, 1, 1]} />
        <meshStandardMaterial
          attach="material"
          transparent={true}
          roughness={0.9}
          map={texture}
          onUpdate={(self) => texture && (self.needsUpdate = true)}
        />
      </mesh>
      <mesh
        ref={rugShadowRef}
        rotation={[xRotation, 0, props.rotation]}
        position={[props.position.x, shadowHeight, props.position.z]}
      >
        <planeBufferGeometry attach="geometry" args={[sizeX, sizeY, 1, 1]} />
        <meshStandardMaterial
          attach="material"
          color={0x777777}
          opacity={0.7}
          roughness={0.95}
          transparent={true}
          map={texture}
          onUpdate={(self) => texture && (self.needsUpdate = true)}
        />
      </mesh>
      {showControls && (
        <mesh
          ref={rugRotationRef}
          rotation={[xRotation, 0, props.rotation]}
          position={[props.position.x, rotateHeight, props.position.z]}
          onPointerDown={(e) => {
            rugRotate(
              e,
              dispatch,
              threeObjs,
              camera,
              (pos: { x: number; z: number }, rotation: number) => {
                if (rotation === props.rotation) {
                  // click occured without rotating
                  setShowControls(false);
                }
                props.onCompleted(pos, rotation);
              },
              props.position,
              allRefs
            );
          }}
        >
          <planeBufferGeometry
            attach="geometry"
            args={[rotateSize, rotateSize, 1, 1]}
          />
          <meshStandardMaterial
            attach="material"
            map={rotateTexture}
            transparent={true}
            onUpdate={(self) => rotateTexture && (self.needsUpdate = true)}
          />
        </mesh>
      )}

      {/* Large plane for raycasting (not visible) */}
      <Plane
        visible={false}
        ref={infinitePlane}
        position={[0, 0.05, 0]}
        rotation={[xRotation, 0, 0]}
        args={[500, 500]}
      />
    </Suspense>
  );
}

type ThreeObjs = { raycaster: Raycaster };

function onClickOutside(
  showControls: boolean,
  setShowControls: Function,
  threeObjs: ThreeObjs,
  camera: Camera,
  refs: {
    rugRotationRef: any;
    rugRef: any;
  }
) {
  function handleClick(e: PointerEvent) {
    const canvElem = threeCanv();
    const isVisualiserBtn = checkVisualiserBtn(e);
    if (isVisualiserBtn) return;
    // if clicked outside of the canv
    if (!canvElem.contains(e.target as any)) {
      if (showControls) setShowControls(false);
      return;
    }

    const rect = threeCanv().getBoundingClientRect();
    const hit = raycastIntersects(
      rect,
      e,
      threeObjs.raycaster,
      camera,
      showControls ? refs.rugRotationRef.current! : refs.rugRef.current!
    );
    if (hit.length && !showControls) {
      setShowControls(true);
    } else if (!hit.length && showControls) {
      setShowControls(false);
    }
  }
  document.addEventListener('pointerdown', handleClick);
  return () => document.removeEventListener('pointerdown', handleClick);
}

function rugPan(
  e: ThreeEvent<PointerEvent>,
  dispatch: Function,
  threeObjs: ThreeObjs,
  camera: Camera,
  onCompleted: Function,
  rotation: number,
  refs: {
    rugPointerDown: any;
    delta: any;
    rugRef: any;
    rugShadowRef: any;
    rugRotationRef: any;
    infinitePlane: any;
  }
) {
  dispatch(setPannable(false));
  const rect = (
    e.sourceEvent.target! as HTMLCanvasElement
  ).getBoundingClientRect();

  refs.rugPointerDown.current = true;
  refs.delta.current = getDeltaFromCenter(e);
  function onMove(event: PointerEvent) {
    if (refs.rugPointerDown.current) {
      const floor = raycastIntersects(
        rect,
        event,
        threeObjs.raycaster,
        camera,
        refs.infinitePlane.current!
      );
      if (floor.length) {
        const [x, z] = [
          floor[0].point.x - refs.delta.current.x,
          floor[0].point.z - refs.delta.current.z,
        ];
        refs.rugRef.current?.position.set(x, rugHeight, z);
        refs.rugShadowRef.current?.position.set(x, shadowHeight, z); // lower shadow to make it more pronounced when dragging
        refs.rugRotationRef.current?.position.set(x, rotateHeight, z);
      }
    }
  }
  function onPointerUpOrLeave() {
    refs.rugPointerDown.current = false;
    document.removeEventListener('pointerup', onPointerUpOrLeave);
    document.removeEventListener('pointermove', onMove);
    document.removeEventListener('pointerleave', onPointerUpOrLeave);
    dispatch(setPannable(true));
    const pos = refs.rugRef.current?.position!;
    onCompleted({ x: pos.x, z: pos.z }, rotation);
  }
  document.addEventListener('pointermove', onMove);
  document.addEventListener('pointerup', onPointerUpOrLeave);
  document.addEventListener('pointerleave', onPointerUpOrLeave);
}

function rugRotate(
  e: ThreeEvent<PointerEvent>,
  dispatch: Function,
  threeObjs: ThreeObjs,
  camera: Camera,
  onCompleted: Function,
  position: { x: number; z: number },
  refs: {
    rugPointerDown: any;
    delta: any;
    rugRef: any;
    rugShadowRef: any;
    rugRotationRef: any;
    infinitePlane: any;
  }
) {
  const rugCenterPoint = [e.object.position.x, e.object.position.z];
  let prevPoint = [e.point!.x, e.point!.z];
  function onMove(e: PointerEvent) {
    const floor = raycastIntersects(
      rect,
      e,
      threeObjs.raycaster,
      camera,
      refs.infinitePlane.current!
    );
    if (floor.length) {
      const newPoint = [floor[0].point.x, floor[0].point.z];
      const prevCenterDelta = [
        prevPoint[0] - rugCenterPoint[0],
        prevPoint[1] - rugCenterPoint[1],
      ];
      const prevLengthFromCenter = hypotenuse(
        prevCenterDelta[0],
        prevCenterDelta[1]
      );
      const newCenterDelta = [
        newPoint[0] - rugCenterPoint[0],
        newPoint[1] - rugCenterPoint[1],
      ];
      const newLengthFromCenter = hypotenuse(
        newCenterDelta[0],
        newCenterDelta[1]
      );
      const lengthBetweenPoints = hypotenuse(
        newPoint[0] - prevPoint[0],
        newPoint[1] - prevPoint[1]
      );
      // use law of cosines to calc angle between last point and new point
      // https://www.mathsisfun.com/algebra/trig-solving-sss-triangles.html
      const [a, b, c] = [
        prevLengthFromCenter,
        lengthBetweenPoints,
        newLengthFromCenter,
      ];
      const angleOppositeB = Math.acos((c * c + a * a - b * b) / (2 * c * a));
      const direction = isClockwise(prevCenterDelta, newCenterDelta) ? 1 : -1;
      const rotationAngle = angleOppositeB * direction;
      refs.rugRotationRef.current?.rotateZ(rotationAngle);
      refs.rugRef.current?.rotateZ(rotationAngle);
      refs.rugShadowRef.current?.rotateZ(rotationAngle);
      prevPoint = newPoint;
    }
  }
  function onPointerUpOrLeave() {
    document.removeEventListener('pointerup', onPointerUpOrLeave);
    document.removeEventListener('pointermove', onMove);
    document.removeEventListener('pointerleave', onPointerUpOrLeave);
    dispatch(setPannable(true));
    onCompleted(position, refs.rugRotationRef.current?.rotation.z);
  }
  const rect = (
    e.sourceEvent.target! as HTMLCanvasElement
  ).getBoundingClientRect();
  const mainRug = raycastIntersects(
    rect,
    e,
    threeObjs.raycaster,
    camera,
    refs.rugRef.current!
  );
  if (mainRug.length === 0) {
    refs.delta.current = getDeltaFromCenter(e);
    dispatch(setPannable(false));
    document.addEventListener('pointermove', onMove);
    document.addEventListener('pointerup', onPointerUpOrLeave);
    document.addEventListener('pointerleave', onPointerUpOrLeave);
  }
}

function raycastIntersects(
  rect: DOMRect,
  event: PointerEvent,
  raycaster: Raycaster,
  camera: Camera,
  threeElem: Object3D
) {
  const mouse = {
    x: ((event.clientX - rect.left) / rect.width) * 2 - 1,
    y: -((event.clientY - rect.top) / rect.height) * 2 + 1,
  };
  raycaster.setFromCamera(mouse, camera);
  const intersections = raycaster.intersectObject(threeElem);
  return intersections;
}

function getDeltaFromCenter(event: ThreeEvent<PointerEvent>) {
  return {
    x: event.point!.x - event.object.position.x,
    z: event.point!.z - event.object.position.z,
  };
}

function hypotenuse(a: number, b: number) {
  return Math.sqrt(Math.pow(a, 2) + Math.pow(b, 2));
}

// Linear algebra dot product to calculate direction and consequently clockwiseness
// https://gamedev.stackexchange.com/questions/45412/understanding-math-used-to-determine-if-vector-is-clockwise-counterclockwise-f/45434
function isClockwise(point1: number[], point2: number[]) {
  return point1[1] * point2[0] > point1[0] * point2[1];
}

export default Rug;
