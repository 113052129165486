import { useRef, useState, useMemo, useEffect } from 'react';
import * as PIXI from 'pixi.js';
import { Texture, TextureLoader } from 'three';
import { useDispatch, useSelector } from 'react-redux';
import {
  setProductLoadingState,
  setRoomLoadingState,
  VisualisationData,
} from '../../productVisualisationSlice';
import { getImageBrightnessFromFile } from './CalculateImageBrightness';
import { setDimensions } from '../../helpers';
import {
  FloorFilterResponse,
  RugFilterResponse,
  BlindFilterResponse,
} from 'shared/build/models/product';
import {
  useGetRugProductsQuery,
  useGetFloorProductsQuery,
  useGetBlindProductsQuery,
} from '../../../../redux/api';
import { config } from '../../../../config';
import {
  generateLoadRequestData,
  getBlindProducts,
  getFloorProducts,
  getIndData,
  getRugProducts,
  SelectedData,
} from '../helper';
import { productData } from '../../ProductData/productDataSlice';
import { photos } from '../../../Photos/photosSlice';
const rugRotateCircle = require('../textures/circle.png').default;

export type RoomLoadedImage = {
  id: string;
  image: PIXI.Texture;
  mask?: PIXI.Texture;
  shadow_mask?: PIXI.Texture;
  reflection?: PIXI.Texture;
};

export type FloorLoadedImage = {
  id: number;
  image: Texture;
  brightness: number;
};

export type RugLoadedImage = {
  id: number;
  image: Texture;
  rotateIcon: Texture;
};

export type BlindLoadedImage = {
  id: number;
  image: PIXI.Texture;
  mask: PIXI.Texture;
  overlay: PIXI.Texture;
  trim: PIXI.Texture;
};

export type RoomProvidedImage = {
  id: string;
  image: string;
  mask?: string;
  shadow_mask?: string;
  reflection?: string;
};

export type BlindProvidedImage = {
  id: number;
  image: string;
  mask: string;
  overlay: string;
  trim: string;
};

export type RugProvidedImage = {
  id: number;
  image: string;
};

export type FloorProvidedImage = {
  id: number;
  image: string;
};

const defaultLoadedAssets = {
  visualiserIndex: null as null | number,
  room: null as null | RoomLoadedImage,
  blind: null as null | BlindLoadedImage,
  floor: null as null | FloorLoadedImage,
  rug: null as null | RugLoadedImage,
};
export type LoadedAssets = typeof defaultLoadedAssets;

type Args = {
  visualiserIndex: number | null;
  room: RoomProvidedImage | null;
  floor: FloorProvidedImage | null;
  blind: BlindProvidedImage | null;
  rug: RugProvidedImage | null;
};

export function useRoomviewAssetLoader(
  visData: VisualisationData,
  index: number
) {
  const dispatch = useDispatch();
  const activeLoad = useRef(0);
  const allRoomData = useSelector(photos).data;
  const { blindFilters } = useSelector(productData);
  const pData = useProductDataRetrieval(visData);
  const pixiLoader = useMemo(() => new PIXI.Loader(), []);
  const threeLoader = useMemo(() => new TextureLoader(), []);
  useEffect(() => {
    return () => {
      PIXI.utils.clearTextureCache();
      pixiLoader.destroy();
    };
  }, []);
  const [currentProcessing, setCurrentProcessing] = useState({
    args: {} as Args,
    data: {} as SelectedData,
  });
  const [loaded, setLoaded] = useState({
    assets: defaultLoadedAssets,
    data: {} as SelectedData,
  });

  useEffect(() => {
    if (visData.blind_id) {
      const blindProduct = getBlindProducts(
        visData.blind_id,
        pData.blindData ? [pData.blindData] : undefined
      );
      if (!blindProduct) {
        return; // requested blindData not loaded
      }
    }
    if (visData.floor_id) {
      const floorProduct = getFloorProducts(
        visData.floor_id,
        pData.floorData ? [pData.floorData] : undefined
      );
      if (!floorProduct) {
        return; // requested floorData not loaded
      }
    }
    if (visData.rug_id) {
      const rugProduct = getRugProducts(
        visData.rug_id,
        pData.rugData ? [pData.rugData] : undefined
      );
      if (!rugProduct) {
        return; // requested rugData not loaded
      }
    }
    const roomData = (allRoomData || []).find(
      (d) => d.id === visData.room_public_id
    );
    const selectedData = {
      ...pData,
      visData,
      roomData,
    };
    const loadRequest = generateLoadRequestData(
      index,
      selectedData,
      blindFilters.type[0]!
    );
    setCurrentProcessing({ args: loadRequest, data: selectedData });
  }, [JSON.stringify([visData, index, pData, allRoomData, blindFilters])]);

  useEffect(() => {
    const { args, data } = currentProcessing;
    if (!args.room) {
      // we need at least a room to start preloading
      return;
    }
    pixiLoader.reset();

    const loadId = activeLoad.current + 1;
    activeLoad.current = loadId;

    addNew(pixiLoader, args.room.image);
    if (args.room.mask) {
      addNew(pixiLoader, args.room.mask);
    }
    if (args.room.shadow_mask) {
      addNew(pixiLoader, args.room.shadow_mask);
    }
    if (args.room.reflection) {
      addNew(pixiLoader, args.room.reflection);
    }
    if (args.blind) {
      addNew(pixiLoader, args.blind.image); // From brand
      addNew(pixiLoader, args.blind.overlay); // from type
      addNew(pixiLoader, args.blind.trim); // from type
      addNew(pixiLoader, args.blind.mask); // from type
    }
    (async () => {
      const [
        pixiResources,
        floorTextureBrightness,
        floorTexture,
        rugTexture,
        rugRotateIcon,
      ] = await Promise.all([
        new Promise((res, rej) => {
          pixiLoader.load((loader, resources) => {
            res(resources);
          });
        }),
        args.floor
          ? getImageBrightnessFromFile(
              setDimensions(args.floor.image, { w: 200, h: 200 })
            )
          : null,
        args.floor
          ? loadThreeTexture(threeLoader, args.floor.image, 2048)
          : null,
        args.rug ? loadThreeTexture(threeLoader, args.rug!.image) : null,
        args.rug ? loadThreeTexture(threeLoader, rugRotateCircle) : null,
      ]);
      if (loadId !== activeLoad.current) {
        // if prop changes have occured before load finished
        return;
      }
      // Check both pixi and three have finished loading
      const loadedAssets = processLoadedItems(args, pixiResources, {
        floor: floorTexture,
        floorBrightness: floorTextureBrightness,
        rug: rugTexture,
        rugRotateIcon,
      });
      setLoaded({ assets: loadedAssets, data: data });
      dispatch(setProductLoadingState(false));
      dispatch(setRoomLoadingState(false));
    })();
  }, [JSON.stringify(currentProcessing.args)]);

  useEffect(() => {
    dispatch(setProductLoadingState(true));
  }, [visData.floor_id, visData.blind_id, visData.rug_id]);

  useEffect(() => {
    dispatch(setRoomLoadingState(true));
  }, [visData.room_public_id]);

  return loaded;
}

function addNew(pixiLoader: PIXI.Loader, url: string) {
  if (PIXI.utils.TextureCache[url]) return; // already in cache
  pixiLoader.add(url);
}

function processLoadedItems(
  props: Args,
  pixiResources: any,
  threeTexture: any
) {
  const { mask, reflection, image, shadow_mask } = props.room!;
  const room = {
    id: props.room!.id,
    mask: mask ? getPixiTexture(pixiResources, mask) : undefined,
    shadow_mask: shadow_mask
      ? getPixiTexture(pixiResources, shadow_mask)
      : undefined,
    image: getPixiTexture(pixiResources, image),
    reflection: reflection
      ? getPixiTexture(pixiResources, reflection)
      : undefined,
  };
  const blind = props.blind
    ? {
        id: props.blind.id,
        mask: getPixiTexture(pixiResources, props.blind.mask),
        image: getPixiTexture(pixiResources, props.blind.image),
        overlay: getPixiTexture(pixiResources, props.blind.overlay),
        trim: getPixiTexture(pixiResources, props.blind.trim),
      }
    : null;
  const floor = threeTexture.floor
    ? {
        id: props.floor!.id,
        image: threeTexture.floor,
        brightness: threeTexture.floorBrightness,
      }
    : null;
  const rug = threeTexture.rug
    ? {
        id: props.rug!.id,
        image: threeTexture.rug,
        rotateIcon: threeTexture.rugRotateIcon,
      }
    : null;
  return { room, blind, floor, rug, visualiserIndex: props.visualiserIndex };
}

function getPixiTexture(pixiResources: any, url: string): PIXI.Texture {
  if (PIXI.utils.TextureCache[url]) return PIXI.utils.TextureCache[url]; // use cache if exists
  return pixiResources[url].texture; // use newly loaded item if not in cache
}

function loadThreeTexture(
  threeLoader: TextureLoader,
  url: string,
  resize?: number
) {
  return new Promise((res) => {
    if (resize) url = setDimensions(url, { w: resize, h: resize });
    threeLoader.load(url, (texture) => {
      res(texture);
    });
  });
}

function useProductDataRetrieval(visData: VisualisationData) {
  const rugProductId = visData.rug_id;
  const floorProductId = visData.floor_id;
  const blindProductId = visData.blind_id;

  const { data: rugProductsRes } = useGetRugProductsQuery(
    {
      region: config.region,
      active: true,
      product_ids: [rugProductId ?? 0], // default rug
    },
    { skip: rugProductId === null }
  );

  const { data: floorProductsRes } = useGetFloorProductsQuery(
    {
      region: config.region,
      active: true,
      product_ids: [floorProductId ?? 0], // default floor
    },
    { skip: floorProductId === null }
  );

  const { data: blindProductsRes } = useGetBlindProductsQuery(
    {
      region: config.region,
      active: true,
      product_ids: [blindProductId ?? 0], // default floor
    },
    { skip: blindProductId === null }
  );

  const rugData: RugFilterResponse | undefined = rugProductsRes?.data
    ? rugProductsRes?.data[0]
    : undefined;
  const floorData: FloorFilterResponse | undefined = floorProductsRes?.data
    ? floorProductsRes?.data[0]
    : undefined;
  const blindData: BlindFilterResponse | undefined = blindProductsRes?.data
    ? blindProductsRes?.data[0]
    : undefined;
  return { rugData, floorData, blindData };
}
