import { useContext } from 'react';
import {
  Box,
  Flex,
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  Text,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react';
import { IoShareOutline } from 'react-icons/io5';
import { MdRotate90DegreesCcw } from 'react-icons/md';
import { batch, useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { FloorType } from 'shared/build/models/product';
import { RoutePaths } from '../../../App';
import Share from '../../Share/Share';
import { productData } from '../ProductData/productDataSlice';
import {
  removeBlinds,
  resetRugPosition,
  updateIndVisualisation,
  updateRug,
  VisualisationData,
} from '../productVisualisationSlice';
import { LoadedContext } from './Visualiser';
import { ProductView } from '../Products';
import SaveIcon from './SaveButton';
import { BsTrash } from 'react-icons/bs';
import { useAppSelector } from '../../../redux/hooks';
import { setShowBlindControls, setShowRugControls } from './visualiserSlice';
import { useDefaultRugPosition, useDefaultBlindPoints } from './helper';

type Props = {
  loadedVisualisation: VisualisationData;
  view: ProductView;
  orientation: 'default' | 'rotated';
};

const BottomOverlayButtons = ({
  loadedVisualisation,
  view,
  orientation,
}: Props) => {
  const dispatch = useDispatch();
  const loadedInd = useContext(LoadedContext);
  const {
    isOpen: isShareOpen,
    onOpen: onShareOpen,
    onClose: onShareClose,
  } = useDisclosure();

  const showRotateFlooring = useShowRotateFlooring(loadedVisualisation);

  return (
    <Flex
      bottom="10px"
      pos="absolute"
      w="100%"
      justifyContent="center"
      h="35px"
    >
      <HStack
        spacing={10}
        borderRadius="full"
        bg="rgba(226, 232, 240, 0.7)"
        _hover={{
          background: 'rgba(226, 232, 240, 1)',
        }}
      >
        <Tooltip hasArrow rounded="md" label="Share">
          <IconButton
            className="visualiser-btn" // id added for a document.querySelector inside visualiser
            size="lg"
            aria-label="share"
            icon={<IoShareOutline />}
            onClick={onShareOpen}
            variant="ghost"
            rounded="full"
            h="35px"
          />
        </Tooltip>
        <SaveIcon view={view} loadedVisualisation={loadedVisualisation} />
        {showRotateFlooring && (
          <Tooltip hasArrow rounded="md" label="Rotate Flooring">
            <IconButton
              className="visualiser-btn" // id added for a document.querySelector inside visualiser
              h="35px"
              size="lg"
              aria-label="rotate flooring"
              icon={<MdRotate90DegreesCcw />}
              onClick={() => {
                dispatch(
                  updateIndVisualisation({
                    ind: loadedInd!,
                    data: {
                      ...loadedVisualisation,
                      floor_config: {
                        orientation:
                          orientation === 'default' ? 'rotated' : 'default',
                      },
                    },
                  })
                );
              }}
              variant="ghost"
              rounded="full"
            />
          </Tooltip>
        )}
        <DeleteButtons />
      </HStack>
      <Modal size="xs" isOpen={isShareOpen} onClose={onShareClose}>
        <Share view={view} visualisations={[loadedVisualisation]} />
      </Modal>
    </Flex>
  );
};

export default BottomOverlayButtons;

function usePageMatch() {
  const isFlooring = useRouteMatch(RoutePaths.flooring);
  const isRugs = useRouteMatch(RoutePaths.rugs);
  const isBlinds = useRouteMatch(RoutePaths.blinds);
  if (isFlooring) return RoutePaths.flooring;
  if (isRugs) return RoutePaths.rugs;
  if (isBlinds) return RoutePaths.blinds;
  return undefined;
}

const DeleteButtons = () => {
  const dispatch = useDispatch();
  const loadedInd = useContext(LoadedContext);
  const vis = useAppSelector((state) => state.productVisualisation.data);
  const page = usePageMatch();
  const isRugControlsShowing = useAppSelector(
    (state) => state.visualiser.showRugControls
  );
  const isBlindControlsShowing = useAppSelector(
    (state) => state.visualiser.showBlindControls
  );

  const defaultRugPosition = useDefaultRugPosition();
  const defaultBlindPoints = useDefaultBlindPoints();

  if (loadedInd === null) return null;
  const loadedVis = vis[loadedInd];

  if (isRugControlsShowing) {
    return (
      <Tooltip hasArrow rounded="md" label="Remove Rug">
        <IconButton
          className="visualiser-btn" // id added for a document.querySelector inside rug visualiser
          h="35px"
          size="lg"
          aria-label="remove rug"
          icon={<BsTrash />}
          onClick={() => {
            batch(() => {
              dispatch(updateRug({ index: loadedInd, id: null }));
              dispatch(
                resetRugPosition({
                  index: loadedInd,
                  position: defaultRugPosition,
                })
              );
              dispatch(setShowRugControls(false));
            });
          }}
          variant="ghost"
          rounded="full"
        />
      </Tooltip>
    );
  }
  if (isBlindControlsShowing) {
    return (
      <Tooltip hasArrow rounded="md" label="Remove Blinds">
        <IconButton
          className="visualiser-btn" // id added for a document.querySelector inside rug visualiser
          size="lg"
          h="35px"
          aria-label="remove blind"
          icon={<BsTrash />}
          onClick={() => {
            batch(() => {
              dispatch(
                removeBlinds({
                  index: loadedInd,
                  defaultPoints: defaultBlindPoints,
                })
              );
              dispatch(setShowBlindControls(false));
            });
          }}
          variant="ghost"
          rounded="full"
        />
      </Tooltip>
    );
  }

  if (page === RoutePaths.blinds || page === RoutePaths.rugs) {
    return (
      <Menu>
        {/* @ts-ignore */}
        <MenuButton
          as={IconButton}
          className="visualiser-btn" // id added for a document.querySelector inside visualiser
          aria-label="remove"
          icon={<BsTrash />}
          variant="ghost"
          size="lg"
          h="35px"
          rounded="full"
        />
        <MenuList>
          <MenuItem
            isDisabled={loadedVis.rug_id === null}
            fontWeight="400"
            icon={<BsTrash />}
            onClick={() => {
              batch(() => {
                dispatch(updateRug({ index: loadedInd, id: null }));
                dispatch(resetRugPosition({ index: loadedInd }));
                dispatch(setShowRugControls(false));
              });
            }}
          >
            Remove Rug
          </MenuItem>
          <MenuItem
            isDisabled={loadedVis.blind_id === null}
            fontWeight="400"
            icon={<BsTrash />}
            onClick={() => {
              if (loadedInd !== null) {
                batch(() => {
                  dispatch(
                    removeBlinds({
                      index: loadedInd,
                      defaultPoints: defaultBlindPoints,
                    })
                  );
                  dispatch(setShowBlindControls(false));
                });
              }
            }}
          >
            Remove Blinds
          </MenuItem>
        </MenuList>
      </Menu>
    );
  }
  return null;
};

const useShowRotateFlooring = (loadedVisualisation: VisualisationData) => {
  const { floorData } = useSelector(productData);

  const floorBrand = floorData.find((floorBrand) => {
    const matchingFloor = floorBrand.products.find(
      (product) => product.id === loadedVisualisation.floor_id
    );
    return matchingFloor;
  });
  const hasFlooring = loadedVisualisation.floor_id !== null;
  const isCarpet = floorBrand?.brand_category.type === FloorType.carpet;
  const showRotateFlooring = hasFlooring && !isCarpet;
  return showRotateFlooring;
};

export const RotateButton = ({
  loadedVisualisation,
  orientation,
}: {
  loadedVisualisation: VisualisationData;
  orientation: 'default' | 'rotated';
}) => {
  const dispatch = useDispatch();
  const loadedInd = useContext(LoadedContext);

  const showRotateFlooring = useShowRotateFlooring(loadedVisualisation);

  return (
    <>
      {showRotateFlooring && (
        <Box
          onClick={() => {
            dispatch(
              updateIndVisualisation({
                ind: loadedInd!,
                data: {
                  ...loadedVisualisation,
                  floor_config: {
                    orientation:
                      orientation === 'default' ? 'rotated' : 'default',
                  },
                },
              })
            );
          }}
          bg="white"
          rounded="full"
          borderWidth={1}
          padding={1}
          borderColor="#636363"
          fontSize="sm"
          display="flex"
          justifyContent="center"
          alignItems="center"
          cursor="pointer"
          w="40px"
          h="40px"
          pos="absolute"
          margin="-7.5px 0"
        >
          <MdRotate90DegreesCcw
            display="inline"
            size="1.5rem"
            color="#636363"
          />
        </Box>
      )}
    </>
  );
};
