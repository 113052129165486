import { Button, Flex, Text } from '@chakra-ui/react';
import { useAppDispatch } from '../../redux/hooks';
import { useHistory } from 'react-router-dom';
import { paginate } from '../workflowSlice';
import RoomviewLogo from './roomview-logo.svg';
import { RoutePaths } from '../../App';

const TypeSelection = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  return (
    <Flex
      id="type-selection"
      flexDirection="column"
      gridGap={3}
      p={'20px'}
      justifyContent="center"
      alignItems="center"
    >
      <img src={RoomviewLogo} width={80} />
      <Text textAlign="center">What would you like to add to your room?</Text>
      <Button
        background={'#0066AB'}
        rounded="full"
        width="full"
        color="white"
        onClick={() => dispatch(paginate(1))}
        fontWeight="normal"
      >
        Flooring
      </Button>
      <Button
        background={'#0066AB'}
        rounded="full"
        width="full"
        color="white"
        onClick={() =>
          history.push({ pathname: RoutePaths.photos, search: '?product=rugs' })
        }
        fontWeight="normal"
      >
        Rugs
      </Button>
      <Button
        background={'#0066AB'}
        rounded="full"
        width="full"
        color="white"
        onClick={() =>
          history.push({
            pathname: RoutePaths.photos,
            search: '?product=blinds',
          })
        }
        fontWeight="normal"
      >
        Window Furnishings
      </Button>
    </Flex>
  );
};

export default TypeSelection;
