import {
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  Box,
  Heading,
  Text,
  ModalHeader,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import QRCode from 'react-qr-code';
import { ErrorMessage } from '../../UI/ErrorMessage';
import { Loading } from '../../UI/Loading';
import { shareVisualisations } from '../../utils/api';
import { ProductView } from '../Products/Products';
import { VisualisationData } from '../Products/productVisualisationSlice';
import { createShareUrl } from '../Share/helpers';

type Props = {
  view: ProductView;
  visualisations: VisualisationData[];
};

enum Status {
  loading = 'loading',
  error = 'error',
  ready = 'ready',
}

const ShareInspirationStation = ({ view, visualisations }: Props) => {
  const [status, setStatus] = useState(Status.loading);
  const [shareUrl, setShareUrl] = useState('');

  async function generateShareUrl() {
    try {
      const shareId = await shareVisualisations(visualisations);
      const url = createShareUrl(shareId);
      setShareUrl(`${url}&modal=true`);
      setStatus(Status.ready);
    } catch (e) {
      console.error(e);
      setStatus(Status.error);
    }
  }

  useEffect(() => {
    generateShareUrl();
  }, [view, JSON.stringify(visualisations)]);

  return (
    <>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <ModalCloseButton />
        </ModalHeader>
        <ModalBody p="8">
          <Box mb="12">
            {status === Status.error && (
              <ErrorMessage error="There was an error sharing your rooms" />
            )}
            {status === Status.loading && <Loading />}
            {status === Status.ready && (
              // @ts-ignore
              <QRCode value={shareUrl} />
            )}
          </Box>
          <Heading as="h2" size="sm" color="brand.blue">
            Share and Save
          </Heading>
          <Text color="brand.blue">
            Scan this QR code with your phones camera to share or save your
            selection.
          </Text>
        </ModalBody>
      </ModalContent>
    </>
  );
};

export default ShareInspirationStation;
