export function getImageBrightnessFromFile(url: string) {
  return new Promise((res, rej) => {
    try {
      const imageElem = document.createElement('img');
      imageElem.crossOrigin = 'anonymous'; // this makes a cors request without sending credentials
      imageElem.src = url;
      imageElem.onload = () => {
        try {
          const rgb = getAverageRGBOfImageElem(imageElem);
          const brightness = rgbToLuminance(
            rgb.r / 255,
            rgb.g / 255,
            rgb.b / 255
          );
          res(brightness);
        } catch (e) {
          console.error(e);
          res(0.5);
        }
      };
      imageElem.onerror = (e) => {
        console.error(e);
        // if network error, just set brightness to half rather than preventing displaying image
        res(0.5);
      };
    } catch (err) {
      console.error(err);
      res(0.5);
    }
  });
}

// http://jsfiddle.net/xLF38/818/
function getAverageRGBOfImageElem(imgEl: HTMLImageElement) {
  const blockSize = 5; // only visit every 5 pixels
  const defaultRGB = { r: 0, g: 0, b: 0 }; // for non-supporting envs
  const canvas = document.createElement('canvas');
  const context = canvas.getContext && canvas.getContext('2d');
  let data;
  let width;
  let height;
  let i = -4;
  let length;
  const rgb = { r: 0, g: 0, b: 0 };
  let count = 0;

  if (!context) {
    return defaultRGB;
  }

  height = canvas.height =
    imgEl.naturalHeight || imgEl.offsetHeight || imgEl.height;
  width = canvas.width = imgEl.naturalWidth || imgEl.offsetWidth || imgEl.width;

  context.drawImage(imgEl, 0, 0);

  data = context.getImageData(0, 0, width, height);

  length = data.data.length;

  while ((i += blockSize * 4) < length) {
    ++count;
    rgb.r += data.data[i];
    rgb.g += data.data[i + 1];
    rgb.b += data.data[i + 2];
  }

  // ~~ used to floor values
  rgb.r = ~~(rgb.r / count);
  rgb.g = ~~(rgb.g / count);
  rgb.b = ~~(rgb.b / count);

  return rgb;
}

function rgbToLuminance(r: number, g: number, b: number) {
  return 0.2126 * r + 0.7152 * g + 0.0722 * b;
}
