import { Box, Flex } from '@chakra-ui/react';
import FlooringSelection from './FlooringSelection';
import TypeSelection from './TypeSelection';
import { AnimatePresence, motion, Variants } from 'framer-motion';
import PhotosNew from '../Photos/PhotosNew';

import { paginate } from '../workflowSlice';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { RoutePaths } from '../../App';
import { Redirect } from 'react-router-dom';

export default function Onboarding() {
  const dispatch = useAppDispatch();
  const [page, direction] = useAppSelector((state) => state.workflow.pageData);

  return (
    <AnimatePresence initial={false}>
      <motion.div
        id="onboarding"
        style={{
          position: 'absolute', // use absolute positioning so that on exit animation we do not get shoved out of position
          height: '100%',
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: `${page != 3 ? 'center' : 'unset'}`, // only vertically center the first two pages, the photo page is aligned to the top
        }}
        key={page}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.5, ease: 'easeInOut' }}
      >
        {page === 1 && (
          <div key={1}>
            <div
              style={{
                backgroundColor: 'white',
                width: '300px',
                borderRadius: 'var(--chakra-radii-xl)',
                height: 'fit-content',
              }}
            >
              <TypeSelection />
            </div>
          </div>
        )}

        {page === 2 && (
          <div key={2}>
            <Box
              maxWidth="300px"
              minWidth="300px"
              rounded="lg"
              bgColor="white"
              p="2"
            >
              <FlooringSelection />
            </Box>
          </div>
        )}

        {page === 3 && (
          <div
            key={3}
            style={{
              width: '400px',
              maxWidth: '75%',
            }}
          >
            <PhotosNew />
          </div>
        )}

        {/*
          Issuing 2 commands here, so this may be some Javascript magicks you haven't seen
          before.  We need to reset the page back to the previous value because we trigger a
          history push.  This way if the user clicks the back button then we go to step 3 of
          the workflow, otherwise we hit this logic again and get stuck in a loop
        */}
        {page === 4 &&
          (dispatch(paginate(-1)), (<Redirect to={RoutePaths.flooring} />))}
      </motion.div>
    </AnimatePresence>
  );
}
