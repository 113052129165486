import {
  useToast,
  ModalContent,
  ModalHeader,
  HStack,
  Icon,
  ModalCloseButton,
  Divider,
  ModalBody,
  Box,
  ModalFooter,
  Text,
} from '@chakra-ui/react';
import { useState } from 'react';
import { FaCheck } from 'react-icons/fa';
import { FiLink } from 'react-icons/fi';
import { RegButton } from '../../UI/Buttons';
import * as clipboard from 'clipboard-polyfill/text'; // polyfill for ios 12
import { ErrorMessage } from '../../UI/ErrorMessage';

const CopyLink = ({ shareUrl }: { shareUrl: string }) => {
  const [isCopied, setIsCopied] = useState(false);
  const [error, setError] = useState<null | string>(null);
  const toast = useToast();
  async function handleCopy() {
    try {
      await clipboard.writeText(shareUrl);
      toast({
        title: 'Link copied to clipboard',
        status: 'success',
        isClosable: true,
      });
      setIsCopied(true);
    } catch (err) {
      console.error(err);
      setError(
        `Copying is not supported on your device. You can manually select the link and copy.`
      );
    }
  }

  return (
    <ModalContent>
      <ModalHeader>
        <HStack>
          <Icon as={FiLink} />
          <Text fontWeight={400} fontSize="md">
            Copy Link
          </Text>
        </HStack>
      </ModalHeader>
      <ModalCloseButton />
      <Divider />
      <ModalBody>
        <Text pb={3}>Copy the link to share your photos</Text>
        <Box border="1px solid" borderColor="gray.400" borderRadius="md" p={3}>
          {shareUrl}
        </Box>
      </ModalBody>

      <ModalFooter justifyContent="center" flexDir="column">
        <RegButton
          primary
          onClick={handleCopy}
          leftIcon={isCopied ? <FaCheck /> : <FiLink />}
          disabled={isCopied}
        >
          {isCopied ? 'Copied' : 'Copy'}
        </RegButton>
        {error && (
          <Box mt={2}>
            <ErrorMessage error={error} />
          </Box>
        )}
      </ModalFooter>
    </ModalContent>
  );
};
export default CopyLink;
