import { applyDefaultProps, PixiComponent } from '@inlet/react-pixi';
import { Container2d as Container2dComp } from 'pixi-projection';

export const Container2d = PixiComponent('Container2d', {
  create: ({}) => {
    return new Container2dComp();
  },
  applyProps: (instance, oldProps, newProps) => {
    // const { count, ...oldP } = oldProps
    // const { count, ...newP } = newProps

    // apply rest props to PIXI.Text
    applyDefaultProps(instance, oldProps, newProps);

    // set new count
    // instance.text = count.toString()
  },
});
