import { Suspense, useContext } from 'react';

// components
import Flooring from './Flooring';

import CameraSetup, { CAMERA_HEIGHT } from './CameraSetup';
import Rug from './Rug';
import { RoomviewCanvas } from './CustomThreeCanvas';
import { useDispatch, useSelector } from 'react-redux';
import { FloorLoadedImage, RugLoadedImage } from './AssetLoader';
import {
  productVisualisation,
  updateIndVisualisation,
} from '../../productVisualisationSlice';
import { LoadedContext } from '../Visualiser';
import { RugProductDb } from 'shared/build/models/product';
import { useAppSelector } from '../../../../redux/hooks';
import { setRightclick } from '../../../../redux/uiSlice';

export function ThreeDScene(props: {
  floor: FloorLoadedImage | null;
  camera: { pitch: number; roll: number; fovy: number };
  rug: RugLoadedImage | null;
  rugData: RugProductDb | null;
  width: number;
  height: number;
  rotation: number;
  floorIsTextured: boolean;
  rugIsTextured: boolean;
}) {
  // To understand reasoning behind props dispatch passing below:
  // https://github.com/reduxjs/react-redux/issues/355#issuecomment-896504179
  const dispatch = useDispatch();
  const loadedInd = useContext(LoadedContext);
  const vis = useSelector(productVisualisation).data[loadedInd!];
  function onCompleted(pos: { x: number; z: number }, rotation: number) {
    dispatch(
      updateIndVisualisation({
        ind: loadedInd!,
        data: {
          ...vis,
          rug_config: {
            ...vis.rug_config,
            position: pos,
            rotation,
          },
        },
      })
    );
  }
  const showRugControls = useAppSelector(
    (state) => state.visualiser.showRugControls
  );
  return (
    <Suspense fallback={null}>
      <RoomviewCanvas
        id={'three-canvas'}
        handleRightClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          const [x, y] = [e.clientX, e.clientY];
          dispatch(setRightclick({ visible: true, x, y }));
        }}
        gl={{
          antialias: false,
          precision: 'highp',
          preserveDrawingBuffer: true,
        }}
        linear={true}
        size={{ width: props.width, height: props.height }}
        style={{
          position: 'absolute',
          top: '0px',
          left: '0px',
          opacity: 0, // Because the contents of the canvas are rendered through pixi, we don't want this to be visible.
        }}
        camera={{
          near: 0.1,
          far: 500,
          scale: [1, 1, 1],
          position: [0, CAMERA_HEIGHT, 0],
        }}
      >
        <CameraSetup
          uprightData={{
            ...props.camera,
            width: props.width,
            height: props.height,
          }}
        >
          <ambientLight />
          <Flooring
            rotation={props.rotation}
            texture={props.floor ? props.floor.image : null}
            floorIsTextured={props.floorIsTextured}
          />
          {props.rug && props.rugData && (
            <Rug
              rug={props.rugData}
              rugIsTextured={props.rugIsTextured}
              onCompleted={onCompleted}
              rotation={vis.rug_config?.rotation || 0}
              position={vis.rug_config?.position || { x: 0, z: -2.5 }}
              texture={props.rug.image}
              rotateTexture={props.rug.rotateIcon}
              dispatch={dispatch}
              showControls={showRugControls}
            />
          )}
        </CameraSetup>
      </RoomviewCanvas>
    </Suspense>
  );
}

export function threeCanv() {
  return document.getElementById('three-canvas')! as HTMLCanvasElement;
}
