import { Product } from 'shared/build/models/product';
import { HStack, Text } from '@chakra-ui/react';
import { priceFormat } from '../ProductList/ChangeSize';

export const ProductPrice = ({ product }: { product: Product }) => {
  if (product.compare_price) {
    return (
      <>
        <HStack spacing="1">
          <Text color="#2B6CB0" fontWeight="bold" as="s">
            {priceFormat(product.compare_price)}
          </Text>
          <Text fontWeight="light">Now:</Text>
          <Text color="red.600" fontWeight="bold">
            {priceFormat(product.price)}
          </Text>
        </HStack>
      </>
    );
  } else {
    return (
      <Text color="#2B6CB0" fontWeight="bold">
        {priceFormat(product.price)}
      </Text>
    );
  }
};
