import axios from '../axios';
import { omit } from 'lodash';
import { RoomApi } from 'shared/build/models/room';
import { CreateRes } from 'shared/build/models/shared';
import { VisualisationData } from '../Components/Products/productVisualisationSlice';
import { config } from '../config';
import { hasLocalStorageAccess } from './localStorage';

axios.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    if (error.response?.data?.message === 'TokenExpiredError') {
      if (hasLocalStorageAccess()) {
        localStorage.removeItem('roomview_token');
      }
      window.location.reload();
    }
    return Promise.reject(error);
  }
);

export async function deleteRoomApi(id: string) {
  await axios.delete('/api/v1/room/single/' + id);
  return true;
}

export async function upload(file: Blob, session?: string) {
  const requestConfig = session
    ? {
        transformRequest: [
          (data: any, headers: any) => {
            headers.common.Authorization = `Bearer ${session}`;
            return data;
          },
        ],
      }
    : {};
  const data = new FormData();
  data.append('image', file!);
  const uploadedData: RoomApi = (
    await axios.post('/api/v1/room/upload', data, requestConfig)
  ).data.data;
  return uploadedData;
}

export async function shareVisualisations(visualisations: VisualisationData[]) {
  const removeSaveData = visualisations.map((v) => {
    return omit(v, ['id', 'thumbnail_url', 'watermark_url']);
  });
  const res: CreateRes = (
    await axios.post('/api/v1/shared/new', { shared_items: removeSaveData })
  ).data.data;
  const shareId = res.id;
  return shareId;
}

export async function deleteRooms() {
  await axios.delete(`/api/v1/room/session`);
  return true;
}

export async function deleteSavedItems() {
  await axios.delete(`/api/v1/saved/session`);
  return true;
}

export async function deleteSavedApi(id: string) {
  await axios.delete('/api/v1/saved/single/' + id);
  return true;
}
