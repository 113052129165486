import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Product, RugFilterResponse } from 'shared/build/models/product';
import { productData, setShowRugFilters } from './productDataSlice';
import { FixedSizeList as List } from 'react-window';
import Row from '../ProductList/Row';
import InfiniteLoader from 'react-window-infinite-loader';
import AutoSizer from 'react-virtualized-auto-sizer';
import { Box, Center, Text } from '@chakra-ui/react';
import { sortRugsBySize } from '../ProductList/ChangeSize';

type Props = {
  hasNextPage: boolean;
  loadNextPage: () => void;
  handleProductClick: (id: number) => void;
  filteredData: RugFilterResponse[];
};

export function RugProductListMobile({
  hasNextPage,
  loadNextPage,
  handleProductClick,
  filteredData,
}: Props) {
  const dispatch = useDispatch();
  const { rugFilters } = useSelector(productData);
  const listRef = useRef<any>(null);

  useEffect(() => {
    listRef?.current?.scrollToItem(0);
  }, [rugFilters]);

  function dispatchSelectProduct(product: Product) {
    dispatch(setShowRugFilters(false));
    handleProductClick(product.id);
  }

  if (filteredData.length === 0) {
    return (
      <Center m="2">
        <Text>No products found</Text>
      </Center>
    );
  }

  const isItemLoaded = (index: number) => {
    return !hasNextPage || index < filteredData.length;
  };

  const itemCount = hasNextPage ? filteredData.length + 1 : filteredData.length;

  const rugDataOneSize = filteredData.map((rug) => {
    const sortedRugs = sortRugsBySize([...rug.products]);
    const smallestRug = sortedRugs.slice(0, 1);
    return {
      ...rug,
      products: smallestRug,
    };
  });

  return (
    <Box flex={1} minHeight={{ base: '350px', lg: '0px' }}>
      <InfiniteLoader
        isItemLoaded={isItemLoaded}
        itemCount={itemCount}
        loadMoreItems={loadNextPage}
        threshold={5}
      >
        {({ onItemsRendered, ref }) => (
          <AutoSizer>
            {({ height, width }) => (
              <List
                ref={(list) => {
                  // @ts-ignore - https://github.com/bvaughn/react-window/issues/324#issuecomment-528887341
                  ref(list);
                  listRef.current = list;
                }}
                onItemsRendered={onItemsRendered}
                itemData={{
                  elems: rugDataOneSize,
                  dispatchSelectProduct,
                  isItemLoaded,
                }}
                itemCount={itemCount}
                itemSize={106}
                height={height}
                width={width}
              >
                {Row}
              </List>
            )}
          </AutoSizer>
        )}
      </InfiniteLoader>
    </Box>
  );
}
