import { Modal, useDisclosure } from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';
import { useAppSelector } from '../../redux/hooks';
import { productView } from '../Products/Products';
import Share from '../Share/Share';

const ShareLandingModal = () => {
  const location = useLocation();
  const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });
  const visualisations = useAppSelector(
    (state) => state.productVisualisation.data
  );
  const view = productView(location.pathname);

  return (
    <Modal size="xs" isOpen={isOpen} onClose={onClose}>
      <Share view={view ?? undefined} visualisations={visualisations} />
    </Modal>
  );
};

export default ShareLandingModal;
