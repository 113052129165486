export function getResizedImage(src: string, width: number): Promise<Blob> {
  return new Promise((res, rej) => {
    let img = document.createElement('img');
    img.onload = function () {
      let canv = document.createElement('canvas');
      let ctx = canv.getContext('2d')!;

      canv.width = width; // destination canvas size
      canv.height = (canv.width * img.height) / img.width;

      ctx.drawImage(img, 0, 0, canv.width, canv.height);
      canv.toBlob((b) => {
        if (!b) {
          rej('Failed to resize image.');
          return;
        }
        res(b);
      }, 'image/jpeg');
    };
    img.onerror = function () {
      rej('Failed to load image');
    };
    img.src = src;
  });
}
