import { extendTheme } from '@chakra-ui/react';
import { createBreakpoints } from '@chakra-ui/theme-tools';

// 2. Extend the theme to include custom colors, fonts, etc
const colors = {
  brand: {
    blue: '#2B6CB0',
  },
};
const styles = {
  global: {
    body: {
      fontFamily: 'Sofia, sans-serif',
    },
  },
};
const components = {
  Container: {
    baseStyle: {
      maxW: 'none',
    },
  },
};
const breakpoints = createBreakpoints({
  sm: '42em',
  md: '48em',
  lg: '62em',
  xl: '80em',
  '2xl': '96em',

  // Used for photos breakpoints
  photo1: '0em',
  photo2: '16em',
  photo3: '28em',
  photo4: '41em',
  photo5: '50em',
});

export const roomviewTheme = extendTheme({
  colors,
  styles,
  components,
  breakpoints,
});
