import {
  VStack,
  Text,
  Icon,
  useDisclosure,
  Modal,
  ModalHeader,
  Divider,
  ModalBody,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  Flex,
  Center,
} from '@chakra-ui/react';
import { FiCamera } from 'react-icons/fi';
import { MdNavigateNext } from 'react-icons/md';
import QRCode from 'react-qr-code';
import { RegButton, SquareButton } from '../../UI/Buttons';
import { createUploadLink } from './helpers';

const QRUpload = () => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const link = createUploadLink(true);

  return (
    <>
      <SquareButton style={{ width: '100%' }} onClick={onOpen}>
        <VStack>
          <Icon color="gray.600" as={FiCamera} size={10} w={12} h={12} />
          <Text color="gray.600" fontSize={15}>
            Add Photos
          </Text>
        </VStack>
      </SquareButton>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent maxW="335px">
          <ModalHeader>Upload Photo</ModalHeader>
          <ModalCloseButton />
          <Divider />
          <ModalBody>
            <Flex flexDirection="column" p="4">
              <Center>
                {/* @ts-ignore */}
                <QRCode value={link} />
              </Center>
              <Text color="brand.blue" mt="4">
                Simply scan the QR code above using the camera on your phone and
                follow the simple instructions to upload a photo.
              </Text>
              <RegButton
                primary
                rightIcon={<MdNavigateNext size="1.5em" />}
                mt="5"
                onClick={onClose}
              >
                Continue to RoomView
              </RegButton>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default QRUpload;
