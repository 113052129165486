import {
  Box,
  Center,
  HStack,
  Icon,
  Spacer,
  Text,
  VStack,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { FiCamera } from 'react-icons/fi';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../redux/hooks';
import { SquareButton } from '../../UI/Buttons';
import { Loading } from '../../UI/Loading';
import { upload } from '../../utils/api';
import QRUpload from '../InspirationStation/QRUpload';
import { addNewRoom } from './photosSlice';
import { getResizedImage } from './resize';
import { ReactComponent as UploadIcon } from './uploadIcon.svg';
import { AnimatePresence, motion } from 'framer-motion';
import { Player } from '@lottiefiles/react-lottie-player';
import choicesLoadingSpinnerData from './choicesLoading.json';
import { SecondaryHeader } from '../../UI/Text';
import Swal from 'sweetalert2';
import disclaimerImage from './roomview-disclaimer-image.png';
import './style.css';
import { hasLocalStorageAccess } from '../../utils/localStorage';

export type UploadFile = {
  blob: File;
  preview: string;
};

export const useUpload = (session?: string) => {
  const [files, setFiles] = useState([] as UploadFile[]);
  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/jpeg, image/png',
    maxFiles: 50,
    maxSize: 1024 * 1024 * 8, // 8mb max image size
    onDrop: (acceptedFiles) => {
      setFiles([
        ...acceptedFiles.map((f: any) => ({
          blob: f,
          preview: URL.createObjectURL(f),
        })),
        ...files,
      ]);
    },
  });

  const uploadImage = async (image: string) => {
    const file = await getResizedImage(image, 1280);
    return await upload(file, session);
  };

  return { files, setFiles, getRootProps, getInputProps, uploadImage };
};

function AddPhotosBtn() {
  const dispatch = useDispatch();
  const isInspirationStation = useAppSelector(
    (state) => state.ui.isInspirationStation
  );
  const { files, setFiles, getRootProps, getInputProps, uploadImage } =
    useUpload();
  const addPhoto = async (image: string) => {
    const res = await uploadImage(image);
    dispatch(addNewRoom(res));
    setFiles(files.slice(0, -1));
  };

  if (isInspirationStation) {
    return <QRUpload />;
  }
  return (
    <Box>
      <SquareButton>
        <div {...getRootProps({ className: 'dropzone' })}>
          <input {...getInputProps()} />
          <VStack>
            <Icon color="gray.600" as={FiCamera} size={10} w={12} h={12} />
            <Text color="gray.600" fontSize={15}>
              Upload Your Room
            </Text>
          </VStack>
        </div>
      </SquareButton>
      {files.map((file, i) => (
        <UploadImage
          key={file.preview}
          preview={file.preview}
          addPhoto={addPhoto}
          upload={i === files.length - 1}
        />
      ))}
    </Box>
  );
}

export default AddPhotosBtn;

const disclaimerConfirmedKey = 'disclaimer_confirmed';

export function AddPhotosBtnNew() {
  const dispatch = useDispatch();
  const isInspirationStation = useAppSelector(
    (state) => state.ui.isInspirationStation
  );
  const [uploadConfirmed, setUploadConfirmed] = useState(false);

  useEffect(() => {
    if (hasLocalStorageAccess()) {
      const confirmed = localStorage.getItem(disclaimerConfirmedKey);
      if (confirmed === 'true') {
        setUploadConfirmed(true);
      }
    }
  }, []);

  const { files, setFiles, getRootProps, getInputProps, uploadImage } =
    useUpload();

  const addPhoto = async (image: string) => {
    const res = await uploadImage(image);
    dispatch(addNewRoom(res));
    setFiles(files.slice(0, -1));
  };

  if (isInspirationStation) {
    return <QRUpload />;
  }

  const handleClick = async (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    if (uploadConfirmed) {
      return;
    }
    const { target, nativeEvent } = event;
    const clonedNativeEvent = new MouseEvent('click', nativeEvent);
    event.stopPropagation();
    event.preventDefault();

    const confirm = await Swal.fire({
      text: 'For optimal results, ensure that your room has ample lighting, minimal furniture, and plenty of open floor space.',
      confirmButtonText: 'Proceed',
      cancelButtonText: 'Previous',
      showCancelButton: true,
      reverseButtons: true,
      confirmButtonColor: '#6CB33E',
      imageUrl: disclaimerImage,
      width: 400,
      animation: false,
      cancelButtonColor: 'white',
    });

    if (confirm.isConfirmed) {
      setUploadConfirmed(true);
      if (hasLocalStorageAccess()) {
        localStorage.setItem(disclaimerConfirmedKey, 'true');
      }
      target.dispatchEvent(clonedNativeEvent);
    }
  };
  return (
    <>
      <Box
        as="section"
        backgroundColor="#f1f1f1"
        w="full"
        p="4"
        rounded="2xl"
        cursor="pointer"
      >
        <div
          {...getRootProps({
            className: 'dropzone',
            onClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
              handleClick(event);
            },
          })}
        >
          <input {...getInputProps()} />
          <Center>
            <HStack spacing={4} paddingX={8} paddingY={4}>
              <Box flex={1}>
                <UploadIcon />
              </Box>
              <Box flex={2} color="gray.600">
                Upload your room photos
              </Box>
            </HStack>
          </Center>
        </div>
      </Box>
      {files.length > 0 && (
        <Box
          as="section"
          backgroundColor="#f1f1f1"
          w="full"
          p="4"
          rounded="2xl"
          display="flex"
          flexDirection="column"
          justifyContent="center"
        >
          <SecondaryHeader>Loading photos</SecondaryHeader>
          <Spacer marginTop="4" />
          <Player
            autoplay={true}
            loop={true}
            src={choicesLoadingSpinnerData}
            style={{ height: '80px', width: '80px' }}
          />
          <AnimatePresence>
            {files.map((file, i) => (
              <motion.div
                key={file.preview}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
              >
                <UploadImageHidden
                  key={file.preview}
                  preview={file.preview}
                  addPhoto={addPhoto}
                  upload={i === files.length - 1}
                />
              </motion.div>
            ))}
          </AnimatePresence>
        </Box>
      )}
    </>
  );
}

export function UploadImageHidden(props: {
  preview: string;
  upload: boolean;
  addPhoto: (image: string) => void;
}) {
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (props.upload) {
      (async () => {
        try {
          setLoading(true);
          await props.addPhoto(props.preview);
          setLoading(false);
        } catch (e: any) {
          window.alert(e.message);
          setLoading(false);
        }
      })();
    }
  }, [props.upload]);
  return (
    <SquareButton
      display="none"
      bg={`url(${props.preview})`}
      bgSize="cover"
      bgPosition="center"
    >
      {loading && <Loading />}
    </SquareButton>
  );
}

export function UploadImage(props: {
  preview: string;
  upload: boolean;
  addPhoto: (image: string) => void;
}) {
  const [loading, setLoading] = useState(false);
  setLoading(true);
  useEffect(() => {
    if (props.upload) {
      (async () => {
        try {
          setLoading(true);
          await props.addPhoto(props.preview);
          setLoading(false);
        } catch (e: any) {
          window.alert(e.message);
          setLoading(false);
        }
      })();
    }
  }, [props.upload]);
  return (
    <SquareButton
      bg={`url(${props.preview})`}
      bgSize="cover"
      bgPosition="center"
    >
      {loading && <Loading />}
    </SquareButton>
  );
}
