import { Box, Code, Tag, Flex, IconButton } from '@chakra-ui/react';
import React, { useState } from 'react';

const HiddenSupportInfo = () => {
  const token = localStorage.getItem('roomview_token');
  let text = 'No Token Found';
  if (token) {
    const parsedToken = JSON.parse(token);
    text = parsedToken.access_token;
  }

  return (
    <Box h="100vh" w="100%" p="10">
      <Flex>
        <Tag mb="2">{`Token`}</Tag>
      </Flex>
      <Code maxW="100%">{text}</Code>
    </Box>
  );
};

export default HiddenSupportInfo;
