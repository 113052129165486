import { Spinner, SpinnerProps } from '@chakra-ui/react';
import { useState } from 'react';
import { useEffect } from 'react';

// https://reactjs.org/docs/concurrent-mode-patterns.html#delaying-a-pending-indicator
// delay loading indicator as this can actually make things appear slower than they are
// we handle the actual delay logic in the transition instead of the set timeout so that
// any cpu blocking does not also prevent the loading spinner from showing
export function Loading(props: SpinnerProps & { visibleDelay?: number }) {
  const [visible, setVisible] = useState(false);
  useEffect(() => {
    const timeout = setTimeout(() => setVisible(true));
    return () => clearTimeout(timeout);
  }, []);
  return (
    <Spinner
      transition={`0.3s opacity ease ${props.visibleDelay ?? '0.4s'}`}
      thickness="4px"
      speed="0.65s"
      emptyColor="gray.200"
      color="brand.blue"
      opacity={visible ? 1 : 0.01}
      size="xl"
      {...(props as any)}
    />
  );
}
