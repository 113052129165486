import { Flex } from '@chakra-ui/react';
import { RoutePaths } from '../../App';
import { LinkButton } from '../../UI/Buttons';
import { RiHeartFill, RiCameraFill } from 'react-icons/ri';
import { useLocation } from 'react-router';
import ProductNav from './ProductNav';
import { useAppDispatch } from '../../redux/hooks';
import { paginate } from '../workflowSlice';

export default function Nav() {
  const location = useLocation();
  const dispatch = useAppDispatch();

  return (
    <Flex zIndex={100}>
      <Flex h="35px" spacing={4} justify="center" flexWrap="wrap">
        <LinkButton
          key={RoutePaths.photos}
          to={RoutePaths.photos}
          leftIcon={<RiCameraFill />}
          primary={location.pathname === RoutePaths.photos}
          fontSize={{ base: 'sm', sm: 'md' }}
          textMt="0"
          onClick={() => {
            dispatch(paginate(-1));
          }}
        >
          Photos
        </LinkButton>
        <ProductNav />
        <LinkButton
          key={RoutePaths.saved}
          to={RoutePaths.saved}
          leftIcon={<RiHeartFill />}
          primary={location.pathname === RoutePaths.saved}
          fontSize={{ base: 'sm', sm: 'md' }}
          textMt="0"
          onClick={() => {
            dispatch(paginate(-1));
          }}
        >
          Saved
        </LinkButton>
      </Flex>
    </Flex>
  );
}
