import {
  Box,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { SecondaryHeader } from '../../../UI/Text';
import { sortRugsBySize } from '../ProductList/ChangeSize';
import {
  productData,
  setShowRugFilters,
} from '../ProductData/productDataSlice';
import {
  productVisualisation,
  updateIndVisualisation,
} from '../productVisualisationSlice';
import ProductScroll from '../ProductList/ProductScroll';
import { getIndData } from '../Visualiser/helper';
import { RugProductFilters } from '../ProductData/RugFilters';
import { RugProductList } from '../ProductData/RugProductList';
import RugSelectedHeading from './RugSelectedHeading';
import { RegButton } from '../../../UI/Buttons';
import {
  IframeSendTypes,
  sendIframeMessage,
} from '../../../utils/iframe-communication';
import { RugFilterResponse } from 'shared/build/models/product';
import { trackEvent } from '../../../utils/tracking';
import { useAppSelector } from '../../../redux/hooks';
import { ArrowBackIcon } from '@chakra-ui/icons';
import {
  RugProductFiltersMobile,
  RugSearch,
} from '../ProductData/RugFiltersMobile';
import { RugProductListMobile } from '../ProductData/RugProductListMobile';

type Props = {
  hasNextPage: boolean;
  loadNextPage: () => void;
  filteredData: RugFilterResponse[];
  allData: RugFilterResponse[];
};
function RugSelectedProduct({ allData, filteredData, ...rest }: Props) {
  const dispatch = useDispatch();
  const visualisations = useSelector(productVisualisation);
  const { showRugFilters } = useSelector(productData);
  const rooms = useAppSelector((state) => state.photos.data);
  const zoomLevel = useAppSelector((state) => state.ui.zoomLevel);

  if (visualisations.loadedIndex === null) return null;

  const { vis, rug, room } = getIndData(visualisations.loadedIndex, {
    visData: visualisations.data,
    roomData: rooms,
    rugData: allData,
  });

  const allProducts = filteredData.map((r) => {
    const sortedBySize = sortRugsBySize([...r.products]);
    const smallestSize = sortedBySize[0];
    return smallestSize;
  });

  function handleProductClick(productId: number) {
    if (visualisations.loadedIndex === null) {
      return; // check to keep typescript happy - we already check above and prevent render if null
    }
    dispatch(
      updateIndVisualisation({
        ind: visualisations.loadedIndex,
        data: { ...vis, rug_id: productId },
      })
    );
  }

  const product = rug?.products.find((rug) => rug.id === vis.rug_id);

  return (
    <>
      {zoomLevel <= 1 && (
        <>
          <RugSelectedHeading rug={rug} vis={vis} room={room} />
          <ProductScroll
            selectedProduct={product}
            products={allProducts}
            handleClick={handleProductClick}
            {...rest}
          />
        </>
      )}
      {(zoomLevel === 0 || zoomLevel === 1) && (
        <RegButton
          primary
          width={'100%'}
          rounded="full"
          height={8}
          mt="2"
          mb="2"
          flex="0 0 auto"
          backgroundColor="#6CB33E"
          disabled={!product}
          _disabled={{
            color: '#141516',
            backgroundColor: '#D0D0D0',
            cursor: 'not-allowed',
            _hover: {
              color: '#141516',
              backgroundColor: '#D0D0D0',
              cursor: 'not-allowed',
            },
          }}
          _hover={{
            backgroundColor: '#6CB33E',
          }}
          onClick={() => {
            if (product) {
              trackEvent({
                category: 'Add To Cart',
                action: 'Click',
                label: `Rug - ${product.sku}`,
              });
              sendIframeMessage(IframeSendTypes.addToCart, {
                sku: product.sku,
                variantId: product.shopify_id,
                productId: product.shopify_product_id,
              });
            }
          }}
        >
          Add to Cart
        </RegButton>
      )}
      <Flex flexDir="column" flex={1} display={{ base: 'none', lg: 'flex' }}>
        <SecondaryHeader align="start" mt="2" mb="2">
          Product Filters
        </SecondaryHeader>
        <RugProductFilters />
        <RugProductList
          {...rest}
          filteredData={filteredData}
          handleProductClick={handleProductClick}
        />
      </Flex>

      <Modal
        returnFocusOnClose={false}
        isOpen={showRugFilters}
        onClose={() => {
          dispatch(setShowRugFilters(false));
          if (document.activeElement instanceof HTMLElement) {
            document.activeElement.blur();
          }
        }}
      >
        <ModalOverlay />
        <ModalContent height={'95vh'} overflowY="scroll" marginY="1rem">
          <ModalHeader
            display="flex"
            justifyContent="space-between"
            paddingBottom={0}
          >
            <Box display="flex">
              <Button
                fontWeight="normal"
                leftIcon={<ArrowBackIcon />}
                backgroundColor="blue.500"
                color="white"
                rounded="full"
                height={8}
                onClick={() => dispatch(setShowRugFilters(false))}
              >
                Back
              </Button>
            </Box>
            <Box width="70%">
              <RugSearch />
            </Box>
          </ModalHeader>
          <ModalBody display="flex" flexDirection="column">
            <RugProductFiltersMobile />
            <RugProductListMobile
              {...rest}
              filteredData={filteredData}
              handleProductClick={handleProductClick}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

export default RugSelectedProduct;
