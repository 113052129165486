import {
  Accordion,
  AccordionItem,
  AccordionButton,
  Box,
  AccordionIcon,
  AccordionPanel,
  Text,
  HStack,
  Tag,
  InputGroup,
  InputLeftElement,
  Input,
  InputRightElement,
  Icon,
  VStack,
  IconButton,
} from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FilterFloorAttributesResponse } from 'shared/build/models/product';
import { useGetFloorProductAttributeQuery } from '../../../redux/api';
import { RegButton } from '../../../UI/Buttons';
import { ApiError } from '../../../UI/ErrorMessage';
import {
  changeFloorSearchTerm,
  clearFloorFilter,
  productData,
  toggleFloorFilter,
} from './productDataSlice';
import { MdSearch } from 'react-icons/md';
import { TiDelete } from 'react-icons/ti';
import { useDebounce } from '../../../utils/hooks';
import { useAppSelector } from '../../../redux/hooks';
import { config } from '../../../config';
import { Loading } from '../../../UI/Loading';

const filterElems: {
  name: string;
  filterKey: keyof FilterFloorAttributesResponse;
}[] = [
  {
    name: 'Product Type',
    filterKey: 'type',
  },
  {
    name: 'Lifestyle',
    filterKey: 'life_style',
  },
  {
    name: 'Style',
    filterKey: 'style',
  },
  {
    name: 'Colour Tone',
    filterKey: 'color_tone',
  },
  {
    name: 'Brand',
    filterKey: 'brand',
  },
];

export function ProductFiltersMobile() {
  const selectedTypes = useAppSelector(
    (state) => state.productData.floorFilters.type
  );
  const { data, error, isLoading } = useGetFloorProductAttributeQuery({
    region: config.region,
    types: selectedTypes,
  });

  if (isLoading) {
    return <Loading />;
  }

  if (error) {
    return <ApiError error={error} />;
  }

  return (
    <Box>
      <Accordion mt={2} allowToggle backgroundColor={'transparent'}>
        {filterElems.map((elem) => (
          <IndividualFilter
            key={elem.name}
            name={elem.name}
            filterKey={elem.filterKey}
            options={data?.data[elem.filterKey].filter((val) => val) ?? []}
          />
        ))}
      </Accordion>
    </Box>
  );
}

export function FloorSearch() {
  const dispatch = useDispatch();
  const searchRef = useRef<HTMLInputElement>();
  const search = useSelector(productData).floorFilters.search_term;
  const [searchInput, setSearchInput] = useState(search);
  const debouncedSearch = useDebounce<string>(searchInput, 300);
  useEffect(() => {
    dispatch(changeFloorSearchTerm(debouncedSearch));
  }, [debouncedSearch]);
  const showFilters = useSelector(productData).showFloorFilters;
  useEffect(() => {
    if (showFilters && searchRef.current) {
      searchRef.current!.focus();
    }
  }, [showFilters]);

  return (
    <Search
      ref={searchRef}
      value={searchInput}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
        setSearchInput(e.target.value)
      }
      onClear={() => setSearchInput('')}
    />
  );
}

export const Search = React.forwardRef(
  (
    props: {
      value: string;
      onClear: () => void;
      onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
      onFocus?: () => void;
    },
    ref
  ) => {
    return (
      <InputGroup>
        <InputLeftElement
          height={8}
          pointerEvents="none"
          children={<Icon as={MdSearch} color="gray.700" />}
        />
        <Input
          height={8}
          type="text"
          ref={ref as any}
          placeholder="Search..."
          value={props.value}
          onChange={props.onChange}
          onFocus={props.onFocus}
        />
        {props.onClear && (
          <InputRightElement
            height={8}
            children={
              <IconButton
                aria-label="clear"
                disabled={!props.value}
                onClick={props.onClear}
                icon={<TiDelete />}
                variant="ghost"
                color="gray.700"
                bg="none"
                _hover={{
                  bg: 'none',
                }}
                fontSize="22px"
              />
            }
          />
        )}
      </InputGroup>
    );
  }
);

function IndividualFilter(props: {
  name: string;
  filterKey: keyof FilterFloorAttributesResponse;
  options: string[];
}) {
  const dispatch = useDispatch();
  const floorFilters = useSelector(productData).floorFilters;
  const currentSelections = floorFilters[props.filterKey];

  // if a filter is selected but the product type is changed (and doesn't include it) we still want to list it
  const notInTypeButSelected = currentSelections.filter(
    (currentSelection) => !props.options.includes(currentSelection)
  );

  const typeAndSelectedOptions = [...props.options, ...notInTypeButSelected];

  return (
    <AccordionItem
      borderColor={'white'}
      isDisabled={typeAndSelectedOptions.length === 0}
    >
      <AccordionButton
        _focus={{ boxShadow: 'none' }}
        backgroundColor={'gray.100'}
        marginY={1}
        rounded="full"
        borderWidth={0}
      >
        <AccordionIcon />
        <Box flex="1" textAlign="left">
          <Text
            color={'brand.blue'}
            marginLeft={2}
            fontWeight={'normal'}
            fontSize="sm"
          >
            {props.name}
          </Text>
        </Box>
        {Boolean(currentSelections.length) && (
          <HStack>
            <Tag
              borderRadius="full"
              size="md"
              variant="solid"
              pl="9px"
              pb="2px"
              colorScheme="blue"
            >
              {currentSelections.length}
            </Tag>
            <Text
              ml={'4px'}
              fontWeight={'bold'}
              onClick={(e) => {
                e.stopPropagation();
                dispatch(clearFloorFilter(props.filterKey));
              }}
            >
              Clear
            </Text>
          </HStack>
        )}
      </AccordionButton>
      <AccordionPanel>
        <VStack
          spacing={2}
          maxHeight="300px"
          overflowY="auto"
          alignItems="flex-start"
        >
          {typeAndSelectedOptions.map((option) => {
            const isSelected = currentSelections.includes(option);
            const options = isSelected ? {} : { bg: 'white' };
            const formattedOption = option
              .split('-')
              .map(capitaliseFirstLetter)
              .join('-');
            return (
              <RegButton
                flex="0 0 auto"
                onClick={() => {
                  dispatch(
                    toggleFloorFilter({
                      category: props.filterKey,
                      val: option,
                    })
                  );
                }}
                fontSize={'sm'}
                rounded={'full'}
                primary={isSelected}
                key={option}
                {...options}
              >
                {formattedOption}
              </RegButton>
            );
          })}
        </VStack>
      </AccordionPanel>
    </AccordionItem>
  );
}

const capitaliseFirstLetter = (word: string) =>
  word[0].toUpperCase() + word.slice(1).toLowerCase();
