import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import photoReducer from '../Components/Photos/photosSlice';
import savedReducer from '../Components/Saved/savedSlice';
import productVisualisationReducer from '../Components/Products/productVisualisationSlice';
import productDataReducer from '../Components/Products/ProductData/productDataSlice';
import visualiserReducer from '../Components/Products/Visualiser/visualiserSlice';
import workflowReducer from '../Components/workflowSlice';
import uiReducer from './uiSlice';
import { roomviewApi } from './api';

export const store = configureStore({
  reducer: {
    photos: photoReducer,
    saved: savedReducer,
    productVisualisation: productVisualisationReducer,
    productData: productDataReducer,
    roomviewApi: roomviewApi.reducer,
    visualiser: visualiserReducer,
    ui: uiReducer,
    workflow: workflowReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(roomviewApi.middleware),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
