import { useThree } from '@react-three/fiber';
import { useEffect } from 'react';
import { radToDeg } from 'three/src/math/MathUtils';

export const CAMERA_HEIGHT = 1.3;
export const CAMERA_Z = 2.5;

function Camera(props: {
  uprightData: {
    pitch: number;
    roll: number;
    fovy: number;
    width: number;
    height: number;
  };
  children: any;
}) {
  const { uprightData } = props;
  const { camera } = useThree();

  useEffect(() => {
    camera.near = 0.01;
    camera.far = 500;
  }, []);

  useEffect(() => {
    const pitch = uprightData.pitch * -1; // pitch is inverted
    const roll = uprightData.roll * -1; // roll is inverted
    const [w, h] = [uprightData.width, uprightData.height];
    const aspect = w / h;
    const vFov = radToDeg(uprightData.fovy);
    (camera as any).aspect = aspect;
    (camera as any).fov = vFov;
    camera.position.set(0, CAMERA_HEIGHT, CAMERA_Z);
    camera.rotation.set(pitch, 0, roll);
    camera.updateProjectionMatrix();
  }, [JSON.stringify(uprightData)]);

  return props.children;
}

export default Camera;
