import { Box, Flex } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { Route, Switch, useLocation } from 'react-router-dom';
import { RoutePaths } from '../App';
import { useAppSelector } from '../redux/hooks';
import Nav from './Nav/Nav';

type ProgressButtonProps = {
  positionNumber: number;
  active: boolean;
  index: number;
  label: string;
};
const ProgressButton = (props: ProgressButtonProps) => {
  const connectingBarStyling = {
    content: "''",
    position: 'absolute',
    top: '25%',
    height: '1px',
    width: '100%',
    right: '50%',
    backgroundColor: props.active ? 'white' : '#2F8BBB',
    backgroundPosition: 'right bottom',
  };
  return (
    <Flex
      flex="1"
      position="relative"
      direction="column"
      alignItems="center"
      mt={2}
      _before={props.positionNumber > 1 ? connectingBarStyling : {}}
    >
      <Box
        position="relative"
        zIndex={1 + props.index}
        rounded="full"
        width={5}
        height={5}
        backgroundColor={props.active ? 'white' : '#0066AB'}
        color={props.active ? 'blue.600' : '#2F8BBB'}
        borderWidth={1}
        borderColor={props.active ? 'white' : '#2F8BBB'}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        {props.positionNumber}
      </Box>
      <Box
        color={props.active ? 'white' : '#2F8BBB'}
        mt="1"
        whiteSpace="nowrap"
        fontSize="xx-small"
      >
        {props.label}
      </Box>
    </Flex>
  );
};

export const Header = () => {
  const [workflowIndex, direction] = useAppSelector(
    (state) => state.workflow.pageData
  );
  const location = useLocation();

  return (
    <motion.header
      initial={{ marginTop: '-50px', opacity: 0 }}
      animate={{ marginTop: 0, opacity: 1 }}
      transition={{ duration: 0.5 }}
      style={{
        backgroundColor: '#0066AB',
        width: '100vw',
        height: 'min-content',
      }}
    >
      <Box w="full" display="flex" justifyContent="center" px="2">
        <Box textColor="white" fontSize="xs" width="400px">
          <Flex h={16} alignItems={'center'}>
            <ProgressButton
              positionNumber={1}
              label="Choose type"
              index={workflowIndex}
              active={1 <= workflowIndex}
            />
            <ProgressButton
              positionNumber={2}
              label="Select photo"
              index={3}
              active={
                3 <= workflowIndex ||
                location.pathname.includes(RoutePaths.editor)
              }
            />
            <ProgressButton
              positionNumber={3}
              label="Customise room"
              index={4}
              active={
                4 <= workflowIndex ||
                (location.pathname.includes(RoutePaths.editor) &&
                  !location.pathname.includes(RoutePaths.photos))
              }
            />
            <ProgressButton
              positionNumber={4}
              label="Request quote"
              index={5}
              active={5 <= workflowIndex}
            />
          </Flex>
        </Box>
      </Box>
      <Switch>
        <Route path={RoutePaths.photos}>{/* show nothing */}</Route>
        <Route path={RoutePaths.editor}>
          <Box
            zIndex={200}
            bg="white"
            w="full"
            h="40px"
            display="flex"
            justifyContent="center"
          >
            <Nav />
          </Box>
        </Route>
      </Switch>
    </motion.header>
  );
};
