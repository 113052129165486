import { Box, Text, useDisclosure, Modal, Flex } from '@chakra-ui/react';
import { useContext, useRef } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import {
  setShowRugControls,
  visualiser,
} from '../Components/Products/Visualiser/visualiserSlice';
import { IconButton } from '@chakra-ui/react';
import {
  SearchIcon,
  ChevronUpIcon,
  ChevronDownIcon,
  ArrowForwardIcon,
  ExternalLinkIcon,
  CloseIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from '@chakra-ui/icons';
import { Space, ViewPort } from 'react-zoomable-ui';
import { setZoomLevel } from '../redux/uiSlice';
import { useAppSelector, useAppDispatch } from '../redux/hooks';
import { productView, ProductView } from '../Components/Products/Products';
import {
  productVisualisation,
  selectNext,
  selectPrevious,
  setSelectedProductInfo,
  updateRug,
} from '../Components/Products/productVisualisationSlice';
import {
  resetBlindPosition,
  resetRugPosition,
} from '../Components/Products/productVisualisationSlice';
import {
  useDefaultBlindPoints,
  useDefaultRugPosition,
} from '../Components/Products/Visualiser/helper';
import {
  addBlind,
  removeBlinds,
} from '../Components/Products/productVisualisationSlice';
import SaveButtonNew from '../Components/Products/Visualiser/SaveButtonNew';
import { LoadedContext } from '../Components/Products/Visualiser/Visualiser';
import { getText } from '../Components/Products/SelectedProduct/Disclaimer';
import ProductThumbnail, {
  BorderType,
} from '../Components/Products/ProductList/ProductThumbnail';
import {
  BlindProductDb,
  FloorProductDb,
  RugProductDb,
} from 'shared/build/models/product';
import { IoMove, IoTrash } from 'react-icons/all';
import { IoAdd } from 'react-icons/io5';
import Share from '../Components/Share/Share';
import { RotateButton } from '../Components/Products/Visualiser/BottomOverlayButtons';

const ProductInfoPopup = (props: {
  product: BlindProductDb | RugProductDb | FloorProductDb;
  view: ProductView;
  brand: string;
}) => {
  const dispatch = useDispatch();

  return (
    <Box
      position="absolute"
      bottom={4}
      zIndex={100}
      display={'flex'}
      justifyContent="center"
      width="full"
    >
      <Box
        bg="white"
        rounded="xl"
        borderWidth={1}
        padding={1}
        borderColor="gray.700"
        width="75%"
        maxWidth="500px"
        p={3}
      >
        <Box display="flex" justifyContent="end">
          <Box
            marginBottom={'-10px'}
            onClick={() => dispatch(setSelectedProductInfo(null))}
          >
            <CloseIcon
              color="gray.700"
              cursor="pointer"
              width={'10px'}
              height={'10px'}
            />
          </Box>
        </Box>
        <Box display="flex" mb={4}>
          <ProductThumbnail
            product={props.product}
            size={50}
            border={BorderType.discrete}
          />
          <Box ml={2}>
            <Box fontSize="xs" color="gray.600">
              {props.brand}
            </Box>
            <Box fontSize="xs" color="#2B6CB0">
              {props.product.name} *
            </Box>
            <Box
              borderColor="gray.700"
              cursor="pointer"
              fontSize="xs"
              onClick={() =>
                window.open(props.product.product_url, '_blank')?.focus()
              }
            >
              View Details <ArrowForwardIcon />
            </Box>
          </Box>
        </Box>
        <Box fontSize="xs" color="gray.600">
          {getText(props.view)}
        </Box>
      </Box>
    </Box>
  );
};

export function PannableContainer(props: {
  children: any;
  roomId: string;
  w: number;
  h: number;
  currentView: ProductView;
  orientation: 'default' | 'rotated';
}) {
  const dispatch = useAppDispatch();
  const spaceRef = useRef<Space | null>(null);
  const isPannable = useSelector(visualiser).pannable;
  const isDisabled = () => ({ ignorePressEntirely: isPannable === false });
  const zoomLevel = useAppSelector((state) => state.ui.zoomLevel);

  const view = productView(window.location.pathname);

  const { isLoadingRoom, isLoadingProduct, data } =
    useSelector(productVisualisation);
  const loadedInd = useContext(LoadedContext);
  const loadedVisualisation = data[loadedInd!];

  const {
    isOpen: isShareOpen,
    onOpen: onShareOpen,
    onClose: onShareClose,
  } = useDisclosure();

  const toggleZoomLevel = () => {
    switch (zoomLevel) {
      case 0:
        dispatch(setZoomLevel(1));
        return;
      case 1:
        dispatch(setZoomLevel(2));
        return;
      case 2:
        dispatch(setZoomLevel(0));
        return;
    }
  };

  const showPageToggles = data.length > 1;

  const selectedVisIndex = useAppSelector(
    (state) => state.productVisualisation.selectedIndex
  );

  const visData = useAppSelector((state) => state.productVisualisation);

  // Recenter Blinds
  const defaultBlindPoints = useDefaultBlindPoints();
  const resetBlinds = () => {
    if (selectedVisIndex === null) {
      return;
    }
    dispatch(
      resetBlindPosition({
        index: selectedVisIndex,
        points: defaultBlindPoints,
      })
    );
  };

  // Recenter Rugs
  const defaultRugPosition = useDefaultRugPosition();
  const resetRug = () => {
    if (selectedVisIndex === null) {
      return;
    }
    dispatch(
      resetRugPosition({
        index: selectedVisIndex,
        position: defaultRugPosition,
      })
    );
  };

  const deleteRug = () => {
    if (loadedInd === null) {
      return;
    }

    batch(() => {
      dispatch(updateRug({ index: loadedInd, id: null }));
      dispatch(resetRugPosition({ index: loadedInd }));
      dispatch(setShowRugControls(false));
    });
  };

  // Product info popup
  const displayProductPopup = useAppSelector(
    (state) => state.productVisualisation.selectedProduct
  );

  return (
    <>
      {/* Product Info Popup*/}
      {displayProductPopup === null ? (
        <></>
      ) : (
        <ProductInfoPopup
          product={displayProductPopup.product}
          view={props.currentView}
          brand={displayProductPopup.brand}
        />
      )}

      {/* Right Hand Zoom Icons (mobile only) */}
      <Box
        position="absolute"
        right={0}
        zIndex={10}
        height="100%"
        display={{ base: 'block', sm: 'none' }}
      >
        <Box
          onClick={toggleZoomLevel}
          cursor="pointer"
          position="absolute"
          top="115px" // ensure fixed positioning of zoom bar across all devices
          right="0"
          bg="white"
          roundedTopLeft="xl"
          roundedBottomLeft="xl"
          borderTopWidth={1}
          borderLeftWidth={1}
          borderBottomWidth={1}
          padding={1}
          borderColor="black"
          display="flex"
          flexDirection="column"
        >
          <IconButton
            pointerEvents="none"
            size="xs"
            rounded="full"
            backgroundColor="white"
            aria-label="Zoom"
            icon={<ChevronUpIcon color="#636363" />}
          />
          <IconButton
            pointerEvents="none"
            size="xs"
            rounded="full"
            backgroundColor="white"
            aria-label="Zoom"
            icon={<SearchIcon color="#636363" />}
          />
          <IconButton
            pointerEvents="none"
            size="xs"
            rounded="full"
            backgroundColor="white"
            aria-label="Zoom"
            icon={<ChevronDownIcon color="#636363" />}
          />
        </Box>
      </Box>

      {props.currentView === 'rugs' && (
        <Box
          position="absolute"
          display="flex"
          flexDirection="row"
          width="full"
          zIndex={10}
          mt={10}
          justifyContent="space-around"
        >
          <Box
            onClick={resetRug}
            bg="white"
            rounded="full"
            borderWidth={0.5}
            paddingY={1}
            paddingX={2}
            borderColor="gray.200"
            className="gray.600"
            display="flex"
            flexDirection="row"
            alignItems="center"
            fontSize="sm"
            cursor="pointer"
            height="fit-content"
            color="#4A5568"
          >
            <IoMove display="inline" />{' '}
            <Text textWrap="nowrap" ml={1}>
              Recenter
            </Text>
          </Box>

          <Box
            onClick={deleteRug}
            bg="white"
            rounded="full"
            borderWidth={0.5}
            paddingY={1}
            paddingX={2}
            borderColor="gray.200"
            className="gray.600"
            display="flex"
            flexDirection="row"
            alignItems="center"
            fontSize="sm"
            cursor="pointer"
            height="fit-content"
            color="#4A5568"
          >
            <IoTrash display="inline" />{' '}
            <Text textWrap="nowrap" ml={1}>
              Remove
            </Text>
          </Box>
        </Box>
      )}

      {props.currentView === 'blinds' && (
        <Box
          position="absolute"
          display="flex"
          flexDirection="row"
          width="full"
          zIndex={10}
          mt={3}
          justifyContent="space-around"
        >
          <Box
            width="80%"
            rounded="xl"
            display="flex"
            zIndex={10}
            justifyContent="space-between"
            alignItems="center"
          >
            <Box
              onClick={() => dispatch(addBlind(selectedVisIndex as number))}
              bg="white"
              rounded="full"
              borderWidth={0.5}
              paddingY={1}
              paddingX={2}
              borderColor="gray.200"
              className="gray.600"
              display="flex"
              flexDirection="row"
              alignItems="center"
              fontSize="sm"
              cursor="pointer"
              height="fit-content"
              color="#4A5568"
            >
              <IoAdd display="inline" />{' '}
              <Text textWrap="nowrap" ml={1}>
                Add Blinds
              </Text>
            </Box>

            <Box
              onClick={resetBlinds}
              bg="white"
              rounded="full"
              borderWidth={0.5}
              paddingY={1}
              paddingX={2}
              borderColor="gray.200"
              className="gray.600"
              display="flex"
              flexDirection="row"
              alignItems="center"
              fontSize="sm"
              cursor="pointer"
              height="fit-content"
              color="#4A5568"
            >
              <IoMove display="inline" />{' '}
              <Text textWrap="nowrap" ml={1}>
                Recenter
              </Text>
            </Box>

            <Box
              onClick={() =>
                dispatch(
                  removeBlinds({
                    index: selectedVisIndex as number,
                    defaultPoints: defaultBlindPoints,
                  })
                )
              }
              bg="white"
              rounded="full"
              borderWidth={0.5}
              paddingY={1}
              paddingX={2}
              borderColor="gray.200"
              className="gray.600"
              display="flex"
              flexDirection="row"
              alignItems="center"
              fontSize="sm"
              cursor="pointer"
              height="fit-content"
              color="#4A5568"
            >
              <IoTrash display="inline" />{' '}
              <Text textWrap="nowrap" ml={1}>
                Remove
              </Text>
            </Box>
          </Box>
        </Box>
      )}

      {/* Room Navigation and custom product controls */}
      <Box
        position="absolute"
        bottom={{ base: '0', sm: '12' }}
        pb="10"
        display="flex"
        flexDirection="column"
        width="full"
      >
        {/* Row 2 */}
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          width="full"
        >
          {/* Previous Page */}
          <Box
            bg="white"
            roundedTopRight="full"
            roundedBottomRight="full"
            borderTopWidth={1}
            borderRightWidth={1}
            borderBottomWidth={1}
            padding={0}
            height={'25px'}
            borderColor="#636363"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            zIndex={10}
            opacity={showPageToggles === true ? 1 : 0}
            onClick={() => dispatch(selectPrevious())}
            cursor="pointer"
          >
            <ChevronLeftIcon color="#636363" />
          </Box>

          <Box
            width="50%"
            rounded="xl"
            display="flex"
            zIndex={10}
            justifyContent="space-around"
          >
            {zoomLevel === 2 && (
              <Box
                bg="white"
                rounded="xl"
                borderWidth={1}
                minWidth={10}
                padding={1}
                borderColor="#636363"
                className="gray.600"
                fontSize="sm"
                display="flex"
                justifyContent="center"
                alignItems="center"
                cursor="pointer"
                onClick={onShareOpen}
              >
                <ExternalLinkIcon color="#636363" />
              </Box>
            )}
            <RotateButton
              loadedVisualisation={loadedVisualisation}
              orientation={props.orientation}
            />
            {zoomLevel === 2 && (
              <Box
                bg="white"
                rounded="xl"
                borderWidth={1}
                minWidth={10}
                padding={1}
                borderColor="#636363"
                className="gray.600"
                fontSize="sm"
                display="flex"
                justifyContent="center"
                alignItems="center"
                cursor="pointer"
              >
                <SaveButtonNew altColor="#636363" />
              </Box>
            )}
          </Box>

          {/* Next Page */}
          <Box
            bg="white"
            roundedTopLeft="full"
            roundedBottomLeft="full"
            borderTopWidth={1}
            borderLeftWidth={1}
            borderBottomWidth={1}
            height={'25px'}
            justifyContent="center"
            borderColor="#636363"
            display="flex"
            flexDirection="column"
            cursor="pointer"
            zIndex={10}
            opacity={showPageToggles === true ? 1 : 0}
            onClick={() => dispatch(selectNext())}
          >
            <ChevronRightIcon color="#636363" />
          </Box>
        </Box>
      </Box>

      {/* Display Box */}
      <Box
        mx={0}
        bg="gray.100"
        position="relative"
        flex="1"
        height="100%"
        overflow="hidden"
        id="wrapper-box"
      >
        <Space
          ref={spaceRef}
          onDecideHowToHandlePress={isDisabled}
          onCreate={(viewPort: ViewPort) => {
            viewPort.camera.centerFitAreaIntoView({
              left: 0,
              // HACK: try to center picture in viewport. You can only apparently do this once; any react re-renders
              // aren't picked up. So the first time the component renders we try and detect sm viewport - if so,
              // use half height as the top value which seems to nudge it into position.
              top: window.innerWidth >= 671 ? 0 : props.h / 2,
              width: props.w,
              height: props.h,
            });
          }}
        >
          {props.children}
        </Space>
      </Box>

      <Modal size="xs" isOpen={isShareOpen} onClose={onShareClose}>
        <Share view={props.currentView} visualisations={visData.data} />
      </Modal>
    </>
  );
}
