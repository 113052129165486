import { Flex, Tooltip, Box } from '@chakra-ui/react';
import { useEffect } from 'react';
import { useRef, useState } from 'react';
import {
  FloorFilterResponse,
  Product,
  RugFilterResponse,
} from 'shared/build/models/product';
import { HorizontalScroll } from '../../../UI/HorizontalScroll';
import { Loading } from '../../../UI/Loading';
import Description from './ProductDescriptions';
import ProductThumbnail from './ProductThumbnail';

type Props = {
  data: {
    elems: RugFilterResponse[] | FloorFilterResponse[];
    dispatchSelectProduct: (product: Product) => void;
    isItemLoaded?: (index: number) => boolean;
  };
  index: number;
  style: {};
};

const productThumbnailSize = 35;

const RowMobile = ({ data, index, style }: Props) => {
  const [visibleCount, setVisibleCount] = useState(0);
  const [offset, setOffset] = useState(0);
  const productListRef = useRef(null as null | HTMLDivElement);
  const { elems, dispatchSelectProduct, isItemLoaded } = data;
  const itemLoaded = isItemLoaded ? isItemLoaded(index) : false;
  useEffect(() => {
    if (itemLoaded && productListRef.current) {
      const visCount = Math.floor(
        productListRef.current.clientWidth / (productThumbnailSize + 5)
      );
      setVisibleCount(visCount);
    }
  }, [itemLoaded]);
  if (!itemLoaded) {
    return <Loading />;
  }
  const elem = elems[index];
  const [firstProduct, ...remainProducts] = elem.products;

  const showNextPage = offset + visibleCount < remainProducts.length;
  const showPreviousPage = offset > 0;
  let productCount = visibleCount;
  if (showNextPage || showPreviousPage) productCount = visibleCount - 1; // One space allocated for next page btn
  if (showNextPage && showPreviousPage) productCount = visibleCount - 2; // One space allocated for next page btn and one for previous btn
  return (
    <Flex key={index} width="100%" style={style} pb={'3px'} pt="3px">
      <Tooltip label={firstProduct.name} placement="auto">
        <ProductThumbnail
          product={firstProduct}
          size={70}
          onClick={() => {
            dispatchSelectProduct(firstProduct);
          }}
        />
      </Tooltip>
      <Flex direction="column" flex="1" ml="3">
        <Description elem={elem} />
        <Flex pr="10px" mt="1" flexWrap="wrap" ref={productListRef}>
          {showPreviousPage && (
            <AdditionalCount
              key={'prevbtn'}
              count={null}
              onClick={() => setOffset(Math.max(0, offset - productCount))}
            />
          )}
          {remainProducts
            .slice(offset, offset + productCount)
            .map((prod: any) => {
              return (
                <Box mr="1" key={prod.id}>
                  <ProductThumbnail
                    product={prod}
                    size={productThumbnailSize}
                    onClick={() => {
                      dispatchSelectProduct(prod);
                    }}
                  />
                </Box>
              );
            })}
          {showNextPage && (
            <AdditionalCount
              key={'nextbtn'}
              count={remainProducts.length - (offset + productCount)}
              onClick={() => setOffset(offset + productCount)}
            />
          )}
        </Flex>
      </Flex>
    </Flex>
  );
};

export function AdditionalCount(props: { count: number | null; onClick: () => void }) {
  return (
    <Flex
      cursor="pointer"
      _hover={{ opacity: 0.85 }}
      rounded="lg"
      border="1px solid #ccc"
      onClick={props.onClick}
      boxSize={productThumbnailSize + 'px'}
      justifyContent="center"
      alignItems="center"
      color="#999"
      pb="2px"
      mr="1"
    >
      {props.count === null ? '<' : '+' + props.count}
    </Flex>
  );
}

export default RowMobile;
