import { useThree } from '@react-three/fiber';
import { useMemo } from 'react';
import {
  Vector2,
  RepeatWrapping,
  Texture,
  LinearFilter,
  LinearMipMapLinearFilter,
} from 'three';

export const xRotation = (-90 * Math.PI) / 180; // rotate plane on the ground (rather than vertical plane)

function Flooring(props: {
  texture: Texture | null;
  rotation: number;
  floorIsTextured: boolean;
}) {
  const three = useThree();
  const anisotropy = useMemo(() => {
    return three.gl.capabilities.getMaxAnisotropy();
  }, []);
  const texture = props.texture;
  if (!texture) {
    return null;
  }
  // define the texture attributes
  texture.repeat = new Vector2(6, 6);
  texture.wrapS = RepeatWrapping;
  texture.wrapT = RepeatWrapping;
  // If Style is textured, we use LinearMipMapLinearFilter
  // To reduce strange noise that can occur from this kind of image
  // otherwise we use a LinearFilter as it is crisp and retains sharp details
  texture.minFilter = props.floorIsTextured
    ? LinearMipMapLinearFilter
    : LinearFilter;
  texture.center = new Vector2(0.5, 0.5);
  texture.rotation = props.rotation;
  texture.anisotropy = anisotropy;

  return (
    <mesh rotation={[xRotation, 0, 0]} position={[0, 0, 0]}>
      <planeBufferGeometry attach="geometry" args={[50, 50, 1, 1]} />
      <meshStandardMaterial
        attach="material"
        color="white"
        map={texture}
        onUpdate={(self) => texture && (self.needsUpdate = true)}
      />
    </mesh>
  );
}

export default Flooring;
