import {
  Box,
  Flex,
  Grid,
  GridItem,
  HStack,
  Modal,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { ExternalLinkIcon } from '@chakra-ui/icons';
import { useEffect } from 'react';
import {
  FloorFilterResponse,
  FloorProductDb,
} from 'shared/build/models/product';
import { RoomApi } from 'shared/build/models/room';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { SecondaryHeader } from '../../../UI/Text';
import { createBrandDetails } from '../helpers';
import { setShowFloorFilters } from '../ProductData/productDataSlice';
import ProductThumbnail from '../ProductList/ProductThumbnail';
import FilterButton from './FilterButton';
import { useTrackProductView } from './helpers';
import ProductInfo from './ProductInfo';
import { setSelectedProductInfo } from '../productVisualisationSlice';
import SaveButtonNew from '../Visualiser/SaveButtonNew';
import Share from '../../Share/Share';
import { ProductView } from '../Products';

type Props = {
  product: FloorProductDb;
  floor: FloorFilterResponse;
  room: RoomApi;
  allProducts: FloorProductDb[];
  updateSelectedProduct: (productId: number) => void;
};
const FloorSelectedProductHeading = ({
  floor,
  product,
  room,
  allProducts,
  updateSelectedProduct,
}: Props) => {
  useTrackProductView(room, product.sku);

  const floorTypes = useAppSelector(
    (state) => state.productData.floorFilters.type
  );

  const zoomLevel = useAppSelector((state) => state.ui.zoomLevel);

  const visData = useAppSelector((state) => state.productVisualisation);
  const {
    isOpen: isShareOpen,
    onOpen: onShareOpen,
    onClose: onShareClose,
  } = useDisclosure();

  useEffect(() => {
    if (
      floorTypes.length &&
      !floorTypes.includes(floor.brand_category.type) &&
      allProducts.length
    ) {
      updateSelectedProduct(allProducts[1].id);
    }
  }, [JSON.stringify(floorTypes), JSON.stringify(allProducts)]);

  const dispatch = useAppDispatch();
  const brandDetails = createBrandDetails(floor);

  const productSelected = useAppSelector(
    (state) => state.productVisualisation.selectedProduct
  );
  const toggleProductInfoFromHeader = () =>
    productSelected === null
      ? dispatch(
          setSelectedProductInfo({
            product: product,
            brand: brandDetails as string,
          })
        )
      : dispatch(setSelectedProductInfo(null));

  return (
    <>
      <Grid
        mt={{ base: '0.5em', lg: '0' }}
        columnGap={{ base: 'auto', lg: '0.25em' }}
        gridTemplateColumns={
          '[filter] auto [share] fit-content(2rem) [save] fit-content(2rem)'
        }
      >
        <GridItem gridColumn="filter">
          <FilterButton handleClick={() => dispatch(setShowFloorFilters(true))}>
            Filter Flooring
          </FilterButton>
        </GridItem>
        <GridItem gridColumn="share">
          <Box
            rounded="full"
            display="flex"
            background="gray.200"
            paddingX={3}
            paddingY={1}
            minWidth={50}
            height={30}
            justifyContent="center"
            alignItems="center"
            onClick={onShareOpen}
          >
            <ExternalLinkIcon />
          </Box>
        </GridItem>
        <GridItem gridColumn="save">
          <Box
            rounded="full"
            display="flex"
            background="gray.200"
            paddingX={3}
            paddingY={1}
            minWidth={50}
            height={30}
            ml={{ base: '2', sm: '1' }}
            justifyContent="center"
            alignItems="center"
          >
            <SaveButtonNew />
          </Box>
        </GridItem>
      </Grid>
      {zoomLevel === 0 && (
        <HStack mt={2} mb={1}>
          <Box flex="0 0 auto" position="relative">
            <ProductThumbnail product={product} size={50}>
              <ProductInfo
                url={product.product_url}
                product={product}
                brand={brandDetails as string}
              />
            </ProductThumbnail>
          </Box>
          <Flex direction="column" pl="2">
            <Text align="left" fontWeight="light" fontSize="12px" pt="5px">
              {brandDetails}
            </Text>
            <SecondaryHeader
              mt="0.5"
              mb="4"
              flex="1"
              align="left"
              color="#2B6CB0"
              onClick={toggleProductInfoFromHeader}
              cursor="pointer"
            >
              {product.name} *
            </SecondaryHeader>
          </Flex>
        </HStack>
      )}
      <Modal size="xs" isOpen={isShareOpen} onClose={onShareClose}>
        <Share view={ProductView.flooring} visualisations={visData.data} />
      </Modal>
    </>
  );
};

export default FloorSelectedProductHeading;
