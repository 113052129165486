import { Icon } from '@chakra-ui/react';
import { IoAdd } from 'react-icons/io5';
import { RiStackFill } from 'react-icons/ri';

export function ProductIcon() {
  return <Icon as={RiStackFill} mr="8px" />;
}

export function AddIcon() {
  return <Icon as={IoAdd} mr="8px" />;
}
