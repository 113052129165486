import { HStack, Icon } from '@chakra-ui/react';
import { FaCircle, FaRegCircle } from 'react-icons/fa';
import { useDispatch } from 'react-redux';
import { BlindTypes } from 'shared/build/models/product';
import { useAppSelector } from '../../../redux/hooks';
import { RegButton } from '../../../UI/Buttons';
import { changeBlindType } from '../ProductData/productDataSlice';

const BlindTypeButtons = () => {
  const dispatch = useDispatch();
  const blindType = useAppSelector(
    (state) => state.productData.blindFilters.type
  )[0];

  return (
    <HStack>
      <RegButton
        leftIcon={
          <Icon
            as={blindType === BlindTypes.Roller ? FaCircle : FaRegCircle}
            h="6"
            color="brand.blue"
          />
        }
        onClick={() => dispatch(changeBlindType(BlindTypes.Roller))}
        size="sm"
      >
        Roller Blinds
      </RegButton>
      <RegButton
        leftIcon={
          <Icon
            as={blindType === BlindTypes.Shutter ? FaCircle : FaRegCircle}
            h="6"
            color="brand.blue"
          />
        }
        onClick={() => dispatch(changeBlindType(BlindTypes.Shutter))}
        size="sm"
      >
        Shutters
      </RegButton>
    </HStack>
  );
};

export default BlindTypeButtons;
