import {
  Box,
  Menu,
  MenuButton,
  MenuList,
  MenuGroup,
  MenuItem,
} from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import { IoChevronDown } from 'react-icons/io5';
import { RiStackFill } from 'react-icons/ri';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { RoutePaths } from '../../App';
import { useGetFloorProductAttributeQuery } from '../../redux/api';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { StyledMenuButton } from '../../UI/Buttons';
import { clearFloorFilter } from '../Products/ProductData/productDataSlice';
import { productVisualisation } from '../Products/productVisualisationSlice';
import FlooringSubmenu from './FlooringSubmenu';
import { ProductIcon, AddIcon } from './Icons';
import { config } from '../../config';

export function ProductNav() {
  const appDispatch = useAppDispatch();
  const location = useLocation();
  const { loadedIndex, data } = useSelector(productVisualisation);
  const selectedTypes = useAppSelector(
    (state) => state.productData.floorFilters.type
  );
  const { data: flooringProductData } = useGetFloorProductAttributeQuery({
    region: config.region,
    types: selectedTypes,
  });
  const vis = loadedIndex === null ? null : data[loadedIndex];
  const onProductPage = location.pathname.includes(RoutePaths.products);
  const [hasFlooring, hasBlinds, hasRugs] = !vis
    ? [false, false, false]
    : [!!vis.floor_id, !!vis.blind_id, !!vis.rug_id];
  const [addFloor, addBlinds, addRugs] = [
    onProductPage && !hasFlooring,
    onProductPage && !hasBlinds,
    onProductPage && !hasRugs,
  ];

  return (
    <Box>
      <Menu key={RoutePaths.products}>
        <MenuButton
          as={StyledMenuButton}
          textMt={'0'}
          leftIcon={<RiStackFill />}
          rightIcon={<IoChevronDown />}
          primary={onProductPage}
          fontSize={{ base: 'sm', sm: 'md' }}
        >
          {productName(location.pathname)}
        </MenuButton>
        <MenuList
          position="absolute"
          marginTop="5px"
          minWidth="150px"
          color="gray.600"
          fontSize="sm"
        >
          <MenuGroup title="Products" fontSize={{ base: 'sm', sm: 'md' }}>
            <MenuGroup>
              <MenuItem
                as={RouterLink}
                to={RoutePaths.flooring}
                onClick={() => appDispatch(clearFloorFilter('type'))}
                fontSize={{ base: 'sm', sm: 'md' }}
              >
                {addFloor ? <AddIcon /> : <ProductIcon />}{' '}
                {addFloor ? 'Add Flooring' : 'Flooring'}
              </MenuItem>
              {flooringProductData?.data.type.map((type) => (
                <FlooringSubmenu type={type} addFloor={addFloor} key={type} />
              ))}
            </MenuGroup>
            <MenuItem
              as={RouterLink}
              to={RoutePaths.blinds}
              fontSize={{ base: 'sm', sm: 'md' }}
            >
              {addBlinds ? <AddIcon /> : <ProductIcon />}{' '}
              {addBlinds ? 'Add Blinds' : 'Blinds'}
            </MenuItem>
            <MenuItem
              as={RouterLink}
              to={RoutePaths.rugs}
              fontSize={{ base: 'sm', sm: 'md' }}
            >
              {addRugs ? <AddIcon /> : <ProductIcon />}{' '}
              {addRugs ? 'Add Rugs' : 'Rugs'}
            </MenuItem>
          </MenuGroup>
        </MenuList>
      </Menu>
    </Box>
  );
}

export default ProductNav;

function productName(path: string) {
  if (path.includes(RoutePaths.flooring)) {
    return 'Flooring';
  }
  if (path.includes(RoutePaths.blinds)) {
    return 'Blinds';
  }
  if (path.includes(RoutePaths.rugs)) {
    return 'Rugs';
  }
  return 'Products';
}
