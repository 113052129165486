import { Flex, ChakraProvider, Box } from '@chakra-ui/react';
import { Switch, Route, useLocation } from 'react-router-dom';
import DataLoader from './Components/DataLoader/Dataloader';
import HiddenSupportInfo from './Components/HiddenSupportInfo';
import Home from './Components/Home/Home';
import QRLandingPage from './Components/InspirationStation/QRLandingPage';
import UploadLandingPage from './Components/InspirationStation/UploadLandingPage';
import Onboarding from './Components/Onboarding/Onboarding';
import PhotosNew from './Components/Photos/PhotosNew';
import { ProductsContainer } from './Components/Products/Products';
import BackgroundImage from './Components/Onboarding/RoomView-Background-New.jpg';
import Saved from './Components/Saved/Saved';
import { roomviewTheme } from './UI/theme';
import { useTracking } from './utils/tracking';
import { Header } from './Components/WorkflowProgressBar';
import Footer, { showFooter } from './Components/WorkflowFooter';
import { AnimatePresence, motion } from 'framer-motion';
import { ReactNode } from 'react';
import { useAppSelector } from './redux/hooks';

export enum RoutePaths {
  saved = '/editor/saved',
  onboarding = '/onboarding',
  photos = '/editor/photos',
  products = '/editor/products',
  flooring = '/editor/products/flooring',
  blinds = '/editor/products/blinds',
  rugs = '/editor/products/rugs',
  editor = '/editor/',
  home = '/',
  inspirationStation = '/inspiration_station',
  inspirationStationQr = '/inspiration_station/qr',
  inspirationStationUpload = '/inspiration_station/upload',
  hiddenSupportInfo = '/support_info',
}

function App() {
  useTracking();
  const location = useLocation();
  const [currentIndex, direction] = useAppSelector(
    (state) => state.workflow.pageData
  );

  // Offset height if used MUST match the height of the footer
  const footerOffsetHeight =
    showFooter(currentIndex, location.pathname) || // offset if we're showing the footer...
    (currentIndex === 1 &&
      !location.pathname.includes(RoutePaths.inspirationStationQr)) // or on page 1, but not QR version, as if we don't leave space for it the whole page jags upwards when it animates in
      ? '64px'
      : '0';

  return (
    <ChakraProvider resetCSS={true} theme={roomviewTheme}>
      <DataLoader>
        <ContentWrapper>
          <Header />
          <Box
            as="main"
            flex="1"
            overflow="auto"
            position="relative"
            mb={footerOffsetHeight}
          >
            <Switch>
              <Route path={RoutePaths.home} exact>
                <Home />
              </Route>
              <Route path={RoutePaths.onboarding}>
                <Onboarding />
              </Route>
              <Route path={RoutePaths.inspirationStation}>
                <Route path={RoutePaths.inspirationStationQr}>
                  <QRLandingPage />
                </Route>
                <Route path={RoutePaths.inspirationStationUpload}>
                  <UploadLandingPage />
                </Route>
              </Route>
              <Route path={RoutePaths.editor}>
                <AnimatePresence>
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.5, ease: 'easeInOut' }}
                    style={{
                      height: '100%',
                    }}
                  >
                    <Flex
                      flexDirection="column"
                      alignItems="center"
                      w="100%"
                      height="100%"
                    >
                      <Switch>
                        <Route path={RoutePaths.photos}>
                          <Box width="400px" maxWidth="75%">
                            <PhotosNew />
                          </Box>
                        </Route>
                        <Route path={RoutePaths.products}>
                          <ProductsContainer />
                        </Route>
                        <Route path={RoutePaths.saved}>
                          <Saved />
                        </Route>
                      </Switch>
                    </Flex>
                  </motion.div>
                </AnimatePresence>
              </Route>
              <Route path={RoutePaths.hiddenSupportInfo}>
                <HiddenSupportInfo />
              </Route>
            </Switch>
          </Box>
          <AnimatePresence>
            <Footer />
          </AnimatePresence>
        </ContentWrapper>
      </DataLoader>
    </ChakraProvider>
  );
}

function ContentWrapper(props: { children: ReactNode }) {
  return (
    <Box
      id="content-wrapper"
      bgImage={`url(${BackgroundImage})`}
      bgPosition="center"
      bgRepeat="no-repeat"
      bgSize="cover"
    >
      <div
        className="full-container-query-height"
        style={{
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
        }}
      >
        {props.children}
      </div>
    </Box>
  );
}

export default App;
