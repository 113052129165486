import { Flex, Text, Box } from '@chakra-ui/react';
import {
    BlindFilterResponse,
  FloorFilterResponse,
  RugFilterResponse,
} from 'shared/build/models/product';
import { SecondaryHeader } from '../../../UI/Text';
import { checkIsBrandAndCategorySame, isFlooring, isRug } from '../helpers';

const Description = ({
  elem,
}: {
  elem: RugFilterResponse | FloorFilterResponse | BlindFilterResponse;
}) => {
  if (isFlooring(elem)) {
    const isBrandAndCategorySame = checkIsBrandAndCategorySame(
      elem.brand_category.brand,
      elem.brand_category.brand_category
    );

    return (
      <Flex flexDir="column">
        {!isBrandAndCategorySame ? (
          <Text mt="2" align="left" fontWeight="light" fontSize="12px">
            {elem.brand_category.brand}
          </Text>
        ) : (
          <Box h={'22px'}></Box>
        )}
        <SecondaryHeader mt="0.5" flex="1" align="left">
          {elem.brand_category.brand_category}
        </SecondaryHeader>
      </Flex>
    );
  }
  if (isRug(elem)) {
    return (
      <SecondaryHeader mt={2} flex="1" align="left">
        {elem.name}
      </SecondaryHeader>
    );
  }
  return <></>;
};

export default Description;
