import { Container, Graphics, Sprite, useApp } from '@inlet/react-pixi';
import * as PIXI from 'pixi.js';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Container2d } from './Projection';
import { Container2d as Container2dType } from 'pixi-projection';
import { InteractionManager } from 'pixi.js';
import { useDispatch, useSelector } from 'react-redux';
import {
  setPannable as setPannableAction,
  setShowBlindControls,
} from '../visualiserSlice';
import { BehaviorSubject } from 'rxjs';
import { useMemo } from 'react';
import { times } from 'lodash';
import {
  productVisualisation,
  updateIndVisualisation,
} from '../../productVisualisationSlice';
import { ImagePoint } from 'shared/build/models/visualiser';
import { LoadedContext } from '../Visualiser';
import { threeCanv } from '.';
import { checkVisualiserBtn } from '../helper';
import { useAppSelector } from '../../../../redux/hooks';

type OnCompleted = (updatedPoints: { x: number; y: number }[]) => void;
type SetPannable = (pannable: boolean) => void;
type BlindAssets = {
  mask: PIXI.Texture;
  image: PIXI.Texture;
  overlay: PIXI.Texture;
  trim: PIXI.Texture;
};

export function Blind(props: { assets: BlindAssets; blindInd: number }) {
  const loadedInd = useContext(LoadedContext);
  const vis = useSelector(productVisualisation).data[loadedInd!];
  const showControls = useAppSelector(
    (state) => state.visualiser.showBlindControls
  );
  const dispatch = useDispatch();
  const setShowControls = (show: boolean) =>
    dispatch(setShowBlindControls(show));
  const subject = useMemo(
    () => new BehaviorSubject(vis.blind_config![props.blindInd].points),
    []
  );
  const app = useApp();
  const containerRef = useRef(null as any);
  const interactionManager = app.renderer.plugins.interaction;
  useEffect(() => {
    return onClickOutside(showControls, setShowControls, interactionManager, {
      containerRef,
    });
  }, [showControls]);
  useEffect(() => {
    subject.next(vis.blind_config![props.blindInd].points);
  }, [JSON.stringify(vis.blind_config![props.blindInd])]);
  function onCompleted(updatedPoints: { x: number; y: number }[]) {
    const blindInd = props.blindInd;
    dispatch(
      updateIndVisualisation({
        ind: loadedInd!,
        data: {
          ...vis,
          blind_config: vis.blind_config!.map((b, ind) => {
            if (ind !== blindInd) {
              return b;
            }
            return {
              ...b,
              points: updatedPoints,
            };
          }),
        },
      })
    );
  }
  return (
    <>
      <BlindPreview
        onCompleted={onCompleted}
        subject={subject}
        assets={props.assets}
      />
      {showControls && (
        <BlindHandles subject={subject} onCompleted={onCompleted} />
      )}
    </>
  );
}

function BlindPreview(props: {
  subject: BehaviorSubject<ImagePoint[]>;
  onCompleted: OnCompleted;
  assets: BlindAssets;
}) {
  const dispatch = useDispatch();
  const [maskLoaded, setMaskLoaded] = useState(false);
  const containerRef = useRef(null as null | Container2dType);
  const maskRef = useRef(null as any);
  const pointsRef = useRef([]);
  const onCompletedRef = useRef(props.onCompleted);
  onCompletedRef.current = props.onCompleted; // make sure ref is always up to date

  useEffect(() => {
    addDrag(
      containerRef.current,
      {
        current: (point: any) => {
          props.subject.next(
            pointsRef.current.map((p: any) => ({
              x: p.x + point.x,
              y: p.y + point.y,
            }))
          );
        },
      },
      (val: boolean) => {
        dispatch(setPannableAction(val));
        if (val) onCompletedRef.current(pointsRef.current);
      }
    );
    // There appears to be an internal react-pixi race condition where the
    // mask being rendered at the same time as the sprite it applies to
    // causes it to not be applied
    setMaskLoaded(true);
  }, []);
  useEffect(() => {
    if (maskLoaded) {
      containerRef.current!.convertSubtreeTo2d!();
    }
  }, [maskLoaded]);
  useEffect(() => {
    const subscription = props.subject.subscribe((points: any) => {
      pointsRef.current = points;
      if (containerRef.current) {
        containerRef.current.proj.mapQuad(
          containerRef.current as any,
          pointsRef.current
        );
      }
    });
    return () => subscription.unsubscribe();
  }, []);
  return (
    <Container2d ref={containerRef}>
      <Sprite texture={props.assets.mask} ref={maskRef} />
      {maskLoaded && (
        <Container mask={maskRef.current}>
          <Sprite
            interactive
            texture={props.assets.image}
            height={1000}
            width={1000}
          />
        </Container>
      )}
      <Sprite
        texture={props.assets.overlay}
        blendMode={PIXI.BLEND_MODES.MULTIPLY}
      />
      <Sprite texture={props.assets.trim} />
    </Container2d>
  );
}

function BlindHandles(props: {
  subject: BehaviorSubject<ImagePoint[]>;
  onCompleted: OnCompleted;
}) {
  return (
    <>
      {times(4, (i) => (
        <BlindHandle
          subject={props.subject}
          key={i}
          ind={i}
          onCompleted={props.onCompleted}
        />
      ))}
    </>
  );
}

function BlindHandle(props: {
  ind: any;
  subject: BehaviorSubject<ImagePoint[]>;
  onCompleted: OnCompleted;
}) {
  const spriteRef = useRef(null as any);
  const dispatch = useDispatch();
  const pointsRef = useRef([]);
  const onCompletedRef = useRef(props.onCompleted);
  onCompletedRef.current = props.onCompleted; // make sure ref is always up to date

  useEffect(() => {
    addInteraction(
      spriteRef.current,
      {
        current: (p: any) => {
          props.subject.next(
            pointsRef.current.map((point: any, ind) =>
              ind === props.ind ? p : point
            )
          );
        },
      },
      (val: boolean) => {
        dispatch(setPannableAction(val));
        if (val) onCompletedRef.current(pointsRef.current);
      }
    );

    const subscription = props.subject.subscribe((points: any) => {
      pointsRef.current = points;
      const point = points[props.ind];
      const s = spriteRef.current;
      if (point && s) {
        s.x = point.x;
        s.y = point.y;
      }
    });
    return () => subscription.unsubscribe();
  }, []);
  const draw = React.useCallback((g) => {
    g.clear();
    g.beginFill(0x2b6cb0, 0.9);
    g.drawCircle(0, 0, 25);
    g.endFill();
  }, []);
  return <Graphics ref={spriteRef} draw={draw} interactive anchor={0.5} />;
  // <Sprite
  //   ref={spriteRef}
  //   texture={PIXI.Texture.WHITE}
  //   interactive
  //   tint={0x333333}
  //   scale={3}
  //   anchor={0.5}
  // />
}

function onClickOutside(
  showControls: boolean,
  setShowControls: (show: boolean) => void,
  interactionManager: InteractionManager,
  refs: {}
) {
  function handleClick(e: PointerEvent) {
    const canvElem = threeCanv();
    const isVisualiserBtn = checkVisualiserBtn(e);
    if (isVisualiserBtn) return;
    // if clicked outside of the canv
    if (!canvElem.contains(e.target as any)) {
      if (showControls) setShowControls(false);
      return;
    }
    const tempPoint = new PIXI.Point();
    interactionManager.mapPositionToPoint(tempPoint, e.x, e.y);

    // At the moment this hit test only checks that there is some pixi interactive element hit.
    // Because the 'blinds' are the only interactive element on the scene, this is sufficient.
    // If we need to add other interactive elements, we will need to find a way to label/layer blind elements
    // and check if the hitObj hits here.
    const hitObj = interactionManager.hitTest(tempPoint);

    if (hitObj && !showControls) {
      setShowControls(true);
    } else if (!hitObj && showControls) {
      setShowControls(false);
    }
  }
  document.addEventListener('pointerdown', handleClick);
  return () => document.removeEventListener('pointerdown', handleClick);
}

// Below Fns are adapted from: https://pixijs.io/examples/#/plugin-projection/quad-homo.js
function addDrag(
  obj: any,
  setPoint: { current: (point: any) => void },
  setPannable: SetPannable
) {
  obj.interactive = true;
  obj
    .on('pointerdown', (event: any) => onDragStart(event, setPannable))
    .on('pointerup', (event: any) => onDragEnd(event, setPannable))
    .on('pointerupoutside', (event: any) => onDragEnd(event, setPannable))
    .on('pointermove', (event: any) => {
      onDragMove(event, setPoint);
      // reset position after move
      const o = event.currentTarget;
      o.dragPointerStart = event.data.getLocalPosition(o.parent);
      o.dragObjStart = new PIXI.Point();
      o.dragObjStart.copyFrom(o.position);
    });
}

// Below Fns are adapted from: https://pixijs.io/examples/#/plugin-projection/quad-homo.js
export function addInteraction(
  obj: any,
  setPoint: { current: (point: any) => void },
  setPannable: SetPannable
) {
  obj.interactive = true;
  obj
    .on('pointerdown', (event: any) => onDragStart(event, setPannable))
    .on('pointerup', (event: any) => onDragEnd(event, setPannable))
    .on('pointerupoutside', (event: any) => onDragEnd(event, setPannable))
    .on('pointermove', (event: any) => onDragMove(event, setPoint));
}

function onDragStart(event: any, setPannable: SetPannable) {
  const obj = event.currentTarget;
  obj.dragData = event.data;
  obj.dragging = 1;
  obj.dragPointerStart = event.data.getLocalPosition(obj.parent);
  obj.dragObjStart = new PIXI.Point();
  obj.dragObjStart.copyFrom(obj.position);
  obj.dragGlobalStart = new PIXI.Point();
  obj.dragGlobalStart.copyFrom(event.data.global);
  setPannable(false);
}

function onDragEnd(event: any, setPannable: SetPannable) {
  const obj = event.currentTarget;
  // set the interaction data to null
  obj.dragging = 0;
  obj.dragData = null;
  setPannable(true);
}

function onDragMove(event: any, setPoint: { current: (point: any) => void }) {
  const obj = event.currentTarget;
  if (!obj.dragging) return;
  const data = obj.dragData; // it can be different pointer!
  if (obj.dragging === 1) {
    // click or drag?
    if (
      Math.abs(data.global.x - obj.dragGlobalStart.x) +
        Math.abs(data.global.y - obj.dragGlobalStart.y) >=
      3
    ) {
      // DRAG
      obj.dragging = 2;
    }
  }
  if (obj.dragging === 2) {
    const dragPointerEnd = data.getLocalPosition(obj.parent);
    // DRAG
    setPoint.current({
      x: obj.dragObjStart.x + (dragPointerEnd.x - obj.dragPointerStart.x),
      y: obj.dragObjStart.y + (dragPointerEnd.y - obj.dragPointerStart.y),
    });
  }
}
