import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../redux/store';
import {
  BlindFilterResponse,
  BlindTypes,
  FilterFloorAttributesResponse,
  FloorFilterResponse,
  RugFilterAttributesResponse,
  RugFilterResponse,
} from 'shared/build/models/product';
import { BlindFilterAttributesResponse } from 'shared/ts/models/product';

export type FilterableBlindTypes =
  | BlindTypes.Roller
  | BlindTypes.Shutter
  | BlindTypes.Roman
  | BlindTypes.Panel
  | BlindTypes.Venetian
  | BlindTypes.CurtainSheers

export interface SelectionState {
  floorData: FloorFilterResponse[];
  floorFilters: FilterFloorAttributesResponse & { search_term: string };
  showFloorFilters: boolean;
  blindData: BlindFilterResponse[];
  blindFilters: BlindFilterAttributesResponse & { search_term: string, type: Array<FilterableBlindTypes> };
  blindScaling: number;
  showBlindFilters: boolean;
  rugData: RugFilterResponse[];
  rugFilters: RugFilterAttributesResponse & { search_term: string };
  showRugFilters: boolean;
}

const initialState: SelectionState = {
  floorData: [],
  showFloorFilters: false,
  floorFilters: {
    search_term: '',
    type: [],
    life_style: [],
    style: [],
    color_tone: [],
    brand: [],
  },
  blindData: [],
  blindFilters: {
    search_term: '',
    name: [],
    width: [],
    length: [],
    color_tone: [],
    type: [BlindTypes.Roller],
  },
  blindScaling: 1,
  showBlindFilters: false,
  rugData: [],
  rugFilters: {
    search_term: '',
    category: [],
    size: [],
    shape: [],
    design: [],
    price_range: [],
  },
  showRugFilters: false,
};

export const productDataSlice = createSlice({
  name: 'productData',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    // floors
    toggleFloorFilter(
      state,
      action: PayloadAction<{
        category: keyof FilterFloorAttributesResponse;
        val: string;
      }>
    ) {
      const { val, category } = action.payload;
      state.floorFilters[category] = toggle(state.floorFilters[category], val);
    },
    setFloorFilter(
      state,
      action: PayloadAction<{
        category: keyof FilterFloorAttributesResponse;
        val: string[];
      }>
    ) {
      const { val, category } = action.payload;
      state.floorFilters[category] = val;
    },
    clearFloorFilter(
      state,
      action: PayloadAction<keyof FilterFloorAttributesResponse>
    ) {
      state.floorFilters[action.payload] = [];
    },
    changeFloorSearchTerm(state, action: PayloadAction<string>) {
      state.floorFilters.search_term = action.payload;
    },
    setFloorProducts(state, action: PayloadAction<FloorFilterResponse[]>) {
      state.floorData = action.payload;
    },
    setShowFloorFilters(state, action: PayloadAction<boolean>) {
      state.showFloorFilters = action.payload;
    },
    // rugs
    toggleRugFilter(
      state,
      action: PayloadAction<{
        category: keyof RugFilterAttributesResponse;
        val: string;
      }>
    ) {
      const { val, category } = action.payload;
      state.rugFilters[category] = toggle(state.rugFilters[category], val);
    },
    clearRugFilter(
      state,
      action: PayloadAction<keyof RugFilterAttributesResponse>
    ) {
      state.rugFilters[action.payload] = [];
    },
    changeRugSearchTerm(state, action: PayloadAction<string>) {
      state.rugFilters.search_term = action.payload;
    },
    setRugProducts(state, action: PayloadAction<RugFilterResponse[]>) {
      state.rugData = action.payload;
    },
    setShowRugFilters(state, action: PayloadAction<boolean>) {
      state.showRugFilters = action.payload;
    },
    changeBlindSearchTerm(state, action: PayloadAction<string>) {
      state.blindFilters.search_term = action.payload;
    },
    toggleBlindFilter(
      state,
      action: PayloadAction<{
        category: keyof BlindFilterAttributesResponse;
        val: string;
      }>
    ) {
      const { val, category } = action.payload;
      state.blindFilters[category] = toggle(state.blindFilters[category], val);
    },
    clearBlindFilter(
      state,
      action: PayloadAction<keyof BlindFilterAttributesResponse>
    ) {
      state.blindFilters[action.payload] = [];
    },
    changeBlindType(state, action: PayloadAction<FilterableBlindTypes>) {
      state.blindFilters.type = [action.payload];
    },
    setBlindProducts(state, action: PayloadAction<BlindFilterResponse[]>) {
      state.blindData = action.payload;
    },
    setBlindScaling(state, action: PayloadAction<number>) {
      state.blindScaling = action.payload;
    },
    setShowBlindFilters(state, action: PayloadAction<boolean>) {
      state.showBlindFilters = action.payload;
    },
  },
});

function toggle(current: string[], val: string) {
  const newFilter = current.includes(val)
    ? current.filter((c) => c !== val)
    : [...current, val];
  return newFilter;
}

export const {
  toggleFloorFilter,
  setFloorFilter,
  clearFloorFilter,
  setFloorProducts,
  changeFloorSearchTerm,
  toggleRugFilter,
  clearRugFilter,
  setRugProducts,
  changeRugSearchTerm,
  setBlindProducts,
  clearBlindFilter,
  changeBlindType,
  changeBlindSearchTerm,
  setShowFloorFilters,
  setShowRugFilters,
  setBlindScaling,
  setShowBlindFilters,
  toggleBlindFilter,
} = productDataSlice.actions;

export const productData = (state: RootState) => state.productData;

export default productDataSlice.reducer;
