import { Visualiser } from './Visualiser/Visualiser';
import { useSelector } from 'react-redux';
import { motion } from 'framer-motion';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { RoutePaths } from '../../App';
import {
  productVisualisation,
  setVisualisations,
  VisualisationData,
} from './productVisualisationSlice';
import BlindProducts from './BlindProducts';
import FlooringProducts from './FlooringProducts';
import RugProducts from './RugProducts';
import { Box, Flex, Button } from '@chakra-ui/react';
import { useQuery } from '../../utils/hooks';
import { useEffect, useState } from 'react';
import { Loading } from '../../UI/Loading';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { ShareItemsDataApi } from 'shared/build/models/shared';
import { addNewRoom } from '../Photos/photosSlice';
import { SharedErrorMessage } from '../../UI/ErrorMessage';
import { showBlindOnboarding, showRugOnboarding } from '../../redux/uiSlice';
import ShareLandingModal from '../InspirationStation/ShareLandingModal';
import axios from '../../axios';
import { sendFreeMeasureAndQuote } from './SelectedProduct/helpers';

export enum ProductView {
  flooring = 'flooring',
  blinds = 'blinds',
  rugs = 'rugs',
}

export const ProductsContainer = () => {
  const { isLoading, error, openShareModal } = useShared();
  if (isLoading) {
    return <Loading />;
  }
  if (error) {
    return (
      <Box p={2} mt={'50px'}>
        <SharedErrorMessage error={error} />G
      </Box>
    );
  }

  return (
    <>
      <Products />
      {openShareModal && <ShareLandingModal />}
    </>
  );
};

export default function Products() {
  const { selectedIndex, data } = useSelector(productVisualisation);
  const location = useLocation();
  if (!data.length) {
    return <Redirect to={RoutePaths.onboarding} />;
  }
  if (selectedIndex === null) return null;
  const view = productView(location.pathname);
  return (
    <>
      <Flex
        flexDirection={{ base: 'column', sm: 'row' }}
        /* TODO: May need to be reverted after consult with will 
         *   gridGap={{ base: '0', sm: '20px' }}
         * */
        gridGap={{ base: '0', sm: '0' }}
        width="100%"
        flex={1}
      >
        {view && <Visualiser view={view} />}
        <Switch>
          <Route path={RoutePaths.flooring}>
            <FlooringProducts />
          </Route>
          <Route path={RoutePaths.rugs}>
            <RugProducts />
          </Route>
          <Route path={RoutePaths.blinds}>
            <BlindProducts />
          </Route>
        </Switch>
      </Flex>
    </>
  );
}

export function productView(path: string) {
  if (path.includes(RoutePaths.flooring)) {
    return ProductView.flooring;
  }
  if (path.includes(RoutePaths.blinds)) {
    return ProductView.blinds;
  }
  if (path.includes(RoutePaths.rugs)) {
    return ProductView.rugs;
  }
  return null;
}

export const useShared = () => {
  const shareId = useQuery('shared_id');
  const openShareModal = useQuery('modal');
  const view = useQuery('view') as ProductView | null;
  const [isLoading, setIsLoading] = useState(true);
  const photos = useAppSelector((state) => state.photos.data);
  const [error, setError] = useState<null | string>(null);
  const dispatch = useAppDispatch();
  useEffect(() => {
    (async () => {
      if (shareId) {
        try {
          const res = await axios.get<{ data: ShareItemsDataApi }>(
            `/api/v1/shared/${shareId}`
          );
          const visualisationData: VisualisationData[] = res.data.data.shared;
          dispatch(setVisualisations(visualisationData));
          const sharedPhotos = res.data.data.rooms;
          sharedPhotos.forEach((sharedPhoto) => {
            if (!photos.some((photo) => photo.id === sharedPhoto.id)) {
              dispatch(addNewRoom(sharedPhoto));
            }
          });
          if (openShareModal) {
            // set hasShownOnboarding true so that it doesn't cover the share modal
            dispatch(showBlindOnboarding());
            dispatch(showRugOnboarding());
          }
        } catch (err) {
          setError('Error loading shared rooms');
        }
      }
      setIsLoading(false);
    })();
  }, []);

  return { isLoading, error, openShareModal, view };
};
