import {
  BlindFilterResponse,
  FloorFilterResponse,
  RugFilterResponse,
} from 'shared/build/models/product';
import { SavedApi } from 'shared/build/models/saved';
import { config } from '../../config';
import { trackEvent } from '../../utils/tracking';
import { ProductView } from '../Products/Products';
import { VisualisationData } from '../Products/productVisualisationSlice';
import { saveWatermarkImage } from '../Products/SelectedProduct/helpers';

export function downloadImage(url: string, filename: string) {
  const a = document.createElement('a');
  a.href = url;
  a.download = filename; // Cross-origin requests ignore this in favour of the request's Content-Disposition
  document.body.appendChild(a);
  a.click();
}

export async function handleSaveImage(
  visualised: SavedApi | VisualisationData,
  productData: {
    floorData?: FloorFilterResponse;
    rugData?: RugFilterResponse;
    blindData?: BlindFilterResponse;
  },
  view: ProductView
) {
  trackEvent({ category: 'Share', action: 'Download Image' });
  let imageUrl = '';
  if ('watermark_url' in visualised) {
    imageUrl = visualised.watermark_url;
  } else {
    const res = await saveWatermarkImage(visualised, productData, view);
    imageUrl = res.image_url;
  }
  await downloadImage(imageUrl, `choices_flooring_roomview.jpg`);
}

export function handleMessage(shareUrl: string) {
  const a = document.createElement('a');
  // https://stackoverflow.com/a/35013929 - cross-device sms opening
  a.href = `sms:?&body=Check out my new floors I created using the Choices Flooring RoomView app - ${shareUrl}`;
  a.target = '_blank';
  document.body.appendChild(a);
  a.click();
}

export function handleEmail(shareUrl: string) {
  const a = document.createElement('a');
  a.href = `mailto:?subject=I just gave my room a makeover!&body=I've just given my room a makeover using the Choices Flooring RoomView app! Check it out at the link below. Pick your own favourites and share them with me! %0D%0A %0D%0A ${encodeURIComponent(
    shareUrl
  )}`;
  a.target = '_blank';
  document.body.appendChild(a);
  a.click();
}

export function createShareUrl(shareId: string) {
  return `${config.pageUrl}?shared_id=${shareId}`;
}
