import {
  ArrowDownIcon,
  ChevronDownIcon,
  ExternalLinkIcon,
  QuestionOutlineIcon,
} from '@chakra-ui/icons';
import {
  HStack,
  Box,
  Flex,
  Text,
  Modal,
  Popover,
  PopoverContent,
  PopoverBody,
  useDisclosure,
  PopoverTrigger,
  Radio,
  RadioGroup,
  Button,
  Grid,
  GridItem,
} from '@chakra-ui/react';

import {
  BlindFilterResponse,
  BlindProductDb,
  BlindTypes,
} from 'shared/build/models/product';
import { RoomApi } from 'shared/build/models/room';
import { SecondaryHeader } from '../../../UI/Text';
import ProductThumbnail, { BorderType } from '../ProductList/ProductThumbnail';
import { setSelectedProductInfo } from '../productVisualisationSlice';
import { useTrackProductView } from './helpers';
import ProductInfo from './ProductInfo';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import SaveButtonNew from '../Visualiser/SaveButtonNew';
import FilterButton from './FilterButton';
import Share from '../../Share/Share';
import { ProductView } from '../Products';
import {
  changeBlindType,
  setShowBlindFilters,
  setShowFloorFilters,
} from '../ProductData/productDataSlice';
import { useState } from 'react';
import { batch } from 'react-redux';

type Props = {
  product: BlindProductDb;
  visIndex: number;
  blind: BlindFilterResponse;
  room: RoomApi;
};
const BlindSelectedProductHeading = ({
  product,
  visIndex,
  blind,
  room,
}: Props) => {
  const dispatch = useAppDispatch();

  const zoomLevel = useAppSelector((state) => state.ui.zoomLevel);

  const productSelected = useAppSelector(
    (state) => state.productVisualisation.selectedProduct
  );
  const toggleProductInfoFromHeader = () =>
    productSelected === null
      ? dispatch(
          setSelectedProductInfo({
            product: product,
            brand: `${blind.brand_category.brand_category} ${blind.brand_category.fabric}`,
          })
        )
      : dispatch(setSelectedProductInfo(null));

  const visData = useAppSelector((state) => state.productVisualisation);
  const {
    isOpen: isShareOpen,
    onOpen: onShareOpen,
    onClose: onShareClose,
  } = useDisclosure();

  const blindType = useDisclosure();
  const [activeFilter, setActiveFilter] = useState<string>('rollerblinds');

  useTrackProductView(room, product.sku);
  return (
    <>
      <Grid
        mt={{ base: '0.5em', lg: '0' }}
        columnGap={{ base: 'auto', lg: '0.25em' }}
        gridTemplateColumns={
          '[filter] auto [share] fit-content(2rem) [save] fit-content(2rem)'
        }
      >
        <GridItem gridColumn="filter">
          <FilterButton handleClick={() => dispatch(setShowBlindFilters(true))}>
            Filter Blinds
          </FilterButton>
        </GridItem>
        <GridItem gridColumn="share">
          <Box
            rounded="full"
            display="flex"
            background="gray.200"
            paddingX={3}
            paddingY={1}
            minWidth={50}
            height={30}
            justifyContent="center"
            alignItems="center"
            onClick={onShareOpen}
          >
            <ExternalLinkIcon />
          </Box>
        </GridItem>
        <GridItem gridColumn="save">
          <Box
            rounded="full"
            display="flex"
            background="gray.200"
            paddingX={3}
            paddingY={1}
            minWidth={50}
            height={30}
            ml={{ base: '2', sm: '1' }}
            justifyContent="center"
            alignItems="center"
          >
            <SaveButtonNew />
          </Box>
        </GridItem>
      </Grid>

      {zoomLevel === 0 && (
        <HStack mt={2} mb={1} id="hstac" justifyContent="space-between">
          <Box display="flex">
            <Box flex="0 0 auto" position="relative">
              <ProductThumbnail product={product} size={50}>
                <ProductInfo
                  url={product.product_url}
                  product={product}
                  brand={`${blind.brand_category.brand_category} ${blind.brand_category.fabric}`}
                />
              </ProductThumbnail>
            </Box>

            <Flex direction="column" pl="2">
              <Flex direction={{ base: 'row', sm: 'column', lg: 'row' }}>
                <Flex direction="column">
                  <Text align="left" fontWeight="light" fontSize="12px">
                    {blind.brand_category.brand_category}{' '}
                    {blind.brand_category.fabric}
                  </Text>
                  <SecondaryHeader
                    flex="1"
                    align="left"
                    color="#2B6CB0"
                    cursor="pointer"
                    onClick={toggleProductInfoFromHeader}
                  >
                    {product.name} *
                  </SecondaryHeader>
                </Flex>
              </Flex>
            </Flex>
          </Box>

          <Popover
            isOpen={blindType.isOpen}
            onClose={blindType.onClose}
            placement="auto-end"
          >
            {({ isOpen, onClose }) => (
              <>
                <PopoverTrigger>
                  <Button
                    onClick={blindType.onToggle}
                    rounded="full"
                    display="flex"
                    alignItems="center"
                    borderWidth={2}
                    borderColor="blue.400"
                    backgroundColor="transparent"
                    color="gray.600"
                    fontWeight="light"
                    fontSize="xs"
                    paddingX={0}
                    paddingY={0}
                    minWidth={100}
                    justifyContent="center"
                    cursor="pointer"
                  >
                    Blind Type <ChevronDownIcon style={{ marginBottom: -2 }} />
                  </Button>
                </PopoverTrigger>

                <PopoverContent
                  borderWidth={1}
                  borderColor="#4A5568"
                  color="#4A5568"
                  fontSize="sm"
                  rounded="2xl"
                  bg="white"
                  padding={0}
                >
                  <PopoverBody p={0}>
                    <RadioGroup value={activeFilter} onChange={setActiveFilter}>
                      <Box
                        pt={2}
                        pb={1}
                        pl={5}
                        borderBottomWidth={1}
                        borderBottomColor="#4A5568"
                        display="flex"
                        justifyContent="space-between"
                        cursor="pointer"
                        onClick={() =>
                          batch(() => {
                            setActiveFilter(BlindTypes.Roller);
                            dispatch(changeBlindType(BlindTypes.Roller));
                            onClose();
                          })
                        }
                      >
                        <span>Rollerblinds</span>
                        <Radio mr={3} value={BlindTypes.Roller} />
                      </Box>

                      <Box
                        py={1}
                        pl={5}
                        borderBottomWidth={1}
                        borderBottomColor="#4A5568"
                        display="flex"
                        justifyContent="space-between"
                        cursor="pointer"
                        onClick={() =>
                          batch(() => {
                            setActiveFilter(BlindTypes.Shutter);
                            dispatch(changeBlindType(BlindTypes.Shutter));
                            onClose();
                          })
                        }
                      >
                        <span>Shutters</span>
                        <Radio mr={3} value={BlindTypes.Shutter} />
                      </Box>

                      <Box
                        py={1}
                        pl={5}
                        borderBottomWidth={1}
                        borderBottomColor="#4A5568"
                        display="flex"
                        justifyContent="space-between"
                        cursor="pointer"
                        onClick={() =>
                          batch(() => {
                            setActiveFilter(BlindTypes.CurtainSheers);
                            dispatch(changeBlindType(BlindTypes.CurtainSheers));
                            onClose();
                          })
                        }
                      >
                        <span>Curtains and Sheers</span>
                        <Radio mr={3} value={BlindTypes.CurtainSheers} />
                      </Box>

                      <Box
                        py={1}
                        pl={5}
                        borderBottomWidth={1}
                        borderBottomColor="#4A5568"
                        display="flex"
                        justifyContent="space-between"
                        cursor="pointer"
                        onClick={() =>
                          batch(() => {
                            setActiveFilter(BlindTypes.Venetian);
                            dispatch(changeBlindType(BlindTypes.Venetian));
                            onClose();
                          })
                        }
                      >
                        <span>Venetions</span>
                        <Radio mr={3} value={BlindTypes.Venetian} />
                      </Box>

                      <Box
                        py={1}
                        pl={5}
                        borderBottomWidth={1}
                        borderBottomColor="#4A5568"
                        display="flex"
                        justifyContent="space-between"
                        cursor="pointer"
                        onClick={() =>
                          batch(() => {
                            setActiveFilter(BlindTypes.Roman);
                            dispatch(changeBlindType(BlindTypes.Roman));
                            onClose();
                          })
                        }
                      >
                        <span>Roman Blinds</span>
                        <Radio mr={3} value={BlindTypes.Roman} />
                      </Box>

                      <Box
                        pt={1}
                        pb={2}
                        pl={5}
                        display="flex"
                        justifyContent="space-between"
                        cursor="pointer"
                        onClick={() =>
                          batch(() => {
                            setActiveFilter(BlindTypes.Panel);
                            dispatch(changeBlindType(BlindTypes.Panel));
                            onClose();
                          })
                        }
                      >
                        <span>Panel Glides</span>
                        <Radio mr={3} value={BlindTypes.Panel} />
                      </Box>
                    </RadioGroup>
                  </PopoverBody>
                </PopoverContent>
              </>
            )}
          </Popover>
        </HStack>
      )}
      <Modal size="xs" isOpen={isShareOpen} onClose={onShareClose}>
        <Share view={ProductView.blinds} visualisations={visData.data} />
      </Modal>
    </>
  );
};

export default BlindSelectedProductHeading;
